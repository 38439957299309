import React, { Component } from 'react';
import { Router, Route } from "react-router-dom";
import { matchPath, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { loadUser, dismissCacheRefreshed, registerAttribution, setAnalyticsData, 
    clearWorkoutRequestingFlags, historyPush, historyReplace, historyPop, 
    historyClear, historyClearForward, clearCache, setOffPlanMeals, confirmSharedMeals, 
    discardUnsavedChanges, 
    clearUnsavedChanges,
    createRecipe,
    updateRecipe,
    createRecipeOverride,
    clearRecipeDraft,
    dismissTooltip,
    switchClient,
    checkTrainerRefresh,
    checkForBreakingUpdate,
    logoutUser,
    tempDismissTip,
    adminSwitchUser} from 'redux/actions';
import { hasBasicProfileSelector, deviceReadySelector, cacheRefreshedSelector, loadedDatesSelector, renderKeySelector, needsProPopupSelector, userRecordSelector, analyticsSelector, failedRequestSelector, historySelector, showSaveFlashSelector, appRenderKeySelector, unsavedChangesSelector, recipeDraftSelector, signupFlowSelector, trainerRecordSelector, successRequestSelector, needsUnsavedChangesPopupSel, shouldShowUpgradePopup } from 'redux/selectors';
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { basename, rootPath, loggedOutPaths, history,
    userMealMatch, workoutPreviewMatch, workoutLogMatch, 
    workoutDoMatch, progressionTestMatch, strengthTestMatch, workoutResetMatch, 
    swapExerciseSpecMatch, homeMatch, neitherPaths, alwaysLoadUserPaths, loginPathFor, 
    appExitPath, afterLoginPath, loginMatch, specialLoggedInPaths, progressionPickMatch, 
    editWorkoutTemplateMatch, editExerciseGroupMatch, editRoutineStandaloneMatch, 
    swapExerciseTemplateMatch, addExercisesToGroupMatch, addExercisesMatch, editWeeklyMealMatch, viewMealPathMatch, viewRecipeReplacementMatch, viewTempMealMatch, viewTempRecipeReplacementMatch, mealPlanMatch, addPeopleToMealsMatch, regenerateWeekMatch, offPlanMealsSetupMatch, swapMealMatch, swapRecipeMatch, swapTempRecipeMatch, addRecipeMatch, addTempRecipeMatch, editRecipeMatch, webLaunchAnnouncementPath, trainerPaths, trainerHomePath, homePath, getAppBlacklist, mainSiteBasename, trackablePaths, startMatch, mainMatches, userSwitchPaths } from 'config/paths';
import { conversionPathFor } from 'redux/helpers';
import { topLevelDomains } from 'config/routes';
import loadingContainer, { LoadingContainerParent, errorMsgFor } from 'components/LoadingHOC';
import Button, { DefaultAsyncActionButton } from "components/Button";
import LinkButton from "components/LinkButton";
import AppSplash from 'components/AppSplash';
import { brandName } from 'config/settings';
import { useTranslation, withTranslation } from 'react-i18next';
import moment from 'moment';
import { registerTimezone, logError } from 'lib/api';
import { BasicModal, ModalHeader, ModalFooter } from 'components/Modal';
import * as _ from 'lib/utilities';
import camelcaseKeys from 'camelcase-keys';
import { dateFormat } from 'config/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toast from 'components/Toast';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Redirect, Link } from 'components/Routing';
import { none, slideOver } from 'assets/transitions';
import { getRecipeDraftAction, parseRecipeDraft } from './RecipeEditor';
import welcomePopup from 'assets/img/WelcomePopup.png';
import PlaceholderImage from 'components/PlaceholderImage';
import { SlightEmphasisIconNote } from 'components/Typography';
import { GetAppButtons } from 'partials/Utilities';
import AppMessagingWrapper from 'partials/AppMessagingWrapper';
import AndroidStepCounterPrompt from 'partials/AndroidStepCounterPrompt';
import { UPGRADE_POPUP } from 'config/tooltips';

const attributionFromLocation = location => _.pick(camelcaseKeys(_.parseQuery(location.search)),'originalSource','originalKeyword','originalCampaign','lastSource')

class GoogleAnalytics extends React.Component {

    constructor(props) {
        super(props);
        const { location, user, attributionOverride } = props;
        this.explicitAttribution = false;
        this.fbPIISet = false;
        const attribution = attributionOverride || attributionFromLocation(location);
        if(!_.isEmpty(attribution)) {
            this.explicitAttribution = true;
            props.registerAttribution(attribution);
        } else if (user && (!_.isBlank(user.originalSource) || !_.isBlank(user.lastSource))) {
            props.setAnalyticsData(_.pick(user,'originalSource','originalKeyword','originalCampaign','lastSource'));
        }

        this.sendPageView();
    }

    render() {
        return null;
    }

    componentDidUpdate({ location, user }) {
        
        if(user !== this.props.user && this.props.user && !this.explicitAttribution && (!_.isBlank(this.props.user.originalSource) || !_.isBlank(this.props.user.lastSource))) {
            const newUser = this.props.user;
            this.props.setAnalyticsData(_.pick(newUser,'originalSource','originalKeyword','originalCampaign','lastSource'));
        }
        
        if (location.pathname === this.props.location.pathname) {
            return;
        }

        this.sendPageView();
    }

    sendPageView() {
        const { location, user } = this.props;
        const fbq = window.fbq;

        if(matchPath(location.pathname,{ path: trackablePaths, exact: true })) {
            _.mixpanelTrack('Pageview', {page_path: `/app${location.pathname}`, visibility_state: document.visibilityState });
        }
        
        //const finalPath = `${mainSiteBasename}${this.filterIdsFromPath(location.pathname)}`;

        if(typeof(fbq) === 'function') {
            if(user && user.email && !this.fbPIISet) {
                const pii = user.firstName ? { fn: user.firstName, em: user.email } : { em: user.email };
                window.fbq('init', `${process.env.REACT_APP_FB_PIXEL_ID}`, pii);
                this.fbPIISet = true;
            }
            fbq('track', 'PageView');
        }
    }

    filterIdsFromPath(path) {
        let newPath = path;
        if(matchPath(path,{ path: homeMatch })) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date');
        } else if(matchPath(path, { path: userMealMatch })) {
            newPath = path.replace(/(\/user_meal\/)\d+/,"$1:id").replace(/(\/:id\/)\d+/,"$1:recipe_id").replace(/\d{4}-\d{2}-\d{2}/,':date');
        } else if(matchPath(path, { path: [workoutPreviewMatch, workoutLogMatch, workoutResetMatch, workoutDoMatch, workoutResetMatch ] })) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date');
            if(matchPath(path, { path: workoutDoMatch })) {
                newPath = newPath.replace(/(\/do\/)\d+-(work|warmup)-\d+/,"$1:set_index");
            }
        } else if(matchPath(path, { path: [progressionTestMatch,progressionPickMatch] })) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date').replace(/(\/progression\/)\d+/,"$1:id");
            if(matchPath(path, { path: progressionTestMatch })) {
                newPath = newPath.replace(/(\/:date\/)\d+-(work|warmup)-\d+/,"$1:set_index");
            }
        } else if(matchPath(path, { path: strengthTestMatch })) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date').replace(/(\/exercise\/)\d+/,"$1:id");
        } else if(matchPath(path, { path: swapExerciseSpecMatch })) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date').replace(/(\/swap\/)\d+/,"$1:id");
        } else if(matchPath(path, { path: [editWorkoutTemplateMatch,editExerciseGroupMatch] })) {
            newPath = path.replace(/(\/routine\/)\d+/,'$1:id').replace(/(\/(workout_template|exercise_group)\/)\d+/,"$1:workout_template_id");
        } else if(matchPath(path, { path: editRoutineStandaloneMatch })) {
            newPath = path.replace(/(\/routine\/)\d+/,"$1:id")
        } else if(matchPath(path, { path: swapExerciseTemplateMatch })) {
            newPath = path.replace(/(\/routine\/)\d+(\/workout_template\/)\d+(\/exercise_template\/)\d+(\/swap)/,"$1:id$2:workout_template_id$3:exercise_template_id$4")
        } else if(matchPath(path, { path: [addExercisesToGroupMatch,addExercisesMatch] })) {
            newPath = path.replace(/(\/routine\/)\d+\/(workout_template|exercise_group)\/\d+(\/add_exercises)/,"$1:id/$2/:workout_template_id$3");
        } else if(matchPath(path, {path: editWeeklyMealMatch})) {
            newPath = path.replace(/\d{4}-\d{2}-\d{2}/,':date').replace(/(lunch|breakfast|snack|dinner)/,':category')
        } else if(matchPath(path, { path: [mealPlanMatch,addPeopleToMealsMatch,regenerateWeekMatch,offPlanMealsSetupMatch] })) {
            newPath = newPath.replace(/\/\d+\/(swap_pick)/,"/:id/$1");
        } else if(matchPath(path, { path: viewRecipeReplacementMatch })) {
            newPath = '/view_recipe_replacement';
        } else if(matchPath(path, { path: viewTempRecipeReplacementMatch })) {
            newPath = '/view_recipe_replacement_temp';
        } else if(matchPath(path, { path: viewTempMealMatch })) {
            newPath = '/view_temp_meal';
        } else if(matchPath(path, { path: viewMealPathMatch })) {
            newPath = '/view_recipe';
        } else if(matchPath(path, { path: userMealMatch })) {
            if(path.includes('portions')) {
                newPath = '/view_user_meal/portions';
            } else {
                newPath = '/view_user_meal';
            }
        } else if(matchPath(path, { path: [swapMealMatch,swapRecipeMatch,swapTempRecipeMatch,addRecipeMatch,addTempRecipeMatch] })) {
            if(matchPath(path, { path: swapMealMatch })) {
                newPath = '/swap_meal';
            } else if(matchPath(path, { path: swapRecipeMatch })) {
                newPath = '/swap_recipe';
            } else if(matchPath(path, { path: swapTempRecipeMatch })) {
                newPath = '/swap_recipe_temp';
            } else if(matchPath(path, { path: addRecipeMatch })) {
                newPath = '/add_recipe';
            } else if(matchPath(path, { path: addTempRecipeMatch })) {
                newPath = '/add_recipe_temp';
            }

            if(path.includes('/search')) {
                newPath = `${newPath}/search`;
            }
        } else if(matchPath(path, { path: editRecipeMatch })) {
            newPath = path.replace(/\/\d{4}-\d{2}-\d{2}/,'');
        }

        newPath = newPath.replace(/\d+(\/copy_workout\/)\d+/,":routine_cycle_id$1:workout_template_id");
        newPath = newPath.replace(/(\/cycle_settings\/)\d+/,"$1:routine_cycle_id");
        newPath = newPath.replace(/(\/exercise_template\/)\d+(\/settings)/,"$1:id$2");
        newPath = newPath.replace(/(\/set_template\/)\d+(\/references)/,"$1:id$2");
        newPath = newPath.replace(/\/(exercise_info|weight_calculator|exercise_settings|plate_calculator|edit_exercise_modal)\/\d+/,"/$1/:id");
        newPath = newPath.replace(/\/(log_meal|modal_food_log|adjust_servings)\/\d+/,"/$1/:id");
        newPath = newPath.replace(/\/(ingredient)\/\d+/,'/$1/:index')
        newPath = newPath.replace(/\/add_ingredient\/.*/,'/add_ingredient')
        newPath = newPath.replace(/\/(food_modal)\/\d+/,'/$1/:foodId')

        return newPath;
    }
}

const mapGaStateToProps = (state) => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state),
    signupData: signupFlowSelector(state),
    analytics: analyticsSelector(state)
})

const mapGaDispatchToProps = (dispatch) => ({
    registerAttribution: data => dispatch(registerAttribution(data)),
    setAnalyticsData: data => dispatch(setAnalyticsData(data))
})

const AnalyticsTag = connect(mapGaStateToProps,mapGaDispatchToProps)(withRouter(GoogleAnalytics))
class GetAppPrompt extends React.Component {

    constructor(props) {
        super(props);
        this.state = { show: false }
    }

    componentDidMount() {
        const isTest = process.env.REACT_APP_TEST === 'true';
        this.timeout = setTimeout(this.timeoutFn,isTest ? 1 : 10000);
    }

    render() {
        const { show } = this.state;

        return (
            <TransitionGroup>
                {show && (<CSSTransition classNames="fade" timeout={300} >
                    <div id="get-app-wrapper">
                        <div className="text-right faint-color">
                            <div className="clickable inline-block pa10" id="hide-app-store-btns" onClick={() => this.hide()}>
                                <FontAwesomeIcon icon="times" size="2x" />
                            </div>
                        </div>
                        <div id="get-app-panel">
                            <GetAppButtons finishCallback={() => this.hide()} />
                        </div>
                    </div>
                </CSSTransition>)}
            </TransitionGroup>
        );
    }

    hide = () => {
        window.localStorage.setItem('show_store_btns',((Date.now().valueOf()+24*60*60*1000*3)));
        this.setState({show: false});
    }

    timeoutFn = () => {
        const isTest = process.env.REACT_APP_TEST === 'true';
        const { user, location, trainer } = this.props;
        const showTestAppPrompt = window.localStorage.getItem('__SHOW_TEST_APP_PROMPT') === 'true';
        if(isTest && !showTestAppPrompt) {
            return;
        }
        const shouldSeePrompt = _.showGetAppPrompt(trainer || user,isTest);
        if(matchPath(location.pathname,{ path: getAppBlacklist })) {
            if(shouldSeePrompt) {
                this.timeout = setTimeout(this.timeoutFn,isTest ? 1 : 10000);
            }
        } else if(shouldSeePrompt) {
            const showBtns = window.localStorage.getItem('show_store_btns');
            window.localStorage.getItem('__NO_APP_PROMPT');
            if(showBtns === null || Date.now().valueOf() >= Number(showBtns)) {
                this.setState({show: true});
            }
        }
    }

    componentWillUnmount() {
        if(this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }
}

GetAppPrompt = connect(mapGaStateToProps)(withRouter(GetAppPrompt))

function RetryButton({ loadUser }) {
    const { t } = useTranslation();
    return (
        <div id="retry-btn" className="mt20 current-state">
            <Button color="primary" onClick={loadUser} rounded>{t('Try Again')}</Button>
        </div>
    )
}

export const UpgradeBrowser = () => {

    return (
        <AppSplash>
            <div id="connect-error" className="current-state">
                <div className="status-msg">
                    {window.isCordova && ("This app requires and up-to-date modern browser. Try updating Chrome, Safari, or your device's browser and restarting the app.")}
                    {!window.isCordova && ("This app requires and up-to-date modern browser. Please update your browser and try again. If this problem persists you may need to use a different browser (Chrome or Safari recommended).")}
                </div>
            </div>
        </AppSplash>
    )
}

export function Connecting({firstTry}) {
    return (
        <AppSplash className="loading">
            <div id="connecting" className="current-state">
            </div>
        </AppSplash>
    )
}

export function Connectivity(props) {
    const { t } = useTranslation();
    return (
        <AppSplash>
            <div id="connect-error" className="current-state">
                <div className="status-msg">
                    {t("can't connect", {brand_name: brandName()})}
                </div>
                <RetryButton loadUser={props.load} />
            </div>
        </AppSplash>
    
    )
}

export function Maintenance(props) {
    const { t } = useTranslation();
    return (
        <AppSplash>
            <div id="service-error" className="current-state">
                <div  className="error-msg">
                    {t("servers unavailable", {brand_name: brandName()})}
                </div>
                <RetryButton loadUser={props.load} />
            </div>
        </AppSplash>
    )
}

export function ServerError(props) {
    const { t } = useTranslation();
    return (
        <AppSplash>
            <div id="generic-error" className="current-state">
                <div  className="error-msg">
                    {t("something went wrong")}
                </div>
                <RetryButton loadUser={props.load} />
            </div>
        </AppSplash>
    )
}

const ConfirmChangesButton = ({ children, onClick }) => {
    const { t } = useTranslation();
    
    return (
        <Button 
            rounded 
            noShadow
            color="primary" 
            onClick={onClick}
            id={`confirm-unsaved-btn`}
        >
                {children} {t("Confirm")}
        </Button>
    )
}

let UnsavedChangesPopup = ({ changes, recipeDraft, setOffPlanMeals, confirmSharedMeals, discardUnsavedChanges, clearUnsavedChanges, createRecipe, updateRecipe, createRecipeOverride, clearRecipeDraft }) => {
    const { t } = useTranslation();
    let { type, submitData } = changes || {};
    let action, clearAction, discardAction;
    let msg;
    if(type === 'offPlan') {
        action = setOffPlanMeals;
        clearAction = clearUnsavedChanges;
        discardAction = discardUnsavedChanges;
        msg = t("You never confirmed your changes to your off-plan meals.");
    } else if(type === 'shared') {
        action = confirmSharedMeals;
        clearAction = clearUnsavedChanges;
        discardAction = discardUnsavedChanges;
        msg = t("You never confirmed your changes to your shared meals.");
    } else if(recipeDraft) {
        submitData = recipeDraft;
        const finalAction = { createRecipe, updateRecipe, createRecipeOverride, copyRecipe: createRecipe }[getRecipeDraftAction(recipeDraft)];
        action = recipeDraft => finalAction(parseRecipeDraft(recipeDraft));
        clearAction = clearRecipeDraft;
        discardAction = clearRecipeDraft;
        msg = t("You didn't confirm your changes to this recipe.")
    }

    return (
        <BasicModal 
            startOpen
            options={{ dismissible: false, endingTop: '10vh' }}
            triggerRender={() => ''} 
            contentRender={() => {
                return (
                    <React.Fragment>
                        <ModalHeader defaults exitButton exitButtonClick={clearAction}>
                            <b>{t('Unconfirmed Changes')}</b>
                        </ModalHeader>
                        <div className="pa20 text-center" id={`confirm-${type}-changes-modal`}>
                            {msg} {t('What do you want to do?')}
                        </div>
                        <ModalFooter defaults>
                            <Button id="discard-unsaved-btn" rounded outlined className="modal-close mr5" color="red" onClick={discardAction}>
                                <FontAwesomeIcon icon={'times'}></FontAwesomeIcon>
                                {t("Discard")}
                            </Button> 
                            <div className="inline-block position-relative">
                                <DefaultAsyncActionButton 
                                    Comp={ConfirmChangesButton}
                                    LoaderWrapper={ConfirmChangesButton}
                                    loaderType="icon"
                                    action={action.bind(null,submitData)}
                                    successCallback={clearUnsavedChanges}
                                >
                                    <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> 
                                </DefaultAsyncActionButton>
                            </div>
                        </ModalFooter>
                    </React.Fragment>
                )
            }} 
        />
    )
}

const mapDispatchToUnsavedProps = dispatch => ({
    setOffPlanMeals: (data) => dispatch(setOffPlanMeals(data)),
    confirmSharedMeals: (data) => dispatch(confirmSharedMeals(data)),
    discardUnsavedChanges: () => dispatch(discardUnsavedChanges()),
    clearUnsavedChanges: () => dispatch(clearUnsavedChanges()),
    createRecipe: (data) => dispatch(createRecipe(data)),
    createRecipeOverride: (data) => dispatch(createRecipeOverride(data)),
    updateRecipe: data => dispatch(updateRecipe(data)),
    clearRecipeDraft: () => dispatch(clearRecipeDraft())
})

const mapStateToUnsavedProps = state => ({
    changes: unsavedChangesSelector(state),
    recipeDraft: recipeDraftSelector(state)
})

UnsavedChangesPopup = connect(mapStateToUnsavedProps,mapDispatchToUnsavedProps)(UnsavedChangesPopup)

let DeactivatedPopup = ({ user, logoutUser }) => {
    const { t } = useTranslation();

    return (
        <BasicModal 
            startOpen
            options={{ dismissible: false, endingTop: '10vh' }}
            triggerRender={() => ''} 
            contentRender={() => {
                return (
                    <React.Fragment>
                        <ModalHeader defaults>
                            <b>{t('Account Deactivated')}</b>
                        </ModalHeader>
                        <div className="pa20" id={`deactivated-modal`}>
                            <SlightEmphasisIconNote 
                                text={`${t('account deactivated tip')} ${user.isTrainer() ? t('contact primary owner', { name: user.getMasterAccount().fullName(), business_name: user.getMasterAccount().businessName }) : t('contact coach')}`}
                            />
                        </div>
                        <div className="pa10 text-center">
                            <Button rounded noShadow outlined onClick={() => logoutUser()}>{t('Sign Out')}</Button>
                        </div>
                    </React.Fragment>
                )
            }} 
        />
    )
}

const mapDispatchToDeactivatedProps = dispatch => ({
    logoutUser: () => dispatch(logoutUser())
})

DeactivatedPopup = connect(null,mapDispatchToDeactivatedProps)(DeactivatedPopup);

class ConversionRedirector extends React.Component {

    constructor(props) {
        super(props);
        const { showUpgradePopup, resetUpgradePopup, history, user, location } = this.props;
        if(showUpgradePopup && matchPath(location.pathname, { path: mainMatches, exact: true })) {
            history.push(conversionPathFor('home',user,'app_start_popup'));
            resetUpgradePopup();
        }
    }

    render() {
        return '';
    }
}

const mapStateToConvRedirProps = state => ({
    showUpgradePopup: shouldShowUpgradePopup(state)
})

const mapDispatchToConvRedirProps = dispatch => ({
    resetUpgradePopup: () => dispatch(tempDismissTip(UPGRADE_POPUP))
})

ConversionRedirector = connect(mapStateToConvRedirProps,mapDispatchToConvRedirProps)(ConversionRedirector);

class Success extends React.Component {

    constructor(props) {
        super(props);
        this.attributionOverride = null;
    }

    componentDidMount() {
        this.alreadyMounted = true;
    }

    render() {
        const { redirectToTrainerHome, redirectToHome, redirectToLogin, location, renderKey, dismissTooltip,
            cacheRefreshed, dismissCacheRefreshed, needsProPopup, needsUnsavedChangesPopup,
            failedRequest, successRequest, showSaveFlash, t, persistedHistory: { entries }, user } = this.props;
        let redirectPath = null;

        const redirectTo = matchPath(location.pathname,{ path: loginMatch }) ? afterLoginPath(location.pathname) : '';
        let delay = null;

        if(redirectToTrainerHome()) {
            redirectPath = _.isBlank(redirectTo) ? trainerHomePath : decodeURIComponent(redirectTo);
        } else if (redirectToHome()) {
            redirectPath = _.isBlank(redirectTo) ? homePath() : decodeURIComponent(redirectTo);
        } else if (redirectToLogin()) {
            if(this.alreadyMounted) {
                redirectPath = appExitPath();
                //delay is necessary in cases where user cleared cookies because localStorage still sees user logged in until user load from server.
                //Thus when user first hits the page they will get redirected to home path, but when user load completes they get kicked back to exit.
                //This will repeat infinitely unless there is enough of a delay for redux to flush the cleared user load to localStorage before user gets
                //redirected
                delay = 300; 
            } else {
                redirectPath = loginPathFor(location.pathname);
            }
        }
        
        const ambiguousCheck = () => {
            if(_.isInternalRequest(entries)) {
                return slideOver;
            } else {
                return none;
            }
        }

        if(redirectPath) {
            this.attributionOverride = attributionFromLocation(location);
            return (<Redirect to={redirectPath} delay={delay} />);
        } else {
            return (
                <React.Fragment>
                    <div key={renderKey} style={{position: 'relative' }}>
                        {user && (<AppMessagingWrapper user={user} key={user.id} />)}
                        {user && (<AndroidStepCounterPrompt user={user} />)}
                        <RouteTransitionMap location={location} trackScrolling ambiguousCheck={ambiguousCheck} disableRedirectsOnExit={true}>
                            {topLevelDomains.map(({component: DomainComponent, ...otherProps}) => {
                                
                                return (
                                    <DomainComponent key={otherProps.path} {...otherProps} />
                                )
                            })}
                        </RouteTransitionMap>
                        <TransitionGroup>
                            {showSaveFlash && (<CSSTransition classNames="fade" timeout={300} >
                                <div id="notifier" className={(typeof showSaveFlash === 'string' && showSaveFlash.match(/scroll/i)) ? 'point-down' : ''}>
                                    <div className="notifier-msg">
                                        {showSaveFlash === true ? t("Saved") : t(showSaveFlash)}
                                    </div>
                                </div>
                            </CSSTransition>)}
                        </TransitionGroup>
                        {cacheRefreshed && (
                            <Toast 
                                message={(<span><FontAwesomeIcon icon="times" className="red-text" /> {t('Data out of sync')}</span>)} 
                                collapseContent={t('data sync message')} 
                                completeCallback={() => dismissCacheRefreshed()} 
                                okCallback={() => dismissCacheRefreshed()}
                                moreText={t('More')}
                                dismissText={t('Ok')}
                                displayLength={10000}
                                id="cache-refreshed-modal"
                            />
                        )}
                        {user && user.showDeactivatedPopup() && (
                            <DeactivatedPopup user={user} />
                        )}
                        {user && user.shouldShowWelcomePopup() && (
                            <BasicModal 
                                startOpen
                                fullWidth
                                className="limit-width"
                                options={{ dismissible: false, endingTop: '10vh' }}
                                triggerRender={() => ''} 
                                contentRender={() => {
                                    return (
                                        <React.Fragment>
                                            <div>
                                                <PlaceholderImage width={1200} height={800} style={{ maxWidth: '100%' }} />
                                                <img src={welcomePopup} alt="" style={{ maxWidth: '100%', position: 'absolute', top: 0, left: 0 }} />
                                            </div>
                                            <div className="text-center limit-50-h">
                                                <h1>We've Updated!</h1>
                                                <p className="pl20 pr20">
                                                    Strongr Fastr 2.0 is here. We have a brand new look and everything is faster and easier than ever. <Link to={webLaunchAnnouncementPath()} target="_blank">Our blog</Link> has details.
                                                </p>
                                                <div className="pa20">
                                                    <Button color="primary" noShadow rounded className="modal-close" onClick={() => setTimeout(() => dismissTooltip('strongr_fastr_v2'),350)}>Let's Go</Button>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    )
                                }} 
                            />
                        )}
                        {needsProPopup && !needsUnsavedChangesPopup && (
                            <BasicModal 
                                startOpen
                                fullWidth
                                options={{ dismissible: false, endingTop: '10vh' }}
                                triggerRender={() => ''} 
                                contentRender={() => {
                                    return (
                                        <React.Fragment>
                                            <ModalHeader defaults exitButton exitButtonClick={dismissCacheRefreshed} id="pro-popup-modal">
                                                <FontAwesomeIcon icon="lock" /> <b>{t('Pro Required')}</b>
                                            </ModalHeader>
                                            <div className="pa20 text-center">
                                                {t("That action requires Pro. Please upgrade to gain access.")}
                                            </div>
                                            <ModalFooter defaults>
                                                <LinkButton rounded className="modal-close" to={conversionPathFor('meal_plan',user)} onClick={dismissCacheRefreshed}>{t("Upgrade")}</LinkButton> 
                                                <Button rounded outlined className="modal-close" onClick={dismissCacheRefreshed}>{t("Dismiss")}</Button>
                                            </ModalFooter>
                                        </React.Fragment>
                                    )
                                }} 
                            />
                        )}
                        {needsUnsavedChangesPopup && (
                            <UnsavedChangesPopup />
                        )}
                        {failedRequest && (
                            <Toast 
                                message={(<span><FontAwesomeIcon icon="times" className="red-text" /> {failedRequest === 'NETERR' ? t('No internet connection') : t('Request Failed')}</span>)} 
                                collapseContent={errorMsgFor(failedRequest,t)} 
                                completeCallback={() => dismissCacheRefreshed()} 
                                moreText={t('More')}
                                dismissText={t('Ok')}
                                displayLength={10000}
                                id="failed-request-modal"
                            />
                        )}
                        {successRequest && (
                            <Toast 
                                message={(<span><FontAwesomeIcon icon="check" className="success-color" /> {t(successRequest.msg)}</span>)} 
                                collapseContent={successRequest.longMsg ? t(successRequest.longMsg) : null} 
                                completeCallback={() => dismissCacheRefreshed()} 
                                moreText={t('More')}
                                dismissText={t('Ok')}
                                displayLength={10000}
                                id="success-request-modal"
                            />
                        )}
                        {!(window.isCordova || _.isOldApp() || _.isPWA()) && <GetAppPrompt />}
                    </div>
                    <AnalyticsTag attributionOverride={this.attributionOverride} />
                    <ConversionRedirector user={user} history={history} location={location} />
                </React.Fragment>
            );
        }
    }
}

const LoadingContainer = loadingContainer(
        {
            'REQUEST': Connecting, 
            'SUCCESS': Success, 
            'NETERR': Connectivity, 
            'SERVERERR': ServerError, 
            'MAINTENANCE': Maintenance,
            'DEFAULT': Connecting
        }
)

class AppHistoryHandler extends Component {

    constructor(props) {
        super(props);
        registerTimezone().catch(() => console.log('Timezone registration failed'));

        const { persistedHistory: { entries, lastEdit }, historyPush, historyReplace, historyPop, historyClear, historyClearForward } = this.props;
        const path = history.location.pathname;
        const normalStart = _.isBlank(path) || path === '/' || path === basename;
        const restoreHistory = ((window.isCordova || _.isPWA()) && normalStart && lastEdit && entries.length > 0 && moment().subtract(4,'hours').isBefore(moment(lastEdit)));
        if(restoreHistory) {
            const startIndex = Math.max(entries.length-20,0);
            const tailEntries = entries.slice(startIndex);
            const firstEntry = tailEntries[0];
            history.replace(firstEntry.path,firstEntry.state);
            const restEntries = tailEntries.slice(1,tailEntries.length);
            restEntries.forEach((entry) => {
                history.push(entry.path,entry.state);
            });
            historyClearForward();
        } else if(!_.pageAccessedByReload()) {
            historyClear({ path: history.location.pathname, state: history.location.state });
        }

        history.listen((location,action) => {
            if(action === 'PUSH') {
                historyPush({ path: location.pathname, state: location.state });
            } else if(action === 'REPLACE') {
                historyReplace({ path: location.pathname, state: location.state });
            } else if(action === 'POP') {
                historyPop(location.pathname);
            }
        })
    }

    render() {

        return (
            <Router history={history}>
                <Route render={(routeProps) => {
                    let mountPath = null;
                    if(_.isBlank(basename)) {
                        const mounted = !matchPath(window.location.pathname,{path: mainSiteBasename});
                        const replaceRegex = new RegExp(`^${mainSiteBasename}`);
                        mountPath = mounted ? null : window.location.pathname.replace(replaceRegex,'');
                    } else {
                        const mounted = matchPath(window.location.pathname,{path: basename});
                        mountPath = mounted ? null : window.location.pathname;
                    }
                    return (<AppRouteConnected {...routeProps} mountPath={mountPath} />);
                }} />
            </Router>
        );
    }
}
class App extends Component {

    shouldComponentUpdate(nextProps,nextState) {
        return (nextProps.deviceReady !== this.props.deviceReady);
    }

    render() {
        const { deviceReady } = this.props;
        
        if(!deviceReady) {
            return '';
        } else {
            return (
                <AppHistoryHandler {...this.props} />
            );
        }
    }
}

const AppErrorScreen = ({ reset }) => {
    const { t } = useTranslation();

    return (
        <AppSplash>
            <div id="generic-error" className="current-state">
                <div className="error-color">
                    {t("something went wrong")}
                </div>
                <div className="mt20">
                <Button color="primary" onClick={reset} rounded>{t('Restart App')}</Button>
                </div>
            </div>
        </AppSplash>
    )
}

const allInteractionEvents = ['mousemove','keydown','wheel','DOMMouseScroll','mousewheel','mousedown','touchstart','touchmove','MSPointerDown','MSPointerMove','visibilitychange'];
class AppRoute extends Component {

    constructor(props) {
        super(props);
        this.redirectToHome = this.redirectToHome.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
        this.preloaded = this.preloaded.bind(this);
        const { clearRequestingFlags } = props;
        clearRequestingFlags();
        this.startPolling();
        this.lastInteraction = Date.now();
        this.pollWaiting = false;
        this.setupInteractionListeners();
        this.state = { hasError: false, errorName: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, errorName: error && error.name }
    }

    componentDidUpdate(prevProps) {
        const { trainer, user } = this.props;
        if(trainer || (user && user.isClient())) {
            this.startPolling()
        } else if(!trainer) {
            this.stopPolling();
        }
    }

    componentDidCatch(error) {
        const { location: { pathname } } = this.props;
        const now = new Date();
        if(!window.lastAppError || (now.getTime() - window.lastAppError.getTime()) > 5000) {
            logError({ 
                name: error.name, 
                message: error.message, 
                backtrace: error.stack, 
                url: pathname, 
                platform: _.platformString(), 
                userAgent: navigator.userAgent,
                clientState: JSON.stringify(window.reduxStore.getState())
            })
            window.lastAppError = new Date();
        }
    }

    render() {
        const { hasError } = this.state;
        const { mountPath, appRenderKey, resetApp, history, needsUnsavedChangesPopup, dismissTooltip, loadUser, trainer } = this.props;

        const storedDate = sessionStorage.getItem('currentFocusDate');
        const params = _.isBlank(storedDate) ? {} : { curDate: moment(storedDate) }
        const load = () => loadUser(params);
        
        if(hasError) {
            const reset = () => {
                history.push(rootPath);
                resetApp();
                this.setState({ hasError: false });
            }
            return (
                <AppErrorScreen reset={reset} />
            )
        } else if(mountPath === null) {
            return (
                <LoadingContainerParent 
                    component={LoadingContainer}
                    load={load}
                    preloaded={this.preloaded}
                    location={this.props.location}
                    match={this.props.match}
                    renderKey={this.props.renderKey}
                    needsUnsavedChangesPopup={needsUnsavedChangesPopup}
                    cacheRefreshed={this.props.cacheRefreshed}
                    needsProPopup={this.props.needsProPopup}
                    failedRequest={this.props.failedRequest}
                    successRequest={this.props.successRequest}
                    showSaveFlash={this.props.showSaveFlash}
                    dismissCacheRefreshed={this.props.dismissCacheRefreshed}
                    persistedHistory={this.props.persistedHistory}
                    user={this.props.user}
                    trainer={trainer}
                    dismissTooltip={dismissTooltip}
                    t={this.props.t}
                    redirectToTrainerHome={this.redirectToTrainerHome}
                    redirectToHome={this.redirectToHome} 
                    redirectToLogin={this.redirectToLogin}
                    alwaysLoad
                    key={appRenderKey}
                />
            )
        } else {
            return (<Redirect to={mountPath} />)
        }

    }

    preloaded() {
        const { trainer } = this.props;

        if(this.alwaysLoadUser()) {
            return false;
        }

        if(this.userIsOptional()) {
            return true;
        }

        if(this.checkTrainerPath()) {
            return !!trainer;
        }

        const storedDate = sessionStorage.getItem('currentFocusDate');
        const mom = _.isBlank(storedDate) ? moment() : moment(storedDate);

        return this.props.loadedDates.includes(mom.format(dateFormat));
    }

    isLoggedInPath = () => {
        const { location } = this.props;
        return !loggedOutPaths.some((pathProps) => matchPath(location.pathname,pathProps));
    }

    checkTrainerPath = () => {
        const { location } = this.props;
        return trainerPaths.some((pathProps) => matchPath(location.pathname,pathProps));
    }

    isUserPath = () => {
        return this.isLoggedInPath() && !this.checkTrainerPath();
    }

    redirectFromUserToTrainer = () => {
        const { hasBasicProfile, trainer } = this.props;
        return (this.isUserPath() && !hasBasicProfile && trainer);
    }

    redirectToTrainerHome = () => {
        const { trainer } = this.props;
        return (this.redirectFromUserToTrainer() || (this.redirectFromLoggedOut() && trainer));
    }

    isTrainerPath = () => {
        return this.isLoggedInPath() && this.checkTrainerPath();
    }

    redirectFromTrainerToUser = () => {
        const { hasBasicProfile, trainer } = this.props;
        return (this.isTrainerPath() && hasBasicProfile && !trainer);
    }

    redirectToHome() {
        return (this.redirectFromTrainerToUser() || this.redirectFromLoggedOut());
    }

    redirectFromLoggedOut() {
        if(this.userIsOptional()) {
            return false;
        }
        const { location, hasBasicProfile, trainer, user } = this.props;

        //this is such bad code lol
        if(user && user.isPdfClient() && matchPath(location.pathname, { path: startMatch })) {
            return false;
        }

        return ((hasBasicProfile || trainer || (user && user.canSeeMainLayout())) && loggedOutPaths.some((pathProps) => matchPath(location.pathname,pathProps)));
    }

    redirectToLogin() {
        if(this.userIsOptional()) {
            return false;
        }
        const { location, hasBasicProfile, user, trainer } = this.props;
        if(hasBasicProfile || trainer) {
            return false;
        } else {
            for(let params of specialLoggedInPaths) {
                const { check, ...pathProps } = params;
                if(matchPath(location.pathname,pathProps)) {
                    const userPassed = user && check(user);
                    return !userPassed;
                }
            }

            const isLoggedInPath = !loggedOutPaths.some((pathProps) => matchPath(location.pathname,pathProps));
            return isLoggedInPath;
        }
    }

    userIsOptional() {
        const { location } = this.props;
        return neitherPaths.some(pathProps => matchPath(location.pathname,pathProps));
    }

    alwaysLoadUser() {
        const { location } = this.props;
        return alwaysLoadUserPaths.some(pathProps => matchPath(location.pathname,pathProps));
    }

    startPolling = () => {
        const { trainer, user } = this.props;
        const needsCheck = trainer || (user && user.isClient());
        if(needsCheck && !this.polling) {
            this.polling = setInterval(this.pollingHandler, 10000);
        }
    }

    stopPolling = () => {
        if(this.polling) {
            clearInterval(this.polling);
            this.polling = null;
        }
    }

    finishWaiting = () => (this.pollWaiting = false);

    pollingHandler = () => {
        if(this.pollWaiting) {
            return;
        }
        const { trainer, user, checkRefresh, checkBreak } = this.props;
        const elapsed = Date.now() - this.lastInteraction;
        if(document.visibilityState !== 'hidden' && elapsed <= 30000) {
            if(user && user.isClient()) {
                this.pollWaiting = true;
                checkBreak().then(this.finishWaiting).catch(this.finishWaiting);
            } else if(trainer) {
                this.pollWaiting = true;
                checkRefresh().then(this.finishWaiting).catch(this.finishWaiting);
            }
        }
    }

    componentWillUnmount() {
        this.stopPolling();
        this.cleanupInteractionListeners();
    }

    interactionHandler = () => {
        this.lastInteraction = Date.now();
    }

    setupInteractionListeners = () => {
        allInteractionEvents.forEach(event => {
            document.body.addEventListener(event,this.interactionHandler);
        })
    }

    cleanupInteractionListeners = () => {
        allInteractionEvents.forEach(event => {
            document.body.removeEventListener(event,this.interactionHandler);
        })
    }
}

const WrapperLoadingContainer = loadingContainer(
    {
        'REQUEST': Connecting, 
        'SUCCESS': AppRoute, 
        'NETERR': Connectivity, 
        'SERVERERR': ServerError, 
        'MAINTENANCE': Maintenance,
        'DEFAULT': Connecting
    }
)

class UserCheckWrapper extends React.Component {

     constructor(props) {
         super(props);
         const { user, trainer, location: { pathname } } = this.props;
         let storedId = sessionStorage.getItem('currentUserId');
         let adminId = localStorage.getItem('adminId');
         this.needsToSwitch = false;
         if(!matchPath(pathname,{ path: userSwitchPaths })) {
            if(storedId) {
                storedId = Number(storedId);
                if(trainer || adminId) {
                    const userMismatched = user && user.id !== storedId;
                    if(userMismatched) {
                       this.needsToSwitch = true;
                       this.targetId = storedId;
                       this.focusDate = sessionStorage.getItem('currentFocusDate');
                    }
                    
                }
            } else if(adminId) {
               adminId = Number(adminId);
               const userMismatched = user && user.id !== adminId;
               if(userMismatched) {
                   this.needsAdminSwitch = true;
                   this.targetId = adminId;
                }
            }
         }
     }

     render() {
         const { switchClient, adminSwitch, ...rest } = this.props;

        if(this.needsToSwitch) {
            return (
                <LoadingContainerParent 
                    component={WrapperLoadingContainer}
                    load={() => switchClient(this.targetId,this.focusDate)}
                    preloaded={() => false}
                    {...rest}
                />
            )
        } else if(this.needsAdminSwitch) {
            return (
                <LoadingContainerParent 
                    component={WrapperLoadingContainer}
                    load={() => adminSwitch(this.targetId)}
                    preloaded={() => false}
                    {...rest}
                />
            )
        } else {
            return (
                <AppRoute {...rest} />
            )
        }
     }
}

const mapStateToProps = (state,props) => {
    return { 
        cacheRefreshed: cacheRefreshedSelector(state),
        needsProPopup: needsProPopupSelector(state),
        failedRequest: failedRequestSelector(state),
        successRequest: successRequestSelector(state),
        showSaveFlash: showSaveFlashSelector(state),
        loadedDates: loadedDatesSelector(state),
        hasBasicProfile: hasBasicProfileSelector(state),
        trainer: trainerRecordSelector(state),
        renderKey: renderKeySelector(state),
        appRenderKey: appRenderKeySelector(state),
        needsUnsavedChangesPopup: needsUnsavedChangesPopupSel(state,props),
        persistedHistory: historySelector(state),
        user: userRecordSelector(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadUser: params => dispatch(loadUser(params)),
    switchClient: (userId,focusDate) => dispatch(switchClient(userId,focusDate)),
    adminSwitch: userId => dispatch(adminSwitchUser(userId)),
    dismissCacheRefreshed: () => dispatch(dismissCacheRefreshed()),
    dismissTooltip: tipName => dispatch(dismissTooltip(tipName)),
    clearRequestingFlags: () => dispatch(clearWorkoutRequestingFlags()),
    resetApp: () => dispatch(clearCache()),
    checkRefresh: () => dispatch(checkTrainerRefresh()),
    checkBreak: () => dispatch(checkForBreakingUpdate())
});

const mapStateToAppProps = state => ({
    deviceReady: deviceReadySelector(state),
    persistedHistory: historySelector(state)
});

const mapDispatchToAppProps = dispatch => ({
    historyPush: (entry) => dispatch(historyPush(entry)),
    historyReplace: (entry) => dispatch(historyReplace(entry)),
    historyPop: (path) => dispatch(historyPop(path)),
    historyClear: (entry) => dispatch(historyClear(entry)),
    historyClearForward: () => dispatch(historyClearForward())
})

export const AppRouteConnected = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserCheckWrapper));

export default connect(mapStateToAppProps,mapDispatchToAppProps)(App);
