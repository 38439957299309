import React from 'react';
import { FemaleGraphic, MaleGraphic } from 'partials/AnimatedBodySvgs';



const AnatomicGifGenPage = (props) => {
    const greenToGrey = ['#17de32','#656565'];

    return (

            <div>
                <MaleGraphic gradientColors={{ default: greenToGrey }} />
                <FemaleGraphic gradientColors={{ default: greenToGrey }} />
            </div>

    )
}

export default AnatomicGifGenPage