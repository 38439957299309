import React from 'react';
import Page from 'components/Page';
import { Redirect, UnderlineLink } from 'components/Routing';
import { resolvedHomePath } from 'redux/helpers';
import { Loader } from 'components/LoadingHOC';
import { jumpToBrowser } from 'redux/actions';
import { connect } from 'react-redux';
import { initCheckoutPathFor, sendAnalyticsEvent } from 'lib/api';
import moment from 'moment';
import * as _ from 'lib/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SaleOpened = () => (<Redirect to={resolvedHomePath()} />);

class SaleRedirector extends React.Component {

    constructor(props) {
        super(props);
        sendAnalyticsEvent({ eventName: 'InitiateCheckout', eventSourceUrl: window.location.href });
    }

    render() {
        const { jumpToBrowser, match: { params: { saleId, timestamp } } } = this.props;
        const timeCutoff = _.isBlank(timestamp) ? moment() : moment(Number(timestamp)*1000);
        timeCutoff.add(36,'hours');

        if(timeCutoff.isBefore(moment())) {
            return (
                <Page>
                    <div className="pa50 text-center">
                        <FontAwesomeIcon icon="times" className="error-color" /> Sorry, this coupon is expired.
                    </div>
                    <div className="pa50 text-center">
                        <UnderlineLink to={resolvedHomePath()}>Go back to home</UnderlineLink>
                    </div>
                </Page>
            )
        }

        if(window.isCordova) {
            return (
                <Page>
                    <Loader 
                        successComponent={SaleOpened}
                        type="page"
                        load={() => jumpToBrowser(initCheckoutPathFor(saleId))}
                        preloaded={() => false}
                    />
                </Page>
            )
        }

        return (
            <Redirect to={initCheckoutPathFor(saleId)} />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    jumpToBrowser: path => dispatch(jumpToBrowser(path))
})

export default connect(null, mapDispatchToProps)(SaleRedirector);