import moment from 'moment';
import { dateFormat } from 'config/settings';
import * as _ from 'lib/utilities';
import pathToRegexp from 'path-to-regexp';
import { createBrowserHistory } from 'history';

export const mainSiteBasename = '/app';
export const basename = window.basename;
export const history = createBrowserHistory({ basename });
const webOrigin = window.location.origin;
export const webPathFor = path => `${webOrigin}${path}`
export const webMealPlannerPath = () => webPathFor('/meal-planner');
export const webWorkoutRoutinesPath = () => webPathFor('/workout_routines');
export const webWorkoutSplitsPath = () => webPathFor('/workout_splits');
export const webMealsPath = () => webPathFor('/meals');
export const webLandingPath = () => webPathFor('/');
export const webTrainerLandingPath = () => webPathFor('/trainer_landing?utm_source=strongrfastr&utm_medium=appmenu&utm_campaign=none');
export const webProfessionalMealPlannerPath = () => webPathFor('/meal-planning-software-fitness-professionals');
export const webGetAppPath = () => webPathFor('/get_app');
export const webMealPlansPath = () => webPathFor('/meal_plans');
export const webBlogPath = () => webPathFor('/blog');
export const webLaunchAnnouncementPath = () => webPathFor('/blog/45-announcement_strongr_fastr_redesign_update_now_live')
export const webForgotPasswordPath = () => webPathFor('/users/password/new');
export const webAdminDashPath = () => webPathFor('/administration/dashboard');
export const webViewUserPath = (id) => webPathFor('/users/'+id)
export const webAffiliateProgramPath = () => webPathFor('/affiliate_program');
export const webTosPath = () => 'https://www.strongrfastr.com/terms_of_service';
export const webPrivacyPath = () => 'https://www.strongrfastr.com/privacy';
export const webWhiteLabelAgreementPath = () => 'https://www.strongrfastr.com/white_label_agreement'

export const modalPathFor = (suffix,pathname,params={}) => {
    const regex = new RegExp(pathToRegexp(suffix).source.replace(/^\^/,''),'i');
    let finalSuffix = suffix;
    Object.entries(params).forEach(([name,val]) => {
        finalSuffix = finalSuffix.replace(`:${name}`,val);
    })
    return `${pathname.replace(regex,'')}${finalSuffix}`;
}

export const fullModalPathFor = (suffix,basePath,params={}) => {
    return modalPathFor(suffix,basePath,params)
}

const homePathPrefix = '/today';
const foodLogModalPostfix = '/modal_food_log/:parentId?';
export const scannedFoodLogModalPostfix = '/scanned_food'
export const homePathFor = (moment) => (homePathPrefix + '/' + moment.format(dateFormat));
export const homePath = () => homePathFor(moment());
export const homeMatch = homePathPrefix + '/:date';
export const foodLogModalMatch = homeMatch + foodLogModalPostfix
export const foodLogModalMatchFor = date => homePathFor(date) + foodLogModalPostfix;
export const foodLogModalPathFor = (moment,parentId) => (homePathFor(moment) + foodLogModalPostfix.replace('/:parentId?',parentId ? `/${parentId}` : ''));
export const scannedFoodLogModalMatch = homeMatch + scannedFoodLogModalPostfix;
export const scannedFoodLogModalPathFor = (moment) => (homePathFor(moment) + scannedFoodLogModalPostfix);
const logMealModalMatch = '/log_meal/:id';
export const logMealModalMatchFor = (basePath) => `${basePath}${logMealModalMatch}`;
export const logMealModalPathFor = (basePath,userMealId) => logMealModalMatchFor(basePath).replace(':id',userMealId);

export const logActivityMatch = '/log_activity/:date';
export const logActivityPathFor = (moment) => logActivityMatch.replace(':date',moment.format(dateFormat));

export const homePathProps = { path: homeMatch }

export const switchAccountPath = '/switch_account';
export const switchAccountMatch = `${switchAccountPath}/:redirectTo?`
export const switchAccountPathFor = afterPath => switchAccountMatch.replace(':redirectTo',afterPath)
export const loginPath = '/login';
export const loginMatch = `${loginPath}/:redirectTo?`
export const loginPathFor = redirectTo => loginMatch.replace(':redirectTo?',encodeURIComponent(redirectTo));
export const rootPath = '/';
export const appExitPath = (trainer=false) => window.isCordova ? rootPath : (trainer ? webTrainerLandingPath() : webLandingPath());
export const afterLoginPath = path => path.replace(`${loginPath}/`,'').replace(loginPath,'')

export const startBase = '/start';
export const startPath = startBase + '/1';
export const startMatch = startBase + '/:page';
export const startFlow = startBase + '/:page(2|3|4)';
export const startEnd = startBase + '/5';
export const emailSignupMatch = startEnd + '/:context?';
export const emailSignupPathFor = context => emailSignupMatch.replace(':context?',context)
export const startPathFor = (page) => `${startBase}/${page}`;

export const onboardBase = '/onboard';
export const onboardMatch = onboardBase + '/:page';
export const onboardPath = onboardBase + '/1';

export const workoutSetupBase = '/workout_setup';
export const workoutSetupPath = `${workoutSetupBase}/1`;
export const workoutSetupMatch = `${workoutSetupBase}/:page`;
export const workoutSetupPickPath = `${workoutSetupBase}/7`;
export const pickNewRoutineModalPathFor = basePath => `${basePath}/pick_new_routine`

export const mealPlanSetupBase = '/meal_plan_setup';
export const mealPlanSetupPath = `${mealPlanSetupBase}/1`;
export const mealPlanSetupMatch = `${mealPlanSetupBase}/:page`;
export const mealPlanSetupPathFor = page => mealPlanSetupMatch.replace(':page',page)

export const nutritionDataInfoPath = '/nutrition_data_info';

export const mealPlanSignupBase = '/meal_plan_signup';
export const mealPlanSignupMatch = `${mealPlanSignupBase}/:page`;
export const mealPlanSignupPathFor = page => mealPlanSignupMatch.replace(':page',page);
export const setupWeeklyPlanPath = mealPlanSignupPathFor(7);

export const pdfSetupDonePath = '/pdf_client/setup_done';

export const conversionPathBase = '/upgrade';
export const conversionMatch = `${conversionPathBase}/:context`;
export const paymentModalMatch = `${conversionMatch}/payment`;
export const paywallMatch = `${conversionMatch}/paywall`;

export const editWeeklyMealMatch = '/weekly_meal/:category/:date/edit'
export const editWeeklyMealPathFor = userMeal => editWeeklyMealMatch.replace(':category',userMeal.category()).replace(':date',userMeal.date)
export const pickMealDaysModalSuffix = '/pick_days';
export const pickMealDaysModalPathFor = basePath => `${basePath}${pickMealDaysModalSuffix}`;
const addPeopleToMealModalSuffix = '/add_people';
export const addPeopleToMealModalPathFor = basePath => `${basePath}${addPeopleToMealModalSuffix}`

export const workoutPreviewMatch = '/workout/:date/preview'
export const workoutPreviewPathFor = (date) => workoutPreviewMatch.replace(':date',date)

export const workoutLogMatch = '/workout/:date/log';
export const workoutLogPathFor = date => workoutLogMatch.replace(':date',date)
const workoutCompleteModalPath = '/complete';
export const workoutCompleteModalPathFor = basePath => `${basePath}${workoutCompleteModalPath}`;

export const workoutDoBase = '/workout/:date/do';
export const workoutDoBaseFor = date => workoutDoBase.replace(':date',date);
export const workoutDoMatch = `${workoutDoBase}/:setIndex/:suffix?`;
export const workoutDoMatchFor = (date,setIndex) => workoutDoMatch.replace(':date',date).replace(':setIndex',setIndex)
export const workoutDoPathFor = (date,setIndex,suffix='') => (_.isBlank(suffix) ? workoutDoMatchFor(date,setIndex).replace('/:suffix?','') : workoutDoMatchFor(date,setIndex).replace(':suffix?',suffix));
export const workoutResetMatch = '/workout/:date/do/reset';
export const workoutResetPathFor = date => workoutResetMatch.replace(':date',date);

export const progressionPickMatch = '/progression/:specId/pick/:date';
export const progressionPickPathFor = (id,date) => progressionPickMatch.replace(':specId',id).replace(':date',date);

const progressionTestBaseMatch = '/progression/:specId/test/:date';
export const progressionTestMatch = `${progressionTestBaseMatch}/:setIndex/:suffix?`;
export const progressionTestBaseFor = (id,date) => progressionTestBaseMatch.replace(':specId',id).replace(':date',date);
export const progressionTestPathFor = (id,date,set,suffix='') => {
    let path = progressionTestMatch.replace(':specId',id).replace(':date',date).replace(':setIndex',set);
    if(_.isBlank(suffix)) {
        path = path.replace('/:suffix?','');
    } else {
        path = path.replace(':suffix?',suffix);
    }
    return path;
}

const strengthTestBaseMatch = '/exercise/:specId/test/:date';
export const strengthTestMatch = `${strengthTestBaseMatch}/:setIndex/:suffix?`;
export const strengthTestBaseFor = (id,date) => strengthTestBaseMatch.replace(':specId',id).replace(':date',date);
export const strengthTestPathFor = (id,date,set,suffix='') => {
    let path = strengthTestMatch.replace(':specId',id).replace(':date',date).replace(':setIndex',set);
    if(_.isBlank(suffix)) {
        path = path.replace('/:suffix?','');
    } else {
        path = path.replace(':suffix?',suffix);
    }
    return path;
}

const strtstFormBaseMatch = '/clients/:clientId/form_test/:formId/strength_test/:exerciseId/:date';
export const strtstFormMatch = `${strtstFormBaseMatch}/:setIndex/:suffix?`;
export const strtstFormBaseFor = (clientId,formId,id,date) => strtstFormBaseMatch.replace(':clientId',clientId).replace(':formId',formId).replace(':exerciseId',id).replace(':date',date);
export const strtstFormPathFor = (clientId,formId,id,date,set,suffix='') => {
    let path = strtstFormMatch.replace(':clientId',clientId).replace(':formId',formId).replace(':exerciseId',id).replace(':date',date).replace(':setIndex',set);
    if(_.isBlank(suffix)) {
        path = path.replace('/:suffix?','');
    } else {
        path = path.replace(':suffix?',suffix);
    }
    return path;
}

export const progressionShowMatch = '/progression/:id/show';
export const progressionShowPathFor = id => progressionShowMatch.replace(':id',id)

export const exerciseInfoFragment = '/exercise_info'
const exerciseInfoModalPostfix = `${exerciseInfoFragment}/:id`;
export const weightCalcModalPostfix = '/weight_calculator/:id';
const exerciseSettingsModalPostfix = '/exercise_settings/:id';
const plateCalculatorModalPostfix = '/plate_calculator/:id';
const syncWorkoutsModalPostfix = '/sync_workouts';

export const exerciseInfoModalMatchFor = basePath => `${basePath}${exerciseInfoModalPostfix}`;
export const exerciseInfoModalPathFor = (basePath,id) => `${basePath}${exerciseInfoModalPostfix.replace(':id',id)}`;

export const weightCalcModalMatchFor = basePath => `${basePath}${weightCalcModalPostfix}`;
export const weightCalcModalPathFor = (basePath,id) => `${basePath}${weightCalcModalPostfix.replace(':id',id)}`;

export const exerciseSettingsModalMatchFor = basePath => `${basePath}${exerciseSettingsModalPostfix}`;
export const exerciseSettingsModalPathFor = (basePath,id) => `${basePath}${exerciseSettingsModalPostfix.replace(':id',id)}`;

export const plateCalculatorModalMatchFor = basePath => `${basePath}${plateCalculatorModalPostfix}`;
export const plateCalculatorModalPathFor = (basePath,id) => `${basePath}${plateCalculatorModalPostfix.replace(':id',id)}`;

export const syncWorkoutsModalPathFor = basePath => `${basePath}${syncWorkoutsModalPostfix}`;

export const swapExerciseSpecMatch = '/workout/:date/swap/:id';
export const swapExerciseSpecPathFor = (date,id) => swapExerciseSpecMatch.replace(':date',date).replace(':id',id);

export const editWorkoutTemplateMatch = '/routine/:id/workout_template/:workoutTemplateId/edit';
export const editWorkoutTemplatePathFor = (id,workoutTemplateId) => editWorkoutTemplateMatch.replace(':id',id).replace(':workoutTemplateId',workoutTemplateId);

export const editExerciseGroupMatch = '/routine/:id/exercise_group/:workoutTemplateId/edit';
export const editExerciseGroupPathFor = (id,workoutTemplateId) => editExerciseGroupMatch.replace(':id',id).replace(':workoutTemplateId',workoutTemplateId);


export const ratingPromptPostfix = '/rating_prompt';
const ratingPromptMatch = `${ratingPromptPostfix}/:context`;
export const ratingPromptPathFor = (basePath,context) => `${basePath}${ratingPromptMatch.replace(':context',context)}`;

export const editRoutinePath = '/edit_routine';
export const routineSettingsPathFor = basePath => `${basePath}/routine_settings`;
export const restoreRoutineDefaultsPathFor = basePath => `${basePath}/restore_defaults`;
export const restartRoutinePathFor = basePath => `${basePath}/restart_routine`;
export const copyWorkoutModalMatchFor = (basePath) => `${basePath}/:routineCycleId/copy_workout/:workoutTemplateId`;
export const copyWorkoutModalPathFor = (basePath,routineCycleId,workoutTemplateId) => `${basePath}/${routineCycleId}/copy_workout/${workoutTemplateId}`;
export const cycleSettingsModalMatchFor = (basePath) => `${basePath}/cycle_settings/:routineCycleId`;
export const cycleSettingsModalPathFor = (basePath,routineCycleId) => `${basePath}/cycle_settings/${routineCycleId}`;

export const editRoutineStandaloneMatch = '/routine/:id/edit';
export const editRoutineStandlonePathFor = id => editRoutineStandaloneMatch.replace(':id',id);

export const etSettingsModalMatchFor = basePath => `${basePath}/exercise_template/:id/settings`;
export const etSettingsModalPathFor = (basePath,id) => etSettingsModalMatchFor(basePath).replace(':id',id);
export const stReferenceWeightModalMatchFor = basePath => `${basePath}/set_template/:id/references`;
export const stReferenceWeightModalPathFor = (basePath,id) => stReferenceWeightModalMatchFor(basePath).replace(':id',id);

export const swapExerciseTemplateMatch = '/routine/:id/workout_template/:workoutTemplateId/exercise_template/:exerciseTemplateId/swap';
export const swapExerciseTemplatePathFor = ({ workoutRoutineId,workoutTemplateId,exerciseTemplateId }) => swapExerciseTemplateMatch.replace(':id',workoutRoutineId).replace(':workoutTemplateId',workoutTemplateId).replace(':exerciseTemplateId',exerciseTemplateId);

export const addExercisesMatch = '/routine/:id/workout_template/:workoutTemplateId/add_exercises';
export const addExercisesPathFor = ({ workoutRoutineId,workoutTemplateId }) => addExercisesMatch.replace(':id',workoutRoutineId).replace(':workoutTemplateId',workoutTemplateId);
export const addExercisesToGroupMatch = '/routine/:id/exercise_group/:workoutTemplateId/add_exercises';
export const addExercisesToGroupPathFor = ({ workoutRoutineId, workoutTemplateId }) => addExercisesToGroupMatch.replace(':id',workoutRoutineId).replace(':workoutTemplateId',workoutTemplateId);

export const editExerciseModalMatchFor = basePath => `${basePath}/edit_exercise_modal/:id?`;
export const editExerciseModalPathFor = (basePath,id) => editExerciseModalMatchFor(basePath).replace(':id?',id ? id : '');

export const mealPlanMatch = '/meal_plan/:week?';
export const mealPlanPath = '/meal_plan/current';
export const mealPlanPathFor = (week,clientId) => !_.isBlank(clientId) ? standaloneMealPlanPathFor(clientId,week) : mealPlanMatch.replace(':week?',week);
export const mealPlanNextWeekPath = mealPlanPathFor('next');
export const freeMealPlanPath = mealPlanPathFor('single');

export const standaloneMealPlanMatch = `/clients/:clientId/meal_plan/:week/:copy`;
export const standaloneMealPlanPathFor = (clientId,week,copy=false) => standaloneMealPlanMatch.replace(':clientId',clientId).replace(':week',week).replace('/:copy',copy ? '/copy' : '/view')

export const generateNewWeekMatch = '/generate_plan/:week/:clientId/:page';
export const generateNewWeekBaseFor = (week,clientId) => `${generateNewWeekMatch.replace(':week',week).replace(':clientId',clientId || 'main').replace('/:page','')}`;
export const generateNewWeekPathFor = (week,clientId) => `${generateNewWeekMatch.replace(':week',week).replace(':clientId',clientId || 'main').replace(':page','8')}`;
export const switchForGenerateMatch = `/clients/:clientId/generate_plan/:week`;
export const switchForGeneratePathFor = (clientId,week) => switchForGenerateMatch.replace(':clientId',clientId).replace(':week',week)

export const regenerateWeekMatch = '/regenerate_plan/:week';
export const regenerateWeekPathFor = week => regenerateWeekMatch.replace(':week',week);

export const viewSharedMealsMatch = '/shared_meals/:week/:miniProfileId';
export const viewSharedMealsPathFor = (week,miniProfileId) => viewSharedMealsMatch.replace(':week',week).replace(':miniProfileId',miniProfileId);

export const offPlanMealsSetupMatch = '/off_plan/:week';
export const offPlanMealsSetupPathFor = week => offPlanMealsSetupMatch.replace(':week',week);

export const mealPlanNutritionModalPathFor = basePath => `${basePath}/nutrition`;

const addPeopleToPlanModalPostfix = '/add_people';
export const addPeopleToPlanModalPathFor = basePath => `${basePath}${addPeopleToPlanModalPostfix}`;

export const addPeopleToMealsMatch = '/share_meals/:week';
export const addPeopleToMealsPathFor = week => addPeopleToMealsMatch.replace(':week',week);

const swapMealModalMatch = `/:id/swap_pick`;
export const swapMealModalMatchFor = basePath => `${basePath}${swapMealModalMatch}`;
export const swapMealModalPathFor = (basePath,id) => swapMealModalMatchFor(basePath).replace(':id',id);

export const swapMealMatch = '/meal_type/:id/:week/find_replacement';
export const swapMealPathFor = (id,week) => swapMealMatch.replace(':id',id).replace(':week',week);

const trainerLeadPath = '/trainer';
export const trainerLeadPathFor = basePath => `${basePath}${trainerLeadPath}`;

export const swapRecipeMatch = '/user_meals/:id/swap_recipe/:recipeId';
export const swapRecipePathFor = (id,recipeId) => swapRecipeMatch.replace(':id',id).replace(':recipeId',recipeId);
export const swapTempRecipeMatch = '/meal_types/:id/swap_recipe/:recipeId';
export const swapTempRecipePathFor = (id,recipeId) => swapTempRecipeMatch.replace(':id',id).replace(':recipeId',recipeId);
export const addRecipeMatch = '/user_meals/:id/add_recipe';
export const addRecipePathFor = (id) => addRecipeMatch.replace(':id',id);
export const addTempRecipeMatch = '/user_meals/:id/add_temp_recipe';
export const addTempRecipePathFor = (id) => addTempRecipeMatch.replace(':id',id);
export const viewRecipeReplacementMatch = '/user_meals/:id/recipe_replacement/:sideDishStubs/:mealCount/:recipeId?';
export const viewRecipeReplacementPathFor = (userMealId,recipeId,mealCount,{ sideDishStubs }) => viewRecipeReplacementMatch.replace(':id',userMealId).replace(':sideDishStubs',sideDishStubs).replace(':mealCount',mealCount).replace(':recipeId?',recipeId ? recipeId : '');
export const viewTempRecipeReplacementMatch = '/meal_types/:id/recipe_replacement/:sideDishStubs/:mealCount/:recipeId?';
export const viewTempRecipeReplacementPathFor = (mealTypeId,recipeId,mealCount,{ sideDishStubs }) => viewTempRecipeReplacementMatch.replace(':id',mealTypeId).replace(':sideDishStubs',sideDishStubs).replace(':mealCount',mealCount).replace(':recipeId?',recipeId ? recipeId : '');

export const userMealMatch = '/user_meal/:id/:recipeId/:logDate?'
export const userMealPathFor = (userMealId,focus,logDate) => (userMealMatch.replace(':id',userMealId).replace(':recipeId',focus).replace('/:logDate?',(logDate ? `/${logDate}` : '')))

export const showPortionsModalPathFor = basePath => `${basePath}/portions`

export const editRecipeMatch = '/recipes/edit/:step/:date?';
export const editRecipeMatchFor = step => editRecipeMatch.replace(':step',step);
export const editRecipePathFor = (step,date) => editRecipeMatchFor(step).replace('/:date?',(date ? `/${date}` : ''));
const editIngredientModalMatch = '/ingredient/:index';
export const editIngredientModalMatchFor = basePath => `${basePath}${editIngredientModalMatch}`;
export const editIngredientModalPathFor = (basePath,index) => editIngredientModalMatchFor(basePath).replace(':index',index);

const addIngredientModalMatch = '/add_ingredient/:foodId/:servings';
export const addIngredientModalMatchFor = basePath => `${basePath}${addIngredientModalMatch}`;
export const addIngredientModalPathFor = (basePath,foodId,servings) => addIngredientModalMatchFor(basePath).replace(':foodId',foodId).replace(':servings',servings);

const foodModalMatch = '/food_modal/:foodId?';
export const foodModalMatchFor = basePath => `${basePath}${foodModalMatch}`;
export const foodModalPathFor = (basePath,foodId) => foodModalMatchFor(basePath).replace('/:foodId?',foodId ? `/${encodeURI(foodId)}` : '');

export const addIngredientsPath = '/recipe/ingredients/add';

const adjustServingsModalSuffix = `/adjust_servings/:recipeId`;
export const adjustServingsModalMatchFor = basePath => `${basePath}${adjustServingsModalSuffix}`;
export const adjustServingsModalPathFor = (basePath,recipeId) => adjustServingsModalMatchFor(basePath).replace(':recipeId',recipeId);

export const recipeBrowserPath = '/recipes/browser';
export const mealBrowserPath = '/meals/browser';

export const mealSearchModalPathFor = basePath => `${basePath}/search`;

export const viewMealPathMatch = '/meals/:sideDishStubs/view/:mealCount/:focus?';
export const viewMealPathFor = (stubs,mealCount,focus) => viewMealPathMatch.replace(':sideDishStubs',stubs).replace(':mealCount',mealCount).replace('/:focus?',focus ? `/${focus}` : '');

export const editTempMealPath = '/meals/temp/edit';
export const viewTempMealMatch = '/meals/temp/view/:mealCount/:focus?';
export const viewTempMealPathFor = (mealCount,focus) => viewTempMealMatch.replace(':mealCount',mealCount).replace('/:focus?',focus ? `/${focus}` : '');

export const workoutPlanPath = '/workout_plan';
export const deloadPathFor = basePath => `${basePath}/deload`;
export const undeloadPathFor = basePath => `${basePath}/undeload`;
export const standaloneWorkoutPlanMatch = '/clients/:clientId/workout_plan';
export const standaloneWorkoutPlanPathFor = clientId => standaloneWorkoutPlanMatch.replace(':clientId',clientId);

export const groceryListMatch = '/grocery_list/:type';
export const groceryListPathFor = type => groceryListMatch.replace(':type',type);

export const standaloneGlistMatch = '/clients/:clientId/grocery_list/:week/:type';
export const standaloneGlistPathFor = (clientId,week) => standaloneGlistMatch.replace(':clientId',clientId).replace(':week',week).replace(':type','needed')

export const foodInfoModalMatch = `/food_info/:id`;
export const foodInfoModalMatchFor = basePath => `${basePath}${foodInfoModalMatch}`;
export const foodInfoModalPathFor = (basePath,foodId) => foodInfoModalMatchFor(basePath).replace(':id',foodId)

export const settingsMatch = '/settings/:section?';
export const settingsPath = '/settings';
export const mealPlanSettingsPath = '/settings/meal_plan';
export const nutritionOverridesPath = `${settingsPath}/nutrition`;
export const progressMatch = '/progress/:exerciseId?';
export const progressPath = '/progress';
export const progressPathFor = exerciseId => progressMatch.replace(':exerciseId?',exerciseId);
export const accountPath = '/account';
export const macroAnalyticsPath = '/macro_analytics';
export const bodyMeasurementsPath = '/body_measurements';
export const progressPicsPath = '/progress_pics';

export const updatePaymentModalPathFor = basePath => `${basePath}/payment`;
export const updateTPaymentModalPathFor = basePath => `${basePath}/trainer_payment`;

export const pickExercisePath = `/exercises/pick`;

export const quickstartBase = '/quickstart';
export const quickstartMatch = `${quickstartBase}/:page`;
export const quickstartPathFor = page => quickstartMatch.replace(':page',page);
export const quickstartInitPath = '/quickstart_init';

export const mealPlanInitPath = '/meal_plan_init';

export const helpPath = '/tutorial';
export const getAppPath = '/get_the_app';

export const strengthBodyStatsPath = '/strength_level';
export const contactPath = '/contact';
export const notificationsPath = '/notifications';

export const errorLogsPath = '/error_logs_index';
export const errorLogMatch = '/error_logs/:id';
export const errorLogPathFor = id => errorLogMatch.replace(':id',id);

export const brandedAppsPath = '/branded_apps';

export const wimageCatsPath = '/workout_image_categories';
export const wimageCatMatch = '/workout_image_categories/:id';
export const wimageCatPathFor = id => wimageCatMatch.replace(':id',id)
export const wimageCatNewPath = wimageCatPathFor('new');

export const mainProgressPath = '/main_progress';
export const mainRouteMatches = [homeMatch,mealPlanMatch,workoutPlanPath,groceryListMatch,errorLogsPath,mainProgressPath]
export const ambiguousMainMatches = [contactPath,strengthBodyStatsPath]
export const mainMatches = [ ...mainRouteMatches, ...ambiguousMainMatches ]
export const mainMenuMatches = mainMatches.map(path => `${path}/menu`)

const trainerBase = '/trainer';
export const trainerSignupBase = `${trainerBase}/signup`;
export const trainerSignupMatch = `${trainerSignupBase}/:page/:planType?`;
export const trainerSignupPath = trainerSignupMatch.replace(':page',1);
export const trainerSignupPathFor = (page,planType='') => trainerSignupMatch.replace(':page',page).replace('/:planType?',_.isBlank(planType) ? '' : `/${planType}`);
const trainerBasicProfileBase = `${trainerBase}/basic_profile/:context`;
export const trainerBasicProfileMatch = `${trainerBasicProfileBase}/:page`;
export const trainerBasicProfileBaseFor = context => trainerBasicProfileBase.replace(':context',context);
export const trainerBasicProfilePathFor = context => trainerBasicProfileMatch.replace(':context',context).replace(':page',1);
export const trainerMealPlannerInitPath = `${trainerBase}/meal_planner_init`;
export const trainerConversionMatch = `${trainerBase}/upgrade/:context`;
const trainerPaymentMatch = `${trainerConversionMatch}/payment`;
export const trainerConversionPathFor = context => trainerConversionMatch.replace(':context',context);
export const trainerPaymentPathFor = context => `${trainerConversionPathFor(context)}/payment`;
export const trainerHomeMatch = `${trainerBase}/dash/:metrics`;
export const trainerHomePathFor = metrics => trainerHomeMatch.replace(':metrics',metrics);
export const trainerHomePath = trainerHomePathFor('summary');
export const newClientModalPathFor = basePath => `${basePath}/new_client`;
export const editClientModalMatchFor = basePath => `${basePath}/edit_client/:clientId`;
export const editClientModalPathFor = (basePath,clientId) => editClientModalMatchFor(basePath).replace(':clientId',clientId);

const trainerFormsBase = `${trainerBase}/forms`;
export const trainerFormsPath = `${trainerFormsBase}/index`;
export const editTrainerFormMatch = `${trainerFormsBase}/:id/edit`;
export const editTrainerFormPath = id => editTrainerFormMatch.replace(':id',id);

export const addAssessmentsToFormMatch = `${trainerFormsBase}/:formId/add_assessments`;
export const addAssessmentsToFormPath = formId => addAssessmentsToFormMatch.replace(':formId',formId);

export const addExercisesToFormMatch = `${trainerFormsBase}/:formId/add_exercises/:fieldType`;
export const addExercisesToFormPath = (formId,fieldType) => addExercisesToFormMatch.replace(':formId',formId).replace(':fieldType',fieldType);

export const assignFormsToClientsMatch = '/clients/:clientIds/assign_forms';
export const assignFormsToClientsPath = clientIds => assignFormsToClientsMatch.replace(':clientIds',clientIds.join('_'));

export const viewClientAssignedFormMatch = '/clients/:clientId/forms/:formId';
export const viewClientAssignedFormPathFor = (clientId,formId) => viewClientAssignedFormMatch.replace(':clientId',clientId).replace(':formId',formId);

export const viewAssignedFormMatch = '/forms/:formId';
export const viewAssignedFormPathFor = (formId) => viewAssignedFormMatch.replace(':formId',formId);

export const trainerBrandingBase = `${trainerBase}/branding`;
export const trainerBrandingMatch = `${trainerBrandingBase}/:page/:param?`;
export const trainerBrandingPathFor = (page,param) => `${trainerBrandingBase}/${page}${param ? `/${param}` : ''}`;

export const brandedAppSetupPath = `${trainerBase}/branded_app_setup`;

export const newNoteModalPathFor = basePath => `${basePath}/notes/new`;
export const editNoteModalMatchFor = basePath => `${basePath}/notes/:noteId/edit`;
export const editNoteModalPathFor = (basePath,noteId) => editNoteModalMatchFor(basePath).replace(':noteId',noteId);

export const tagClientsModalMatchFor = (basePath) => `${basePath}/tag/:ids`;
export const tagClientsModalPathFor = (basePath,ids) => tagClientsModalMatchFor(basePath).replace(':ids',ids.join('_'))

export const likeRecipeForClientsSuffix = '/like_recipe_for/:recipeId';
export const likeRecipeForClientsBasePath = pathname => pathname.replace(/\/like_recipe_for\/.*/,'');
export const likeRecipeForClientsMatchFor = pathname => `${likeRecipeForClientsBasePath(pathname)}${likeRecipeForClientsSuffix}`;
export const likeRecipeForClientsPathFor = (pathname,recipeId) => likeRecipeForClientsMatchFor(pathname).replace(':recipeId',recipeId);

export const switchCliAccountMatch = `${trainerBase}/clients/switch/:id`;
export const switchCliAccountPathFor = id => switchCliAccountMatch.replace(':id',id);

export const switchUserAdminMatch = '/admin/switch/:id';

export const inviteMatch = '/invite/:context';
export const trainerInvitePath = inviteMatch.replace(':context','trainer');
export const clientInvitePath = inviteMatch.replace(':context','client');;
export const trainersPath = `${trainerBase}/team`;
export const newTrainerModalPathFor = basePath => `${basePath}/new`;
export const workoutRoutinesInitPath = `${trainerBase}/workout_routines_init`;
export const routinesBase = `${trainerBase}/routines`;
export const routinesBaseMatch = `${routinesBase}/:type`;
export const myRoutinesPath = `${routinesBase}/personal`;
export const newRoutinePath = `${myRoutinesPath}/new`;
export const dbRoutinesPath = `${routinesBase}/db`;
export const teamRoutinesPath = `${routinesBase}/team`;
export const trainerRecipesMatch = `${trainerBase}/recipes/:type`;
export const trainerRecipesPathFor = type => trainerRecipesMatch.replace(':type',type)
export const trainerMyRecipesPath = trainerRecipesPathFor('favorites');
export const trainerDbRecipesPath = trainerRecipesPathFor('database');
export const trainerTeamRecipesPath = trainerRecipesPathFor('team');
export const trainerMessagingPath = `${trainerBase}/messaging`;
export const trainerMessagingMatch = `${trainerMessagingPath}/:chatId(\\d+)?`;

export const habitsBase = `${trainerBase}/habits`;
export const trainerHabitsMatch = `${habitsBase}/:type`;
const trainerHabitsPathFor = type => trainerHabitsMatch.replace(':type',type);
export const trainerHabitsPath = trainerHabitsPathFor('personal');
export const dbHabitsPath = trainerHabitsPathFor('db');
export const teamHabitsPath = trainerHabitsPathFor('team');
export const pastDuePath = `${trainerBase}/past_due`;
export const trainerMainMatches = [
    trainersPath,
    routinesBaseMatch,
    trainerHomeMatch,
    trainerRecipesMatch,
    trainerMessagingMatch,
    trainerHabitsMatch
]

export const recipeConfirmPaths = [userMealMatch,viewMealPathMatch,viewRecipeReplacementMatch,viewTempMealMatch,viewTempRecipeReplacementMatch,homeMatch,recipeBrowserPath,swapRecipeMatch,swapTempRecipeMatch,swapMealMatch,...trainerMainMatches]

export const trainerMainMenuMatches = trainerMainMatches.map(path => `${path}/menu`)

export const allMainMatches = [ ...mainMatches, ...trainerMainMatches ];

export const clientBasicProfileMatch = '/clients/:id/profile/:context';
export const clientBasicProfilePath = (clientId,context) => clientBasicProfileMatch.replace(':id',clientId).replace(':context',context);

export const clientDetailsMatch = '/clients/:clientId/details';
export const clientDetailsPath = clientId => clientDetailsMatch.replace(':clientId',clientId);

export const clientMealPlansMatch = '/clients/:clientId/meal_plans';
export const clientMealPlansPath = clientId => clientMealPlansMatch.replace(':clientId',clientId);

export const clientMealPlanSettingsMatch = '/clients/:id/meal_plan_settings/:context';
export const clientMealPlanSettingsPath = (clientId,context) => clientMealPlanSettingsMatch.replace(':id',clientId).replace(':context',context);

export const syncRoutineSuffix = '/sync_routines';
export const assignClientRoutineMatch = '/clients/:ids/assign_routine';
export const assignClientRoutinePathFor = clientIds => assignClientRoutineMatch.replace(':ids',clientIds.join('_'));

export const assignClientHabitsMatch = '/clients/:clientIds/assign_habits';
export const assignClientHabitsPathFor = clientIds => assignClientHabitsMatch.replace(':clientIds',clientIds.join('_'));

export const assignHabitToClientsMatch = '/habits/:id/assign_clients';
export const assignHabitToClientsPathFor = id => assignHabitToClientsMatch.replace(':id',id);

const mealPlanPickMatch = '/pick_meal_plan/:clientId';
export const mealPlanPickMatchFor = basePath => `${basePath}${mealPlanPickMatch}`;
export const mealPlanPickPathFor = (basePath,clientId) => `${mealPlanPickMatchFor(basePath).replace(':clientId',clientId)}`;

export const assignRoutineToClientsMatch = '/routines/:id/assign_clients';
export const assignRoutineToClientsPathFor = id => assignRoutineToClientsMatch.replace(':id',id);

export const editRoutineShortcutMatch = `${trainerBase}/edit_routine/:clientId`;
export const editRoutineShortcutPathFor = clientId => editRoutineShortcutMatch.replace(':clientId',clientId);

export const workoutPreviewShortcutMatch = `/clients/:clientId/workout_preview/:focusDate`;
export const workoutPreviewShortcutPathFor = (clientId,date) => workoutPreviewShortcutMatch.replace(':clientId',clientId).replace(':focusDate',date);

export const clientProgressShortcutMatch = `/clients/:clientId/progress/:exerciseId?`;
export const clientProgressShortcutPathFor = (clientId,exerciseId) => clientProgressShortcutMatch.replace(':clientId',clientId).replace(':exerciseId?',exerciseId || '');

export const clientProgressPicsShortcutMatch = `/clients/:clientId/progress_pics`;
export const clientProgressPicsShortcutPathFor = clientId => clientProgressPicsShortcutMatch.replace(':clientId',clientId);

export const clientBmShortcutMatch = `/clients/:clientId/body_measurements`;
export const clientBmShortcutPathFor = clientId => clientBmShortcutMatch.replace(':clientId',clientId);

export const clientMacroAnalyticsShortcutMatch = `/clients/:clientId/macro_analytics`;
export const clientMacroAnalyticsShortcutPathFor = clientId => clientMacroAnalyticsShortcutMatch.replace(':clientId',clientId);

export const viewLogsPath = '/logs/view';
export const viewLogsShortcutMatch = `/clients/:clientId/view_logs`;
export const viewLogsShortcutPathFor = clientId => viewLogsShortcutMatch.replace(':clientId',clientId);

export const importExerciseGroupsPathFor = basePath => `${basePath}/import`;

const importWorkoutTemplatesMatch = '/import_template/:routineCycleId';
export const importWorkoutTemplatesMatchFor = basePath => `${basePath}${importWorkoutTemplatesMatch}`
export const importWorkoutTemplatesPathFor = (basePath,routineCycleId) => importWorkoutTemplatesMatchFor(basePath).replace(':routineCycleId',routineCycleId)

export const trainerSettingsPath = `${trainerBase}/settings`;

export const cancelTsubPath = `${trainerBase}/cancel_subscription`;
export const viewInvoicesPath = `${trainerBase}/invoices`;

export const viewChatMatch = '/chats/:chatId';
export const viewChatPath = chatId => viewChatMatch.replace(':chatId',chatId);
export const desktopViewChatPath = chatId => trainerMessagingMatch.replace(':chatId(\\d+)?',chatId);

export const clientMessagingPath = '/messaging';
export const clientMessagingMatch = '/messaging/:chatId(\\d+)?';
export const clientDesktopViewChatPath = chatId => clientMessagingMatch.replace(':chatId(\\d+)?',chatId);

export const anatomicGifGenPath = '/anatomic_gif_gen';

export const trainerPaths = [
    { path: [
        switchCliAccountMatch,
        routinesBaseMatch,
        assignClientRoutineMatch,
        editRoutineShortcutMatch,
        workoutPreviewShortcutMatch,
        assignRoutineToClientsMatch,
        clientBasicProfileMatch,
        clientDetailsMatch,
        clientMealPlanSettingsMatch,
        assignRoutineToClientsMatch,
        clientProgressShortcutMatch,
        clientMacroAnalyticsShortcutMatch,
        viewLogsShortcutMatch,
        trainerSettingsPath,
        assignClientHabitsMatch,
        assignHabitToClientsMatch,
        trainerBrandingMatch,
        cancelTsubPath
    ] },
    { path: trainerMainMatches },
    { path: trainerInvitePath }
]

export const switchToClientPaths = [editRoutineShortcutMatch,switchCliAccountMatch];

export const canHitEmailOrPaywallMatches = [   
    ...allMainMatches,
    workoutPlanPath,
    mealPlanPath,
    workoutLogMatch,
    workoutDoMatch,
    workoutPreviewMatch,
    homeMatch,
    workoutSetupMatch,
    mealPlanSetupMatch,
    mealPlanSignupMatch,
    settingsMatch,
    editWeeklyMealMatch,
    editTempMealPath, 
    swapRecipeMatch, 
    swapTempRecipeMatch, 
    viewMealPathMatch,
    rootPath,
    startPath,
    onboardMatch,
    startFlow,
    quickstartMatch,
    mealPlanSetupMatch,
    standaloneMealPlanMatch
]

export const emailPaywallMatches = [conversionMatch,paywallMatch,emailSignupMatch,trainerConversionMatch,trainerBasicProfileMatch]

export const assignRoutinePath = '/assign_routine';
const workoutPlanStartedWhitelist = [workoutPreviewMatch,workoutDoMatch,workoutLogMatch,
    workoutResetMatch,progressionPickMatch,progressionTestMatch,strengthTestMatch]

export const signupModalSuffix = '/signup_first';
export const signupModalPathFor = basePath => `${basePath}${signupModalSuffix}`;

export const saleRedirectorMatch = '/sale_redirect/:saleId/:timestamp?';

export const loggedOutPaths = [
    { exact: true, path: rootPath },
    { path: [startMatch] },
    { path: loginPath },
    { path: [quickstartMatch,quickstartInitPath]}
]

export const neitherPaths = [
    { path: [mealPlanSignupMatch,mealPlanInitPath] },
    { path: emailSignupMatch },
    { path: onboardMatch },
    { path: strengthBodyStatsPath },
    { path: contactPath },
    { path: progressionShowMatch },
    { path: assignRoutinePath },
    { path: pickExercisePath },
    { path: switchAccountMatch },
    { path: helpPath },
    { path: getAppPath },
    { path: switchUserAdminMatch },
    { path: anatomicGifGenPath },
    { path: [clientInvitePath,trainerSignupMatch,trainerMealPlannerInitPath,trainerConversionMatch,trainerBasicProfileMatch,workoutRoutinesInitPath] }
]

export const alwaysLoadUserPaths = [
    { path: quickstartInitPath },
    { path: mealPlanInitPath },
    { path: assignRoutinePath },
    { path: trainerInvitePath },
    { path: clientInvitePath }
]

export const specialLoggedInPaths = [
    { path: workoutPlanStartedWhitelist, check: user => user.workoutPlanInitialized() },
    { path: workoutPlanPath, check: user => (user.workoutPlanInitialized() || user.canSeeMainLayout()) },
    { path: [homeMatch,workoutPlanPath,groceryListMatch,mealPlanMatch,mainProgressPath,clientMessagingPath,viewChatMatch], check: user => user.canSeeMainLayout() }
]

export const getAppBlacklist = [
    trainerSignupMatch,
    trainerBasicProfileMatch,
    trainerConversionMatch,
    conversionMatch,
    startPath,
    startFlow,
    onboardMatch,
    mealPlanSetupMatch,
    workoutSetupMatch,
    emailSignupMatch,
    inviteMatch,
    switchAccountMatch,
    loginMatch,
    pdfSetupDonePath
]

export const trackablePaths = [
    rootPath,
    startMatch,
    onboardMatch,
    loginMatch,
    conversionMatch,
    emailSignupMatch,
    paywallMatch,
    paymentModalMatch,
    workoutSetupMatch,
    mealPlanSetupMatch,
    trainerSignupMatch,
    trainerConversionMatch,
    trainerPaymentMatch,
    trainerBasicProfileMatch, 
    mealPlanSettingsPath, 
    accountPath,
    mealPlanSignupMatch,
    ...allMainMatches
]

export const pdfClientWhitelist = [startMatch,mealPlanSetupMatch,pdfSetupDonePath,onboardMatch];

export const userSwitchPaths = [switchUserAdminMatch,switchCliAccountMatch];