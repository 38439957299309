import React from 'react';
import maleMusculature from 'assets/img/male-musculature.png';
import femaleMusculature from 'assets/img/female-musculature.png';

const generateGradient = (id, colors) => {
    if (!colors || colors.length === 0) return null;

    // Join all colors into a semicolon-separated string to cycle through all colors in the gradient
    const colorCycle = [...colors, colors[0]].join(';');  // Ensures it loops back to the first color

    return (
        <linearGradient id={id} x1="0%" y1="0%" x2="100%">
            {colors.map((color, index) => {
                return (
                    <stop
                        key={index}
                        offset={`${(index / (colors.length - 1)) * 100}%`}
                        stopColor={color}
                    >
                        <animate
                            attributeName="stop-color"
                            values={colorCycle}  // All colors in the gradient
                            dur="4s"  // Adjust duration for full cycle (can be customized)
                            repeatCount="indefinite"
                        />
                    </stop>
                );
            })}
        </linearGradient>
    );
};

const generateGradients = (gradientColors,gender) => {
    const gradients = {};

    Object.entries(gradientColors).forEach(([bpart,colors]) => {
        const id = `${bpart}-${gender}-gradient`;
        gradients[bpart] = { id, gradient: generateGradient(id, colors) };
    });

    return gradients;
}

const classesFor = (bpart,gradients) => {

    if(gradients[bpart]) {
        const opacity = (gradients[bpart].gradient) ? 0.65 : 0;

        return { fillOpacity: opacity, fill: `url(#${gradients[bpart].id})`}
    }

    return { fillOpacity: 0.65, fill: `url(#${gradients['default'].id})`}
}


export const MaleGraphic = ({ gradientColors }) => {
    const gradients = generateGradients(gradientColors,'male');

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="overlay-svg" width="310" height="800" viewBox="0 0 310 800" id="male-body-svg" role="img" aria-label="Male musculature system">
            <title>Male musculature system</title>
            <desc>Image of male muscular system with color-coded overlays for arm size, waist size, chest size, shoulder size, forearm size, calf size, hip size, and thigh size.</desc>
            <defs>
                {Object.values(gradients).map(({ gradient }) => gradient)}
            </defs>
            <image width="310" height="800" className="body-underlay" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={maleMusculature} ></image>
            <path
            style={classesFor("forearms",gradients)}
            d="m 237.0276,276.50598 7.5,-7.88 8.62,5.63 5.63,-13.75 7.43,-12.13 4.2,-15.87 1.62,11.75 2.12,15.68 2.26,4.95 2.41,31.17 1.09,63.33 -8.24,-2.71 -11.14,-1.05 -2.75,2.13 -15.52,-42.22 -4.11,-19.41 z"
            ></path>
            <path
            style={classesFor("forearms",gradients)}
            d="m 66.777595,253.12598 -4.87,9.76 -3,8.87 -5.13,13.87 -0.87,10.76 -2.63,16 -8.25,43.26 6.88,0.36 13.37,5.12 11.63,-19.12 13,-21.5 7.62,-16.88 4.38,-15 v -17.5 l -11.76,-5.62 -15.87,12.92 -4.38,-11.67 z"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 103.5276,550.50598 5.5,33 2.75,18.5 2.25,22.75 2.5,17.5 8,48.25 -16.5,3.75 -2.5,-51.5 -4.5,-28.5 -2.500005,-31.5 z"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 141.5276,560.00598 -4.75,9.25 -7.25,9.75 -3,8.75 -1.75,14.5 1,21.5 2,27.25 6.5,23 3.25,-24.5 1.25,-24 5.25,-11.25 2.5,-10 -1,-20 z"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 187.7776,565.25598 9.25,16 3.5,10.75 1.5,27.25 -0.25,27.5 v 9.5 l -3.5,9.25 -3.5,-18.75 -1.25,-22.75 -7,-18.25 v -21.75 z"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 220.2776,546.25598 -3.25,33.25 -2.75,15.5 -0.25,27.25 -3.25,11.5 -0.25,15.5 -6.25,40 15,3.75 4.75,-2.5 3.75,-70 3,-25.5 -1,-26 -3,-16.25 z"
            ></path>
            <path
            style={classesFor("triceps",gradients)}
            d="m 98.907595,270.62598 4.370005,-10 -2.37,-26"
            ></path>
            <path
            style={classesFor("thighs",gradients)}
            d="m 170.4795,372.15889 -16.72968,-3.09208 -12.67049,-0.85796 -10.55199,20.04701 -6.5,12.25 3,9.5 -1,12 0.5,11.25 2.25,29 3.5,15.25 3.25,18.5 1.5,11.25 2.49414,12.72461 0.006,0.27539 3.25,8.5 1.25,17 3.75,10.25 0.25,5.25 11,15 4.75,-9.25 -6.75,-7.75 -6,-12.5 -3.125,-21.125 7.875,4.625 5.25,-3.25 2.25,-11.25 0.25,-12.5 11.75,1 1.75,15 5.5,13 3.5,-6.5 2.25,-18.5 5,-10.25 4,-19 1.25,-32.5 -4.125,-22.375 -1.625,-16.125 -3.75,-18 -2.14474,-10.59452 z"
            transform="translate(40)"
            ></path>
            <path
            style={classesFor("thighs",gradients)}
            d="m 88.130478,412.14352 -1.292471,4.75958 -3.060411,19.35288 -1.75,21.75 3.25,28.25 6.75,16 4.75,8.5 2.75,15.75 4.000004,-10.25 v -10 l -2.5,-5.25 h 11 l 3,11.5 3,11.75 4.75,6 h 5.5 l 6.41015,-4.68555 -3.66015,23.18555 -10.5,15 5,12.75 13,-14.25 4.24999,-24.25 0.75,-7.25 4.75,-20.5 2.75,-21.75 4.75,-27.75 1,-24.25 -1.75,-15.75 v -14.25 l 3,-3.75 -11.75,-11.25 -10.72851,-16.21875 -0.0215,-0.28125 -2.59092,-5.76318 -11.22607,1.86779 -13.6567,3.95396 -15.206913,7.06463 -3.819381,26.8768 z m 49.324642,-33.47804 c -63.733241,282.68872 -31.86662,141.34436 0,0 z"
            ></path>
            <path
            style={classesFor("biceps",gradients)}
            d="m 94.777595,180.25598 -8.24,8.31 -6.81,15.93 -3.45,8.51 -4.63,12.38 -2.37,9.62 -2,8.12 v 11 12.38 l 4.4,11.68 8.23,-6.56 7.13,-5.88 11.77,5.29 0.64,-7.56 1.200005,-27.09 -1.000005,-24.76 -6.12,-18.12 z"
            ></path>
            <path
            style={classesFor("hips",gradients)}
            d="M 61.027344 330.08789 L 58.777344 340.28516 L 56.992188 359.18359 L 52.847656 382.12891 L 68.054688 375.06445 L 81.710938 371.11133 L 92.9375 369.24219 L 87.849609 358.87109 L 86.703125 348.10547 L 81.777344 342.00586 L 63.777344 331.50586 L 61.027344 330.08789 z "
            transform="translate(40)"
            ></path>
            <path
            style={classesFor("hips",gradients)}
            d="M 179.52734 326.00586 L 175.27734 331.00586 L 149.02734 342.75586 L 145.77734 348.50586 L 144.81055 357.59766 L 141.08008 368.20898 L 153.75 369.06641 L 170.48047 372.1582 L 186.88281 378.41211 L 184.06836 360.32617 L 183.27734 336.50586 L 179.52734 326.00586 z "
            transform="translate(40)"
            ></path>
            <path
            style={classesFor("side-delts",gradients)}
            d="m 247.5276,203.38598 6.88,12.24 6.62,-21.87 3.25,-19.37 -0.96,-9.63 -2.54,-7.5 -4.5,-7 -7.87,-6.25 -7.12,-3.5 -10.76,-3.5 h -4.62 l -4,3.5 6.74,0.12 10.63,5.76 7.13,5.5 4.24,12.74 1.38,13.63 z"
            ></path>
            <path
            style={classesFor("front-delts",gradients)}
            d="m 185.5276,146.12598 29.25,-3.37 7.37,-1.87 h 6.76 l 10.37,5.5 7.05,5.72 4.08,12.4 1.5,13.75 -4.26,25.37 -18.5,-24.37 -1.24,-8.87 -22.88,-21.88 z"
            ></path>
            <path
            style={classesFor("upper-chest",gradients)}
            d="m 163.1476,150.38598 22.13,-4.26 20.13,2.63 22.37,21.63 1.25,8.87 -26.12,-19.5 -17.38,-2.63 z"
            ></path>
            <path
            style={classesFor("lower-chest",gradients)}
            d="m 163.1476,150.25598 21.88,6.75 17.62,2.75 18.26,13.75 v 15.75 l -1.38,4.25 -6.75,11.25 -10.23,10.02 -19.9,3.73 h -7.5 l -17.74,-5.62 -5.98,-3.14 -3.96,-9.1 1.31,-16.89 6.25,-20.87 z"
            ></path>
            <path
            style={classesFor("lower-chest",gradients)}
            d="m 95.527595,170.62598 9.120005,-6.87 37.63,-12.37 1.37,1.74 v 31.38 l -2.24,15.25 -6.13,10.5 -4.63,3.5 -12.37,6.5 -5.63,1 h -4.5 l -4.5,-3.13 -4.120005,-6.5 -5.75,-17.87 z"
            ></path>
            <path
            style={classesFor("abdominals",gradients)}
            d="m 118.2776,220.38598 12.28,-6.7 25.72,-1.06 18.5,5.88 4.63,14.5 v 17.38 5.62 l 2,9.89 3.24,11.86 v 15.87 l -1,20.13 -1,7.63 -5.24,10.5 v 17.87 l -11.88,40.13 -13.99,-0.29 -5.14,-5.81 -10.62,-24.78 -2.25,-11.97 v -13.28 l -10.62,-17.37 -1.26,-14.63 -3,-9 -2,-35.25 -3.28,-8.39 -0.3,-13.72 z"
            ></path>
            <path
            style={classesFor("upper-traps",gradients)}
            d="m 186.7776,110.62598 -5.5,11.5 11.25,20.26 h 11.62 l 13.76,-7.5 -10.29,-3.88 -7.59,-4.25 -5.38,-3.63 -4.74,-5.62 z"
            ></path>
            <path
            style={classesFor("obliques",gradients)}
            d="m 105.36054,229.59646 7.91706,-8.35048 -3.76,4.88 2.39,1.88 -1,8 v 4.38 l -3.63,1.87 2.69,2.69 1.06,6.18 1,8 -3.38,1.26 3.38,2 0.88,3.74 1.24,14.76 -2.87,1 3.2,6.15 v 3.97 l 2.55,5.88 -5,5 7.27,6.99 1.23,12.13 5.12,11.38 2.63,5.62 -6.63,0.25 -10.67,-6.18 -8.33,-6.23 2.26,-4.96 -2.5,-9.13 -0.57,-16.11 3.56,-13.15 -0.37,-13.81 -1.62,-9.3 -2.76,-24 z"
            ></path>
            <path
            style={classesFor("obliques",gradients)}
            d="m 181.2776,218.69598 3.64,3.62 -2.51,2.88 v 8.12 l 2,4.13 2.87,1.13 -2.87,3.37 v 14.87 l 5.12,2.5 -3.38,2.26 1.13,8.5 1.5,8.5 4.63,1.24 -1.63,5.38 -3,2.62 1,11.63 7.37,1.13 -8.5,5.62 -2,14.38 -4,12.37 v 11.5 l 1.26,3 2.62,-6.87 7.25,-4.13 18,-6.75 5.63,-7.12 1.12,-11.88 v -11.25 l -2.5,-10.37 0.88,-16.07 -6.01125,-20.46506 2.25308,-5.28567 -6.51723,-6.29762 -1.16549,-3.13451 6.4242,-6.33906 -8.06543,-0.53458 z"
            ></path>
            <path
            style={classesFor("lats-and-teres-major",gradients)}
            d="m 228.1476,186.50598 -4.44,6.42 -1.16,7.21 -1.9,6.49 -1.74,25 -1,15.63 -2,9.13 1,16.62 9.74,-22.09 3.54,-9.72 2.22,-2.69 -4.13,-18.6 z"
            ></path>
            <path
            style={classesFor("upper-traps",gradients)}
            d="m 110.3376,141.20598 3.57,3.64 4.12,1.04 17.12,-0.13 2.76,-2.75 0.94,-13.75 -14.2,8.13 -5.86,2.89 z"
            ></path>
            <path
            style={classesFor("side-delts",gradients)}
            d="m 103.6476,145.62598 -7.740005,-0.12 -5.76,3.25 -5.12,3.75 -5.75,7 -4.25,6.88 -2.62,9.74 v 10.26 l 1,8.37 2,9 1.26,8.19 2.86,-6.53 -2.62,-15.55 v -13.61 l 1.37,-9.13 4.07,-7 4.8,-6 7.38,-4.87 z"
            ></path>
            <path
            style={classesFor("front-delts",gradients)}
            d="m 115.6476,148.50598 -12.12,-2.88 -9.000005,3.63 -7.12,4.63 -4.5,5.24 -4.5,7.88 -1.5,8.88 v 13.24 l 2.62,15.5 3.5,-7.8 3.5,-8.2 8.25,-8.5 1,-9.74 2,-10.5 z"
            ></path>
            <path
            style={classesFor("biceps",gradients)}
            d="m 254.2776,215.75598 2.75,16.5 1.62,28.63 -5.5,13.5 -8.5,-5.76 -7.37,8 v -20.24 l -4.87,-18.63 -4,-17.37 v -31.38 l 0.98,-9.31 18.02,23.81 z"
            ></path>
            <path
            style={classesFor("triceps",gradients)}
            d="m 254.1476,215.25598 3,16.75 1.63,28.75 7.13,-12.83 4.25,-14.26 0.37,-13.66 -3.75,-12 -5.37,-13.96 z"
            ></path>
            <path
            style={classesFor("upper-chest",gradients)}
            d="m 115.6476,149.00598 19.63,-0.5 7.13,2.88 -37.26,12.12 -9.620005,6.88 2.12,-10.26 z"
            ></path>
            <path
            style={classesFor("serratus",gradients)}
            d="m 228.9176,179.14598 -8.12,-5.62 0.12,15.92 -1.37,4.08 -7.37,11.5 -9.63,9.75 -21.5,4.25 10.55506,5.47526 12.22282,6.44824 8.06543,0.53458 -6.4242,6.33906 1.16549,3.13451 6.51723,6.29762 -2.25308,5.28567 6.01125,20.46506 0.5,-12.875 0.5,-12.875 2.27,-40.73 2.12,-6.12 0.75,-7.38 4.87,-6.88 z"
            ></path>
            <path
            d="m 100.0176,211.88598 3.82,6.41 4.31,2.96 5.37,0.12 -8.15706,8.22048 -4.71294,6.78952 0.19098,-11.91268 z"
            style={classesFor("serratus",gradients)}
            ></path>
      </svg>
    )
}

export const FemaleGraphic = ({ gradientColors }) => {
    const gradients = generateGradients(gradientColors,'female');

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="overlay-svg"  width="310" height="800" viewBox="0 0 413.33334 1066.6667" id="female-body-svg">
            <title>Female musculature system</title>
            <desc>Image of female muscular system with color-coded overlays for arm size, waist size, chest size, shoulder size, forearm size, calf size, hip size, and thigh size.</desc>
            <defs>
                {Object.values(gradients).map(({ gradient }) => gradient)}
            </defs>
            <image width="413.33334"
                    height="1066.6667" className="body-underlay" xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={femaleMusculature}></image>
            <path
            style={classesFor("forearms",gradients)}
            d="m 293.70804,370.11281 7.1755,-13.3467 5.57752,3.83161 5.06257,3.97981 4.3606,-12.93275 2.65706,-20.64949 2.89169,20.19798 7.57445,25.11798 4.18899,30.59573 3.21551,42.36055 0.43651,33.47271 -0.77463,10.71633 -23.10193,-0.77601 -8.51945,-35.55605 -9.24302,-27.27055 -6.04707,-26.47156 -5.1347,-29.54605 z"
            id="forearm-left"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 262.97223,806.72606 14.27447,15.2111 8.04647,27.71037 2.25,40.875 -0.375,41.25 0.57096,13.59709 -4.27952,-20.78801 -9.46541,-31.32853 -10.5,-48.94775 -0.79899,-21.43913 z"
            ></path>
            <path
            style={classesFor("calves",gradients)}
            d="m 305.72977,764.24372 -3.27702,30.29973 -1.32853,30.04142 -1.17399,45.26945 -1.27954,20.44596 -0.7745,32.4384 1.01188,38.82674 16.10808,2.02954 7.125,-0.15454 0.61527,-55.29646 2.21326,-42.91212 -1.49243,-45.84041 -4.69597,-34.20606 -6.49747,-20.38004 z"
            ></path>
            <path
            d="m 294.90859,527.74875 -18.31605,-3.79854 -53.35457,-0.53906 -9.54437,13.38785 2.63435,16.77702 3.70101,20.24243 6.06592,24.44002 6.54647,19.97787 5.25,34.85987 4.875,44.12931 3.44849,31.25683 8.13565,29.47379 7.99891,23.98332 0.48055,13.54899 3.47298,8.72119 11.21793,9.78207 7.96542,-0.9139 -1.87679,-15.05257 -9.25431,-11.07853 -11.32349,-15.61995 -9,-21.54647 0.90544,-6.1198 8.21704,1.74406 7.07601,-3.67651 -0.22046,-22.86743 8.36491,-6.76514 9.23559,3.89697 8.21794,13.7111 6.25252,15.90455 5.6495,1.43587 -0.61996,-6.57675 0.30909,-12.17904 1.20605,-26.90201 0.67652,-44.35555 1.00341,-31.96452 1.55746,-26.58448 -2.82853,-31.79394 0.25992,-32.01525 z"
            style={classesFor("thighs",gradients)} ></path>
            <path
            d="m 285.02912,430.67258 -13.96541,16.2889 -11.41032,11.23308 -16.46037,27.40128 -5.04564,11.64022 -14.90941,26.17509 30.06711,1.11698 23.28746,-0.57792 18.31605,3.79854 14.38555,6.92367 -5.46862,-34.54208 -7.17896,-29.33854 z"
            style={classesFor("hips",gradients)} ></path>
            <path
            style={classesFor("front-delts",gradients)}
            d="m 237.62498,172.03863 20.70424,6.53036 28.8962,1.32 16.43133,7.11207 10.11497,15.80353 2.25,18.62752 -1.19656,27.66812 -1.0949,23.64627 -12.68026,-24.69268 -16.37651,-22.78788 5.39782,-14.61261 -3.96835,-8.27148 -27.75162,-0.93979 -25.35466,2.25618 -13.92911,3.8313 -2.6514,-11.44086 8.00176,-13.74279 7.20277,-14.14228 z"
            ></path>
            <path
            style={classesFor("lower-chest",gradients)}
            d="m 284.67349,225.26594 -3.53406,12.52197 1.66126,8.91894 5.41776,16.63005 1.99747,12.03963 -0.0725,13.16643 -6.39331,13.36897 -9.26148,9.14284 -17.80623,1.80196 -8.26265,-4.10618 -16.25634,-6.68029 -17.85433,-17.86617 -5.48947,-18.10925 -2.02995,-27.33248 7.13769,-22.60758 5.14022,-8.62524 13.92911,-3.8313 25.35466,-2.25618 27.75162,0.93979 3.96835,8.27148 z"
            ></path>
            <path
            style={classesFor("lower-chest",gradients)}
            d="m 121.80074,279.09981 4.09211,-21.09137 3.71161,-16.15803 -7.93239,-30.54812 2.54679,-6.65042 25.71752,-3.45445 21.44791,-1.12799 19.45042,4.86445 9.08853,20.45653 1.89759,18.05955 -0.64917,23.31174 -3.65519,17.4033 -15.62353,14.84395 -20.59885,13.7261 -6.53395,1.29935 -10.75238,1.11042 -7.67147,-2.84601 -4.06803,-6.03798 -4.13202,-0.42661 -4.96555,-15.21014 z"
            id="chest-right"
            ></path>
            <path
            style={classesFor("abdominals",gradients)}
            d="m 181.89294,299.00895 15.62353,-14.84395 9.01734,7.99789 7.77525,-7.9588 17.85433,17.86617 16.25634,6.68029 1.99937,27.32577 -3.32774,13.83203 0.39949,11.42065 0.49748,26.20004 -4.29647,23.42986 2.52686,20.94344 -7.59041,30.40046 -15.82253,33.02932 -10.99761,27.92917 -18.89587,-3.12207 -6.74161,-21.19018 -3.77449,-17.55551 -10.38688,-18.72151 -4.34464,-34.04491 -1.49051,-16.75155 -2.90201,-21.88941 2.59294,-44.88509 -2.52303,-14.18298 -2.04798,-18.18303 z"
            ></path>
            <path
            style={classesFor("obliques",gradients)}
            d="m 134.55267,323.21155 9.45509,-8.06673 10.75238,-1.11042 1.69597,12.799 -3.59546,8.96697 4.94188,11.5939 -1.95743,9.62793 -6.00042,0.4811 6.69344,5.60808 0.52294,12.67637 -3.3194,8.59294 4.51596,8.40647 0.26202,8.15766 -6.30248,5.89445 7.59647,5.23004 -1.99748,10.34945 -2.66215,3.43067 -0.24771,14.00415 7.68,17.07 2.34702,12.02546 -8.34702,-3.22046 -8.81408,-14.46344 -7.70106,-7.74702 -6.19789,-13.03294 -3.75,-12.09701 4.73794,-12.97913 3.74202,-19.32551 -0.555,-22.71247 -2.03051,-13.55051 -1.74303,-16.42472 z"
            ></path>
            <path
            style={classesFor("obliques",gradients)}
            d="m 252.42324,319.24519 3.06301,14.2189 -2.56651,14.70688 1.59798,10.18252 -1.69596,12.86443 2.29899,10.80021 -3.90535,10.54142 4.11839,12.1789 -5.09642,9.55403 0.93708,14.06918 1.91691,12.94514 -4.99747,16.37656 -9.59546,12.56257 4.55229,4.85807 19.56741,-25.31339 9.64349,-6.68505 8.07193,-16.62151 -1.19849,-7.68661 -8.14445,-20.34894 -1.47647,-19.31106 6.16397,-25.90365 0.58315,-10.72497 -0.18797,-8.24795 1.04825,-11.09368 -5.54454,-7.91061 -14.89462,-2.39985 z"
            ></path>
            <path
            style={classesFor("front-delts",gradients)}
            d="m 155.17671,179.52558 -24.27061,0.55195 -22.97903,6.02385 -9.195484,12.68324 -5.659838,23.46637 3.195963,65.84286 0.409821,-9.12944 5.615878,-19.47865 11.20881,-14.47488 11.70779,-16.74331 -3.53794,-16.96528 2.54679,-6.65042 25.71752,-3.45445 21.44791,-1.12799 19.45042,4.86445 5.53176,-7.24136 -11.54092,-23.50595 -9.14395,-8.32513 -9.54345,9.65216 z"
            ></path>
            <path
            style={classesFor("biceps",gradients)}
            d="m 313.73026,272.7465 2.78053,28.08764 2.0305,30.16115 -2.65706,20.64949 -4.3606,12.93275 -5.06257,-3.97981 -5.57752,-3.83161 -7.1755,13.3467 -9.6804,3.72354 -0.89151,-16.25123 -0.49201,-19.84669 -5.52258,-14.57124 6.62855,-21.25526 6.39333,-13.36897 0.0725,-13.16643 -1.99747,-12.03963 -7.07902,-25.54899 3.53406,-12.52197 16.37651,22.78788 z"
            ></path>
            <path
            d="m 128.13624,305.83422 4.13202,0.42661 4.06803,6.03798 7.67147,2.84601 -9.45509,8.06673 -0.27799,10.18428 z"
            style={classesFor("serratus",gradients)}
            ></path>
            <path
            style={classesFor("serratus",gradients)}
            d="m 256.68238,312.85673 17.80623,-1.80196 9.26148,-9.14284 -2.31592,12.43389 -4.31263,8.82137 -5.54454,-7.91061 z"
            ></path>
            <path
            d="m 116.17546,369.91848 -6.776,-12.94721 -5.97702,6.62808 -5.06257,3.97981 -4.3606,-12.93275 -2.65706,-20.64949 -3.291185,18.99949 -5.576973,29.51243 -5.786972,27.39977 -0.818538,42.36055 -0.03701,19.88987 -1.222847,17.10826 22.302939,-0.77602 8.519446,-28.36513 9.24302,-27.27055 6.04707,-26.47156 5.1347,-29.54605 z"
            style={classesFor("forearms",gradients)} ></path>
            <path
            d="m 96.15324,275.74813 -2.78053,28.08764 -2.0305,30.16115 2.65706,20.64949 4.3606,12.93275 5.06257,-3.97981 5.97702,-6.62808 6.776,12.94721 9.6804,6.9195 0.89151,-16.25123 0.49201,-19.84669 2.72611,-20.56367 -1.82925,-14.34217 -4.96555,-15.21014 -1.36995,-11.52427 7.80372,-37.2494 -4.39445,-13.58284 -22.9166,31.21819 z"
            style={classesFor("biceps",gradients)} ></path>
            <path
            style={classesFor("hips",gradients)}
            d="m 122.98575,434.00562 13.40043,12.92747 7.9962,6.02697 13.79689,15.36743 14.65016,30.0018 9.82467,20.80786 -21.4513,1.8232 -27.66599,4.36558 -15.77368,5.77595 -16.64543,4.94626 4.62116,-28.32739 7.17896,-29.33854 z"
            ></path>
            <path
            style={classesFor("thighs",gradients)}
            d="m 101.1177,536.04814 32.41911,-10.72221 27.66599,-4.36558 21.4513,-1.8232 4.57491,11.31562 7.77088,6.52168 -4.8995,38.21972 -6.06592,24.44002 -3.75,23.17383 -3.25253,36.45785 -1.27954,40.13436 -3.44849,30.85734 -9.33413,28.2753 -2.80547,8.403 -0.48055,13.54899 -7.06844,13.11564 -4.82601,3.39014 -4.76945,-7.70532 5.07275,-8.26115 5.65885,-9.08105 3.33358,-9.62752 6.60303,-22.34546 -8.09635,-2.12485 -1.82512,-11.03979 -7.875,-6.87247 -5.77197,-5.28964 -7.16642,4.82023 -2.44417,9.8894 -5.82097,8.51766 -6.65202,11.11061 -4.45101,-1.3606 0.61996,-16.56414 -0.30909,-18.17147 -1.20605,-16.51513 -3.87248,-40.76009 -1.00341,-30.76603 -3.55494,-26.98398 -3.1639,-29.79646 0.93857,-12.83947 z"
            ></path>
            <path
            d="m 160.89956,787.15078 -11.07851,15.6106 -8.04647,27.71037 -2.25,40.875 0.375,41.25 2.22551,6.80567 1.48305,-13.99659 10.6639,-53.70027 5.30656,-27.375 2.39697,-21.03964 z"
            style={classesFor("calves",gradients)} ></path>
            <path
            d="m 120.13949,755.45482 3.27702,30.29973 4.125,35.63436 3.57096,32.88509 1.67904,24.84041 1.97298,25.64698 -0.61238,18.85197 -8.91717,-0.76693 -9.52197,-1.35303 -4.21073,-27.73128 -3.81124,-41.71363 -4.5,-49.83536 4.29648,-33.80657 2.50251,-11.59114 z"
            style={classesFor("calves",gradients)} ></path>
        </svg>
    )
}

