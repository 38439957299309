import * as actionTypes from 'redux/actionTypes';
import { store } from 'redux/initializer';
import * as api from 'lib/api';
import camelCase from 'camelcase';
import * as _ from 'lib/utilities';
import { clearCurrentUser } from 'lib/utilities';
import { getComprehensiveWodSelector, cachedWorkoutRecordsSelector, getWodSelector, cachedProgexSelector, userSelector, trainerSelector, warmupsNeedLoadingSel, trainerDataForSwitchSel, trainerCacheSelector, needToLoadExerciseGroupsSel, isSignedInAsClientSel, chatUserSelector, usersSelector, clientFiltersSelector, dailyNutritionProfilesSel } from 'redux/selectors';
import moment from 'moment';
import { dateFormat } from 'config/settings';
import { matchPath } from 'react-router-dom';
import { validResponses } from 'components/LoadingHOC';
import { Recipe } from 'lib/recipe';
import { GroceryItem } from 'lib/classes';
import { GENERATE_FIRST_MP_TIP } from 'config/tooltips';
import * as UpChunk from '@mux/upchunk/dist/upchunk.mjs'
import { healthkitCallWrapper } from './helpers';

//none - don't hit cache
//cache-if-exists - if cache exists only hit cache
//cache-on-fail - if request fails then resolve with cache


const syncToHealthkitFromOwnedHandler = (date,dispatch) => {
    const needed = [
        'activity',
        'weight',
        'nutrition.calories',
        'nutrition.fat.total',
        'nutrition.carbs.total',
        'nutrition.dietary_fiber',
        'nutrition.protein'
    ];
    const completed = [];
    const successHandlerCreator = dataType => () => {
        completed.push(dataType);
        if(_.difference(needed,completed).length === 0) {
            dispatch(getOwnedHealthkitData(date,data => dispatch => {
                const { healthkitData } = data;
                healthkitData.forEach(obj => {
                    if(obj.value && obj.value.mealType) {
                        obj.value.meal_type = obj.value.mealType;
                    }

                    if(obj.value && obj.value.nutrients) {
                        obj.value.nutrients = _.mapKeys(obj.value.nutrients,(val,macro) => _.macroToHealthkitKey[macro]);
                    }

                    ['startDate','endDate'].forEach(dtAttr => {
                        if(obj[dtAttr]) {
                            obj[dtAttr] = moment(obj[dtAttr]).toDate();
                        }
                    })
                    navigator.health.store({ ...obj },() => console.log(`Stored ${obj.dataType} successfully...`), err => console.log(err));
                })
            }));
        }
    }

    healthkitCallWrapper(() => {
        needed.forEach((dataType) => {
            navigator.health.delete({
                startDate: moment(date).toDate(),
                endDate: moment(date).add(24,'hours').toDate(),
                dataType
            }, successHandlerCreator(dataType), err => console.log(`Failed to delete ${dataType}... ${err}`));
        })

    }, () => console.log('Falling back because HK not available for some reason...'));
}

const logTrainerSubscription = () => {

}

const logSignupEvent = (email) => {
    const cordova = window.cordova;

    if(cordova) {
        cordova.plugins.firebase.analytics.logEvent("signup", {value: 1.00});
    } else {

    }
}

const userIsCached = (state) => !!state.data.user;
const recentUserMealsCached = (page) => (state) => (state.data.recentUserMeals && state.data.recentUserMeals.page >= page)
const recipesCached = (ids) => (state) => {
    return (state.data.recipes && _.every(ids,(id) => (state.data.recipes[id] && !!state.data.recipes[id].ingredientIds)))
}

const workoutIsCached = (id) => state => Object.values(_.selectWithFKs(state.data.exerciseSpecifications,'workoutId',[id])).length > 0
const progressionIsCached = id => state => Object.values(_.selectWithFKs(state.data.progressionExercises,'exerciseProgressionId',[id])).length > 0
const workoutProgressionIsCached = (date,id) => state => {
    const progexes = cachedProgexSelector(date)(state);
    if(progexes) {
        return Object.values(_.selectWithFKs(progexes,'exerciseProgressionId',[id])).length > 0
    }
    return false;
}
const workoutRoutineIsCached = id => state => Object.values(_.selectWithFKs(state.data.routineCycles,'workoutRoutineId',[id])).length > 0;
const progressionRequirementsCached = etId => state => Object.values(_.selectWithFKs(state.data.exerciseProgressionRequirements,'exerciseTemplateId',[etId])).length > 0;
const dayCacheCheckCreator = reducerName => state => state.data[reducerName] && state.data[reducerName].timestamp && !moment(state.data[reducerName].timestamp).isBefore(moment(),'day')
const mealSearchCategoriesCached = dayCacheCheckCreator('mealSearchCategories');
const recentRecipesCachedCreator = page => state => (dayCacheCheckCreator('recentRecipes')(state) && state.data.recentRecipes.page >= page);
const recipeDraftIsCached = foodWeightIds => state => (_.noBlanks(foodWeightIds).length === 0 || (state.data.foodWeights && _.every(foodWeightIds,fwId => !!state.data.foodWeights[fwId])))

const passApiCheck = (response,dispatch,showPopup) => {
    const { cacheInvalid, loginRequired: needToLogin, trainersOnly, trainerLoginRequired: tLoginReq, onboardingRequired: needOnboarding, proExpired, forceReload, quotaHit, ...data } = response;
        if (needToLogin) {
            dispatch(loginRequired());
            return null;
        } else if(tLoginReq) {
            dispatch(trainerLoginRequired());
            return null;
        } else if(trainersOnly) {
            dispatch(trainersOnlyAction());
            return null;
        } else if (needOnboarding) {
            dispatch(onboardingRequired());
            return null;
        } else if (proExpired) {
            dispatch(proRequired({ proExpires: proExpired }));
            return null;
        } else if (cacheInvalid) {
            dispatch(refreshCache(data,showPopup));
            return null;
        } else if(forceReload) {
            window.location.reload();
            return null;
        }
        return data;
}

let callsBlocked = false;

//for actions that can happen either by a client or a trainer in trainer space on behalf of a client, pass clientId as userOverrideId and don't set isUserSwitch
const userSpaceApiCall = ({ apiCall, 
    success, dispatch, state, cacheType, 
    cacheCheck, showPopup=true, popupOnFail, 
    flashOnSuccess, popupOnSuccess, hkSyncOnSuccess,
    flashTimeout, onFail, overrideUserId, 
    isUserSwitch=false, waitForSuccess=false }) => {

    if(cacheType === 'cache-if-exists' && cacheCheck(state)) {
        return Promise.resolve({status: 'SUCCESS', data:  true});
    }

    if(callsBlocked && !isUserSwitch) { //allow user switches to queue because we know this won't have unintended side effects (since it just overwrites previous switch)
        return Promise.reject({ status: 'SERVERERR' });
    }

    callsBlocked = isUserSwitch;
    const currentUser = userSelector(store.getState());
    const userId = !_.isBlank(overrideUserId) ? Number(overrideUserId) : (currentUser ? currentUser.id : null);
    //skip breaking check when this is a client action happening by a trainer not signed in as the client
    //technically could be ok for use switches as well, but we DO want to pull in breaking update tstamp
    //on a user switch so behavior is different enough, probably more future-proof to exclude user switches
    const skipBreakCheck = currentUser && currentUser.id !== userId && !isUserSwitch;

    return apiCall()(userId,skipBreakCheck)
    .then(response => {
        const data = passApiCheck(response,dispatch,showPopup);
        let successProm = null;
        if (data && success) {
            successProm = dispatch(success(data));
        }

        if(hkSyncOnSuccess && data && data.date) {
            syncToHealthkitFromOwnedHandler(data.date,dispatch);
        }

        if(flashOnSuccess && !data.error) {
            dispatch(showSaveFlash(flashOnSuccess === true ? null : flashOnSuccess,flashTimeout));
        } else if(popupOnSuccess && !data.error) {
            dispatch(requestSucceeded(popupOnSuccess))
        }

        const resolution = (!cacheType || cacheType === 'none') ? { status: 'SUCCESS', data } : { status: 'SUCCESS', data: true };
        callsBlocked = false;
        if(waitForSuccess && successProm) {
            return successProm.then((res) => {
                return Promise.resolve(resolution);
            })
        }
        return Promise.resolve(resolution);
    })
    .catch(error => {
        callsBlocked = false;
        if(validResponses.includes(error.message)) {
            if(cacheType === 'cache-on-fail' && cacheCheck(state)) {
                return Promise.resolve({status: 'SUCCESS', data: true});
            }
    
            if(popupOnFail) {
                dispatch(requestFailed({ status: error.message }));
            }
    
            if(onFail) {
                dispatch(onFail());
            }
    
            return Promise.reject({ status: error.message, data: error });
        } else {
            console.log(error.message);
            console.log(error);
            throw error
        }
    });
}

export class ActionCreator {
    static createLoader(name,cacheType,cacheCheck=userIsCached,showPopup=true) {
        
        this[camelCase(name) + 'Success'] = (data) => ({
            type: name + '_SUCCESS',
            data
        });
    
        this[camelCase(name)] = (sendData) => (dispatch,getState) => {
            const state = getState();
            const apiCall = api[camelCase(name)].bind(null,{ data: sendData });
            const success = this[camelCase(name) + 'Success'];
            return userSpaceApiCall({ apiCall, success, dispatch, state, cacheType, cacheCheck, showPopup  });
        }
    
        return this[camelCase(name)];
    }
}

const loadUserSuccess = allowFullReload => data => (dispatch,getState) => {
    let trainerCache = trainerDataForSwitchSel(getState());
    
    applyBranding(data);
    if(trainerCache && data.trainerData && data.trainerData.trainer && (_.isBlank(data.user) || data.user.id !== trainerCache.user.id)) {
        dispatch(switchClientFinal(data,trainerCache))
    } else {
        dispatch({ type: actionTypes.LOAD_USER_SUCCESS, allowFullReload, data })
    }
}

export const loadUser = data => (dispatch) => {
    const apiCall = api.loadUser.bind(null, { ...data });
    const success = loadUserSuccess(true);
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        cacheType: 'none', 
        showPopup: false  
    });
}

const refreshStore = () => {
    if(window.cordova) {
        const store = _.getIapStore();
        store.ready(() => {
            const prod = _.isAndroid() ? store.get(`${_.packagePrefix()}.annually2022`,_.getIapPlatform()) : store.get(`${_.packagePrefix()}.annuallynow`,_.getIapPlatform());
            if(prod && prod.duplicate) {
                prod.duplicate = false;
                prod.duplicateInfo = null;
                store.update().then(() => store.restorePurchases());
            }
        })
    }
}

const applyBranding = ({ branding }) => {

    if(branding) {
        const { iconUrl, appHue, appSat, appLum, appName, appSkipTracking } = branding;
        document.getElementById('icon-link1').href = iconUrl;
        document.getElementById('icon-link2').href = iconUrl;
        _.setAppColor({h: appHue, s: appSat, l: appLum },false);
        window.appBrandName = appName;
        window.skipTracking = `${appSkipTracking}`;
        if(appSkipTracking) {
            window.fbq = () => {};
        }
    }
}

const loginUserSuccess = (data) => (dispatch) => {

    if(!data.error) {
        applyBranding(data);
        if(data.user && data.user.role === 'admin') {
            localStorage.setItem('adminId',data.user.id);
        }
        dispatch({ type: actionTypes.LOGIN_USER_SUCCESS, data });
        refreshStore();
    }
}

export const loginUser = (data) => (dispatch) => {
    const apiCall = api.loginUser.bind(null,{ data });
    const success = loginUserSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        cacheType: 'none'
    });
}

const logoutUserSuccess = () => dispatch => {
    localStorage.removeItem('adminId');
    dispatch({ type: actionTypes.LOGOUT_USER });
}

export const logoutUser = () => (dispatch) => {
    const apiCall = api.logoutUser;
    const success = logoutUserSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        popupOnFail: true
    });
}

export const destroyUser = () => (dispatch) => {
    const apiCall = api.destroyUser;
    const success = logoutUserSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        popupOnFail: true
    });
}

const handleSignedInAsClient = (data,dispatch,getState) => {
    const state = getState();
    const isSignedInAsClient = isSignedInAsClientSel(state);
    if(isSignedInAsClient) {
        return dispatch({
            type: actionTypes.TSAC_SIGNUP_SUCCESS,
            data
        })
    }

    return null;
}

const handleSignupEvent = data => {
    if(data.user && !_.isBlank(data.user.email)) {
        if(data.user.type !== 'TrainerClient' && data.user.type !== 'Trainer') {
            logSignupEvent(data.user.email);
        }
    }
}

const newEmailUserSuccess = (data) => (dispatch,getState) => {

    if(data.error) {
        return {
            type: actionTypes.NOOP,
            data
        }
    } else {
        handleSignupEvent(data);

        const handledDispatch = handleSignedInAsClient(data,dispatch,getState);
    
        return handledDispatch || dispatch({
            type: actionTypes.EMAIL_SIGNUP_SUCCESS,
            data
        })
    }
}

export const newEmailUser = (data) => (dispatch) => {
    const apiCall = api.emailSignup.bind(null,{ data });
    const success = newEmailUserSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const verifyOauthSuccessFor = (signup) => (data) => (dispatch, getState) => {
    if(data.error) {
        return {
            type: actionTypes.NOOP,
            data
        }
    } else {
        let handledDispatch = null;

        if(signup) {
            handleSignupEvent(data);
            handledDispatch = handleSignedInAsClient(data,dispatch,getState);
        } else {
            applyBranding(data);
            refreshStore();
        }
        
        return handledDispatch || dispatch({
            type: actionTypes.VERIFY_OAUTH_SUCCESS,
            data
        })
    }
}

export const verifyOauth = (data) => (dispatch) => {
    const apiCall = api.verifyOauth.bind(null,{ data });
    const success = verifyOauthSuccessFor(data.signup);
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const recentUserMealsSuccess = (data) => ({
    type: actionTypes.RECENT_USER_MEALS_SUCCESS,
    data
});
export const loadRecentUserMeals = (page) => (dispatch, getState) => {
    const state = getState();
    const apiCall = api.loadRecentUserMeals.bind(null,page);
    const success = recentUserMealsSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        state, 
        cacheType: 'cache-if-exists', 
        cacheCheck: recentUserMealsCached(page)
    });
};

const logOffPlanSuccess = (replaceeId) => (data) => ({
    type: actionTypes.LOG_OFF_PLAN_SUCCESS,
    data,
    replaceeId
})

export const logOffPlan = (parentId,userMeal,userMealId) => (dispatch) => {
    const apiCall = api.logOffPlan.bind(null,parentId,userMeal);
    const success = logOffPlanSuccess(userMealId);
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        hkSyncOnSuccess: true
    });
}

export const refreshCache = (data,showPopup) => ({type: actionTypes.REFRESH_CACHE, data, showPopup})

export const dismissCacheRefreshed = () => ({type: actionTypes.DISMISS_REFRESH_CACHE})

export const trainerLoginRequired = () => ({type: actionTypes.TRAINER_LOGIN_REQUIRED})
export const loginRequired = () => ({type: actionTypes.LOGIN_REQUIRED})

export const trainersOnlyAction = () => ({ type: actionTypes.TRAINERS_ONLY })

export const onboardingRequired = () => ({type: actionTypes.ONBOARDING_REQUIRED})

export const proRequired = (data) => ({type: actionTypes.PRO_REQUIRED, data})

export const requestFailed = (data) => ({type: actionTypes.REQUEST_FAILED, data})

export const requestSucceeded = (data) => ({ type: actionTypes.REQUEST_SUCCEEDED, data })

export const clientMpQuotaHit = () => ({ type: actionTypes.CLIENT_MP_QUOTA_HIT })
export const freeTrialExportQuotaHit = () => ({ type: actionTypes.FREE_TRIAL_EXPORT_QUOTA_HIT })
export const mscExportQuotaHit = () => ({ type: actionTypes.MSC_EXPORT_QUOTA_HIT })

export const touch = ActionCreator.createLoader('TOUCH_USER','none');

export const setVideoAutoplay = (value) => ({type: actionTypes.SET_VIDEO_AUTOPLAY, data: { value }})
export const deviceReady = () => ({type: actionTypes.DEVICE_READY});

export const switchTrainerType = data => ({
    type: actionTypes.SWITCH_TRAINER_TYPE,
    data
})

const foodDBSearchSuccess = (data) => ({
    type: actionTypes.FOOD_DB_SEARCH_SUCCESS,
    data
})

export const foodDBSearch = (page,query) => (dispatch) => {
    const apiCall = api.foodDBSearchResults.bind(null,query,page);
    const success = foodDBSearchSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const populateFatsecretSuccess = (data) => ({
    type: actionTypes.POPULATE_FATSECRET_SUCCESS,
    data
})

export const populateFatsecret = (fatsecretId) => (dispatch) => {
    const apiCall = api.populateFatsecret.bind(null,fatsecretId);
    const success = populateFatsecretSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const logFoodFromDBSuccess = (data) => ({
    type: actionTypes.LOG_FOOD_FROM_DB_SUCCESS,
    data
})

export const logFoodFromDB = (parentId,data,type) => (dispatch) => {
    let apiCall = {food:  api.logFood, recipe: api.logRecipe, new: api.createAndLogOffPlan}[type];
    apiCall = apiCall.bind(null,parentId,data);
    const success = logFoodFromDBSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        hkSyncOnSuccess: true
    });
}

const offPlanRecipeSearchSuccess = () => (data) => ({
    type: actionTypes.LOAD_RECIPE_SEARCH_RESULTS,
    data
})

export const offPlanRecipeSearch = (queryParams,page) => (dispatch) => {
    const apiCall = api.offPlanRecipeSearch.bind(null,queryParams,page);
    const success = offPlanRecipeSearchSuccess(queryParams);
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const loadRecipesSuccess = (data) => ({
    type: actionTypes.LOAD_RECIPES_SUCCESS,
    data
})

export const loadRecipes = (ids) => (dispatch,getState) => {
    const state = getState();
    const apiCall = api.loadRecipes.bind(null,ids);
    const success = loadRecipesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        state, 
        cacheType: 'cache-if-exists', 
        cacheCheck: recipesCached(ids)
    });
}

export const loadAllRecipeVersions = (ids) => (dispatch,getState) => {
    const state = getState();
    const apiCall = api.loadAllRecipeVersions.bind(null,ids);
    const success = loadRecipesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        state, 
        cacheType: 'none'
    });
}

const loadCategorizeRecipesSuccess = (data) => ({
    type: actionTypes.LOAD_CATEGORIZE_RECIPES,
    data
})

export const myRecipesLoad = () => (dispatch) => {
    const apiCall = api.myRecipesLoad;
    const success = loadCategorizeRecipesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        cacheType: 'none'
    });
}

export const loadDislikedRecipes = () => (dispatch) => {
    const apiCall = api.loadDislikedRecipes;
    const success = loadCategorizeRecipesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        cacheType: 'none'
    });
}

const loadTeamRecipesSuccess = (data) => ({
    type: actionTypes.LOAD_TEAM_RECIPES,
    data
})

export const teamRecipesLoad = (trainerId) => (dispatch) => {
    const apiCall = api.teamRecipesLoad.bind(null,trainerId);
    const success = loadTeamRecipesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        cacheType: 'none'
    });
}

export const updateMyRecipesFilters = newFilters => ({
    type: actionTypes.UPDATE_MY_RECIPES_FILTERS,
    data: newFilters
})

export const updateTeamRecipesFilters = newFilters => ({
    type: actionTypes.UPDATE_TEAM_RECIPES_FILTERS,
    data: newFilters
})

export const dismissTooltip = (tipName) => (dispatch) => {
    api.dismissTooltip(tipName).catch((e) => { console.log('failed to dismiss tooltip'); console.log(e); });
    dispatch({ type: actionTypes.DISMISS_TOOLTIP, data: { tipName } });
}

export const advanceSignupFlow = (data) => ({
    type: actionTypes.ADVANCE_SIGNUP_FLOW,
    data
})

export const advanceOnboardingFlow = (data) => ({
    type: actionTypes.ADVANCE_ONBOARDING_FLOW,
    data
})

export const advanceWorkoutSetup = (data) => ({
    type: actionTypes.ADVANCE_WORKOUT_SETUP,
    data
})

const loadBannableProgsSuccess = (data) => ({
    type: actionTypes.LOAD_BANNABLE_PROGS_SUCCESS,
    data
})

export const advanceTrainerSignup = (data) => ({
    type: actionTypes.ADVANCE_TRAINER_SIGNUP,
    data
})

export const loadBannableProgs = () => (dispatch,getState) => {
    const state = getState();
    const apiCall = api.loadBannableProgs;
    const success = loadBannableProgsSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch, 
        state
    });
}

export const loadUserWorkoutTimes = ({ newRoutine, ...data } ) => (dispatch) => {
    const apiCall = api.possibleWorkoutTimes.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

const possibleRoutinesSuccess = (data) => ({
    type: actionTypes.POSSIBLE_ROUTINES_SUCCESS,
    data
})

export const loadPossibleRoutines = ({ newRoutine, ...data }) => (dispatch) => {
    const apiCall = api.possibleRoutines.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: possibleRoutinesSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const unpossibleRoutinesSuccess = (data) => ({
    type: actionTypes.UNPOSSIBLE_ROUTINES_SUCCESS,
    data
})

export const loadUnpossibleRoutines = ({ newRoutine, ...data }) => (dispatch) => {
    const apiCall = api.unpossibleRoutines.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: unpossibleRoutinesSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const assignRoutineSuccess = (data) => ({
    type: actionTypes.ASSIGN_NEW_ROUTINE_SUCCESS,
    data
})

export const assignNewRoutine = (data) => (dispatch) => {
    let apiCall = api.assignNewRoutine.bind(null, data);
    const success = assignRoutineSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const assignClientRoutinesSuccess = (data) => ({
    type: actionTypes.ASSIGN_CLIENT_ROUTINES,
    data
})

export const assignClientRoutines = (data) => (dispatch) => {
    let apiCall = api.assignClientRoutines.bind(null, data);
    const success = assignClientRoutinesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        popupOnFail: true
    });
}

const syncClientRoutinesSuccess = (data) => ({
    type: actionTypes.SYNC_CLIENT_ROUTINES,
    data
})

export const syncClientRoutines = (data) => (dispatch) => {
    let apiCall = api.syncClientRoutines.bind(null, data);
    const success = syncClientRoutinesSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        popupOnFail: true
    });
}

export const advanceMealPlanSetup = (data) => {
    return {
        type: actionTypes.ADVANCE_MEAL_PLAN_SETUP,
        data
    }
}

const updateMealPlanSuccess = (data) => ({
    type: actionTypes.UPDATE_MEAL_PLAN_SUCCESS,
    data
})

export const initMacros = ({ nutritionParameters, calorieOverride, mealPlanWeekday, ...data}) => (dispatch) => {
    data = parseExtraKeywords(data);
    const apiCall = api.initMacros.bind(null,data);
    const success = updateMealPlanSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success
    });
}

const parseMealPlanSetupParams = data => {
    const { dailyNutritionProfiles: { 0: { id, main, nutritionParameters, fiberGoal, overrideCalories, calorieOverride }={} }={}, mealPlanWeekday } = data;
    const params = overrideCalories === undefined ? { mealPlanWeekday } : { dailyNutritionProfiles: [{ id, main, nutritionParameters, fiberGoal, overrideCalories, calorieOverride }], mealPlanWeekday };
    return params;
}

const initialRecipeOptionsSuccess = (success) => (data) => {
    success(data);
    return {
        type: actionTypes.INITIAL_RECIPE_OPTIONS,
        data
    }
}

export const loadInitialRecipeOptions = (data,success) => (dispatch,getState) => {
    const state = getState();
    let apiCall = api.initialRecipeOptions;
    if(data) {
        apiCall = apiCall.bind(null,parseMealPlanSetupParams(data));
    }
    
    success = initialRecipeOptionsSuccess(success);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        state
    });
}

export const resetInitialRecipeOptions = () => ({
    type: actionTypes.RESET_INITIAL_RECIPE_OPTIONS
})

const recipeOptionsCategorySuccess = (data) => ({
    type: actionTypes.RECIPE_OPTIONS_CATEGORY_SUCCESS,
    data
})

export const getRecipeOptionsCatPage = (params) => (dispatch) => {
    const apiCall = api.getRecipeOptionsCatPage.bind(null,params);
    const success = recipeOptionsCategorySuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success
    });
}

const fullMealPlanUpdate =  (data) => ({
    type: actionTypes.FULL_MEAL_PLAN_UPDATE,
    data
})

const startMealPlanSuccess = data => dispatch => {
    const { clientMpQuotaHit } = data;

    if(!clientMpQuotaHit) {
        dispatch(fullMealPlanUpdate(data));
    }
}

export const startMealPlan = (selectedRecipes,startDate) => (dispatch) => {
    setTimeout(() => dispatch(dismissTooltip(GENERATE_FIRST_MP_TIP)),1); //maybe timeout will fix weird data sync bug can't reproduce
    const data = startDate ? { selectedRecipes, startDate } : { selectedRecipes };
    const apiCall = api.startMealPlan.bind(null,data);
    const success = startMealPlanSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success
    });
}

export const regenerateMealPlan = (data) => (dispatch) => {
    const apiCall = api.regenerateMealPlan.bind(null,data);
    const success = fullMealPlanUpdate;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}

export const copyMealPlanDay = (data) => (dispatch) => {
    const apiCall = api.copyMealPlanDay.bind(null,data);
    const success = fullMealPlanUpdate;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}

export const initStripeSub = (data) => (dispatch) => {
    const apiCall = api.initStripeSub.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const initStripeCheckout = (data) => (dispatch) => {
    const apiCall = api.initStripeCheckout.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

const restoreSuccess = data => dispatch => {
    if(data.success) {
        dispatch(initProSuccess({ proExpires: data.proExpires }))
    }
}

export const restoreSubscription = () => (dispatch) => {
    const apiCall = api.restoreSubscription;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: restoreSuccess
    });
}

export const androidStepsUpdate = data => (dispatch) => {
    const apiCall = api.androidStepsUpdate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const setScannerDeviceId = deviceId => ({
    type: actionTypes.SET_SCANNER_DEVICE_ID,
    data: { deviceId }
})

export const updateStripeCard = (type,data) => (dispatch) => {
    const apiCall = type === 'trainer' ? api.updateTStripeCard.bind(null,data) : api.updateStripeCard.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: loadSubscriptionSuccess
    });
}

export const cancelSubscription = (type,feedback=null) => (dispatch) => {
    if(feedback && process.env.REACT_APP_TEST !== 'true') {
        api.submitTCancelFeedack(feedback);
    }
    const apiCall = type === 'trainer' ? api.cancelTSubscription.bind(null,feedback && feedback.call) : api.cancelSubscription;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Subscription cancelled' },
        success: loadSubscriptionSuccess
    });
}

export const uncancelSubscription = (type) => (dispatch) => {
    const apiCall = type === 'trainer' ? api.uncancelTSubscription : api.uncancelSubscription;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        success: loadSubscriptionSuccess
    });
}

export const addBrandedApp = () => (dispatch) => {
    const apiCall = api.addBrandedApp;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        success: loadSubscriptionSuccess
    });
}

export const loadBrandedAppSetupInfo = () => (dispatch) => {
    const apiCall = api.loadBrandedAppSetupInfo;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const createBrandedApp = (values) => (dispatch) => {
    const apiCall = api.createBrandedApp.bind(null,values);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Submitted' }
    });
}

export const loadBrandedApps = () => (dispatch) => {
    const apiCall = api.loadBrandedApps;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const markBrandedAppInitialized = (id) => (dispatch) => {
    const apiCall = api.markBrandedAppInitialized.bind(null,id);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Initialized App' }
    });
}

export const deactivateBrandedApp = (id) => (dispatch) => {
    const apiCall = api.deactivateBrandedApp.bind(null,id);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Deactivated App' }
    });
}

export const updateBrandedApp = (data) => (dispatch) => {
    const apiCall = api.updateBrandedApp.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Updated App' }
    });
}


export const initProSuccess = (data) => ({
    type: actionTypes.INIT_PRO_SUCCESS,
    data
})

export const setAnalyticsData = (data) => ({
    type: actionTypes.SET_ANALYTICS_DATA,
    data
})

export const registerAttribution = (data) => (dispatch) => {
    api.registerOriginalSource({...data});
    dispatch(setAnalyticsData(data));
}

const unlogMealSuccess = (data) => ({
    type: actionTypes.UNLOG_MEAL_SUCCESS,
    data
})

export const unlogMeal = (userMealId) => (dispatch) => {
    const apiCall = api.unlogMeal.bind(null,userMealId);
    const success = unlogMealSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        hkSyncOnSuccess: true
    });
}

const getFoodFromBarcodeSuccess = (data) => ({
    type: actionTypes.GET_FOOD_FROM_BARCODE_SUCCESS,
    data
})

export const clearScannedFood = (data) => ({
    type: actionTypes.CLEAR_SCANNED_FOOD
})

export const getFoodFromBarcode = (data) => (dispatch) => {
    const apiCall = api.getFoodFromBarcode.bind(null,data);
    const success = getFoodFromBarcodeSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success
    });
}

const likeRecipeSuccess = (data) => ({
    type: actionTypes.LIKE_RECIPE_SUCCESS,
    data
})

export const likeRecipe = (data) => (dispatch) => {
    const apiCall = api.likeRecipe.bind(null,data);
    const success = likeRecipeSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}


export const multilikeRecipe = (data) => (dispatch) => {
    const apiCall = api.multilikeRecipe.bind(null,data);
    const success = likeRecipeSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success
    });
}

const dislikeRecipeSuccess = (data) => ({
    type: actionTypes.DISLIKE_RECIPE_SUCCESS,
    data
})

export const dislikeRecipe = (data) => (dispatch) => {
    const apiCall = api.dislikeRecipe.bind(null,data);
    const success = dislikeRecipeSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}

const deactivateRecipeSuccess = (data) => ({
    type: actionTypes.DEACTIVATE_RECIPE_SUCCESS,
    data
})

export const deactivateRecipe = (data) => (dispatch) => {
    const apiCall = api.deactivateRecipe.bind(null,data);
    const success = deactivateRecipeSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}

const logMealSuccess = (data) => ({
    type: actionTypes.LOG_MEAL_SUCCESS,
    data
})

export const logMeal = (data) => (dispatch) => {
    const apiCall = api.logMeal.bind(null,data);
    const success = logMealSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        hkSyncOnSuccess: true
    });
}

const skipMealSuccess = (data) => ({
    type: actionTypes.SKIP_MEAL_SUCCESS,
    data
})

export const skipMeal = (data) => (dispatch) => {
    const apiCall = api.skipMeal.bind(null,data);
    const success = skipMealSuccess.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        popupOnFail: true
    });
}

const logWeightSuccess = (data) => ({
    type: actionTypes.LOG_WEIGHT_SUCCESS,
    data
})

export const logDailyWeight = (data) => (dispatch) => {
    const apiCall = api.logDailyWeight.bind(null,data);
    const success = logWeightSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        hkSyncOnSuccess: true
    });
}

export const logClientWeight = (data) => (dispatch) => {
    const apiCall = api.logClientWeight.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Weight logged' }
    });
}

const cleanupRoutineSuccess = (data) => ({
    type: actionTypes.CLEANUP_ROUTINE_SUCCESS,
    data
})

const workoutsNeedingSync = (getState) => {
    const cachedWorkouts = cachedWorkoutRecordsSelector(getState());
    const unsyncedWorkouts = _.filter(cachedWorkouts,workout => workout.needsSyncing());
    return _.sortBy(unsyncedWorkouts,workout => (new Date(workout.date)));
}

export const cleanupRoutine = (date,loadWarmup,onlyLog=false) => (dispatch,getState) => {
    let sortedWorkouts = workoutsNeedingSync(getState);

    if(sortedWorkouts.length === 0) {
        if(onlyLog) {
            return Promise.resolve({ status: 'SUCCESS' });
        } else {
            const apiCall = api.cleanupRoutine.bind(null,date,loadWarmup);
            const success = cleanupRoutineSuccess;
            return userSpaceApiCall({ 
                apiCall, 
                dispatch, 
                cacheType: 'none',
                success
            });
        }
    } else {
        let promise = dispatch(logWorkout(sortedWorkouts.shift()));
        return promise.then(() => dispatch(cleanupRoutine(date)));
    }

}

export const logAllWorkouts = () => (dispatch,getState) => {
    let sortedWorkouts = workoutsNeedingSync(getState);
    sortedWorkouts.forEach(workout => dispatch(logWorkout(workout)).catch((e) => {
        if(!e.status) {
            throw(e);
        }
    }))
}

const loadWorkoutSuccess = (data) => ({
    type: actionTypes.LOAD_WORKOUT_SUCCESS,
    data
})

export const loadWorkout = (id,loadWarmup) => (dispatch,getState) => {
    const apiCall = api.loadWorkout.bind(null,id,loadWarmup);
    const success = loadWorkoutSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        state: getState(),
        cacheType: 'cache-if-exists',
        cacheCheck: workoutIsCached(id),
        success
    });
}

export const startWorkout = (workout) => {

    workout.syncStatus = 'unsynced';

    return {
        type: actionTypes.START_WORKOUT,
        date: workout.date,
        data: workout.renormalize()
    }
}

const logWorkoutRequest = (workout) => ({
    type: actionTypes.LOG_WORKOUT_REQUEST,
    id: workout.id,
    date: workout.date
})

const logWorkoutSuccess = (workout,data) => ({
    type: actionTypes.LOG_WORKOUT_SUCCESS,
    id: workout.id,
    date: workout.date,
    data: { ...data, logged: workout.logged }
})

const logWorkoutFail = (workout) => ({
    type: actionTypes.LOG_WORKOUT_FAIL,
    id: workout.id,
    date: workout.date
})

export const clearWorkoutRequestingFlags = () => ({
    type: actionTypes.CLEAR_WORKOUT_REQUESTING_FLAGS
})

export const logWorkout = (workout) => (dispatch) => {
    workout.logged = workout.isFullyLogged();
    dispatch(logWorkoutRequest(workout));
    const apiCall = api.logWorkout.bind(null,{ id: workout.id, workout: _.parseObjForForm(workout.extractForLog()) });
    const success = logWorkoutSuccess.bind(null,workout);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success,
        onFail: logWorkoutFail.bind(null,workout),
        hkSyncOnSuccess: true
    });
}

const updateExerciseSets = (workout,exerciseSets) => ({
    type: actionTypes.UPDATE_EXERCISE_SETS,
    date: workout.date,
    data: { exerciseSets }
})

const flagWorkoutUnsynced = (workout) => ({
    type: actionTypes.FLAG_WORKOUT_UNSYNCED,
    id: workout.id,
    date: workout.date
})

export const logExerciseSets = (workout,sets,updateWeights=true) => (dispatch) => {
    if(!workout.cached) {
        dispatch(startWorkout(workout));
        if(workout && _.isBlank(workout.startedAt)) {
            const nowStr = moment.utc().subtract(5,'minutes').toISOString();
            dispatch(updateWorkout(workout, { startedAt: nowStr }));
        }
    } else {
        dispatch(flagWorkoutUnsynced(workout));
    }

    if(updateWeights) {
        const synced = workout.syncWeights(sets);
        sets = [ ...sets, ...synced ];
    }

    const finalSets = _.mapValues(_.keyBy(sets,'id'),set => set.persistAttrs());

    dispatch(updateExerciseSets(workout,finalSets));
}

export const clearCachedWorkouts = (workouts) => ({
    type: actionTypes.CLEAR_CACHED_WORKOUTS,
    dates: workouts.map(workout => workout.date)
})

export const muteTimers = (mute) => (dispatch) => {
    api.muteTimers(mute).catch((e) => { console.log('failed to mute timers')});
    dispatch({ type: actionTypes.MUTE_WORKOUT_TIMERS, data: { muteTimers: mute } });
}

const loadStrengthTestSuccess = (date) => (data) => ({
    type: actionTypes.LOAD_STRENGTH_TEST_SUCCESS,
    date,
    data
})

export const loadStrengthTest = ({ clientId, ...params }) => (dispatch) => {
    const apiCall = api.loadStrengthTest.bind(null,params)
    const success = loadStrengthTestSuccess(params.date);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        overrideUserId: clientId,
        success
    });
}

const workoutActionSuccess = (date) => (data) => ({
    type: actionTypes.WORKOUT_ACTION_SUCCESS,
    date,
    data
})

const ensureWorkoutStarted = (dispatch,getState,date) => {
    const workout = getComprehensiveWodSelector(date)(getState());

    if(workout && !workout.cached && workout.isFullyLoaded()) {
        dispatch(startWorkout(workout));
    }
}

export const standaloneStartWorkout = (date) => (dispatch,getState) => {
    ensureWorkoutStarted(dispatch,getState,date);
}

export const submitStrengthTest = ({ clientId, ...params }) => (dispatch,getState) => {
    ensureWorkoutStarted(dispatch,getState,params.date);
    const apiCall = api.submitStrengthTest.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: workoutActionSuccess(params.date)
    });
}

export const submitFormStrengthTest = ({ clientId, ...params }) => (dispatch) => {
    const apiCall = api.submitStrengthTest.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        overrideUserId: clientId
    });
}

const exerciseSettingsUpdateSuccess = (data) => ({
    type: actionTypes.EXERCISE_SETTING_UPDATE_SUCCESS,
    data
})

const exerciseSettingUpdateResponse = (date,data) => (dispatch) => {
    dispatch(exerciseSettingsUpdateSuccess(data));
    dispatch(workoutActionSuccess(date)(data));
}

export const updateExerciseSettings = (params) => (dispatch,getState) => {
    ensureWorkoutStarted(dispatch,getState,params.date);
    const apiCall = api.updateExerciseSettings.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: exerciseSettingUpdateResponse.bind(null,params.date)
    });
}

const loadExProgressionSuccess = (date) => (data) => ({
    type: date ? actionTypes.LOAD_EX_PROGRESSION : actionTypes.LOAD_PROGRESSION_STANDALONE,
    date,
    data
})

export const loadExProgression = (id,date) => (dispatch,getState) => {
    if(date) {
        ensureWorkoutStarted(dispatch,getState,date);
    }

    const apiCall = api.loadExerciseProgression.bind(null,id);
    const success = loadExProgressionSuccess(date);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        state: getState(),
        cacheType: 'cache-if-exists',
        cacheCheck: date ? workoutProgressionIsCached(date,id) : progressionIsCached(id),
        success
    });
}

export const submitProgTest = (params) => (dispatch,getState) => {
    ensureWorkoutStarted(dispatch,getState,params.date);
    const apiCall = api.submitProgressionTest.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        success: workoutActionSuccess(params.date)
    });
}

export const handleProgPickFail = (date, id, exerciseId) => ({
    type: actionTypes.HANDLE_PROG_PICK_FAIL,
    data: { id, exerciseId },
    date
})

const restartWorkoutSuccess = (date) => (data) => (dispatch,getState) => {
    let workout = getWodSelector(date)(getState());
    let actionData = { type: actionTypes.RESTART_WORKOUT_SUCCESS, date, clear: {}, data };
    if(workout) {
        actionData.clear = { workoutIds: [workout.id], ...workout.extract(['exerciseSpecificationIds','exerciseSetIds'])}
    }
    dispatch(actionData);
}

export const restartWorkout = date => dispatch => {
    const apiCall = api.restartWorkout.bind(null,date);
    const success = restartWorkoutSuccess(date);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        success
    });
}

export const updateWorkout = (workout,updates) => ({
    type: actionTypes.UPDATE_WORKOUT,
    data: updates,
    date: workout.date,
    id: workout.id
})

export const updateExerciseSpecification = (spec,updates) => ({
    type: actionTypes.UPDATE_EXERCISE_SPECIFICATION,
    data: updates,
    date: spec.workout.date,
    id: spec.id
})

export const resetExerciseSearch = ({ formValues, context }) => ({
    type: actionTypes.RESET_EXERCISE_SEARCH,
    data: { formValues, context }
})

const executeExerciseSearchSuccess = (data) => ({
    type: actionTypes.EXECUTE_EXERCISE_SEARCH,
    data
})

export const executeExerciseSearch = (data,page) => (dispatch) => {
    const apiCall = api.executeExerciseSearch.bind(null,data,page);
    return userSpaceApiCall({ 
        apiCall, 
        success: executeExerciseSearchSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const cacheExerciseSearch = (formValues) => ({
    type: actionTypes.CACHE_EXERCISE_SEARCH,
    data: formValues
})

const swapExerciseSpecSuccess = (date) => (data) => ({
    type: actionTypes.SWAP_EXERCISE_SPEC,
    date,
    data
})

export const swapExerciseSpec = (spec,substitute) => (dispatch,getState) => {
    ensureWorkoutStarted(dispatch,getState,spec.workout.date);
    const apiCall = api.swapExerciseSpec.bind(null,{ id: spec.id, loggedIds: spec.workout.loggedSpecIds(), ...substitute.swapParams() });
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: swapExerciseSpecSuccess(spec.workout.date)
    });
}

export const getLastExerciseMax = (exercise, reps) => (dispatch) => {
    const apiCall = api.lastExerciseMax.bind(null,{ id: exercise.id, reps });
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const workoutsLoggedThisWeek = (date) => (dispatch) => {
    const apiCall = api.workoutsLoggedThisWeek.bind(null,{ date });
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const submitStrengthTestSet = (date,{ exerciseId, set, setIndex, specId }) => ({
    type: actionTypes.SUBMIT_STRENGTH_TEST_SET,
    date,
    data: { exerciseId, set, setIndex, specId }  
})

export const submitProgressionTestSet = (date,{ set, setIndex, progressionId, reps, specId }) => ({
    type: actionTypes.SUBMIT_PROGRESSION_TEST_SET,
    date,
    data: { set, setIndex, progressionId, reps, specId }  
})

export const setPaywallRedirect = (path) => ({
    type: actionTypes.SET_PAYWALL_REDIRECT,
    data: { path }
})

export const respondToRatingPrompt = (response) => (dispatch) => {
    let nextRatingRequest = moment().add(3,'days').format(dateFormat);
    if(response === 'never') {
        nextRatingRequest = moment().add(99999,'days').format(dateFormat);
    }

    let onlyIosPrompt = false;
    if(response === 'later_ios') {
        onlyIosPrompt = true;
    }

    const data = { nextRatingRequest, onlyIosPrompt };

    api.ratingResponse(data);
    dispatch({
        type: actionTypes.SET_NEXT_RATING_REQUEST,
        data
    });
}

export const setViewportHeight = (viewportHeight) => ({
    type: actionTypes.SET_VIEWPORT_HEIGHT,
    data: { viewportHeight }
})

const deloadSuccess = (data) => ({
    type: actionTypes.DELOAD_SUCCESS,
    data
})

export const deload = (type) => (dispatch) => {
    const apiCall = api.deload.bind(null,type);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: deloadSuccess
    });
}

export const undeload = () => (dispatch) => {
    const apiCall = api.undeload;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        success: deloadSuccess
    });
}

export const historyPush = (entry) => ({
    type: actionTypes.HISTORY_PUSH,
    data: { entry }
})

export const historyReplace = (entry) => ({
    type: actionTypes.HISTORY_REPLACE,
    data: { entry }
})

export const historyPop = (path) => ({
    type: actionTypes.HISTORY_POP,
    data: { path }
})

export const historyClear = (entry) => ({
    type: actionTypes.HISTORY_CLEAR,
    data: { entry }
})

export const historyClearForward = () => ({
    type: actionTypes.HISTORY_CLEAR_FORWARD
})

const loadRoutineSuccess = exerciseGroupsLoaded => data => ({
    type: actionTypes.LOAD_ROUTINE_SUCCESS,
    data: { ...data, exerciseGroupsLoaded }
})

export const loadWorkoutRoutine = (id,loadWarmups) => (dispatch,getState) => {
    const needToLoadGroups = needToLoadExerciseGroupsSel(getState());
    const apiCall = api.loadWorkoutRoutine.bind(null,id,loadWarmups,needToLoadGroups);
    const success = loadRoutineSuccess(needToLoadGroups);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        state: getState(),
        cacheType: 'cache-if-exists',
        cacheCheck: workoutRoutineIsCached(id),
        success
    });
}

const updateRoutineSuccess = data => ({
    type: actionTypes.UPDATE_ROUTINE_SUCCESS,
    data
})

export const updateWorkoutRoutine = (data) => (dispatch) => {
    const apiCall = api.updateWorkoutRoutine.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateRoutineSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const destroyWorkoutRoutine = (id) => (dispatch) => {
    const apiCall = api.destroyWorkoutRoutine.bind(null,id);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateRoutineSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const restoreRoutineDefaultsSuccess = data => ({
    type: actionTypes.RESTORE_ROUTINE_DEFAULTS_SUCCESS,
    data
})

export const restoreRoutineDefaults = () => (dispatch) => {
    const apiCall = api.restoreRoutineDefaults;
    return userSpaceApiCall({ 
        apiCall, 
        success: restoreRoutineDefaultsSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const restartRoutineSuccess = data => ({
    type: actionTypes.RESTART_ROUTINE_SUCCESS,
    data
})

export const restartRoutine = (data) => (dispatch) => {
    const apiCall = api.restartRoutine.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: restartRoutineSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const updateRoutineCycleSuccess = data => ({
    type: actionTypes.UPDATE_ROUTINE_CYCLE_SUCCESS,
    data
})

export const updateRoutineCycle = (data) => (dispatch) => {
    const apiCall = api.updateRoutineCycle.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateRoutineCycleSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const createRoutineCycle = (data) => (dispatch) => {
    const apiCall = api.createRoutineCycle.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateRoutineCycleSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const destroyRoutineCycleSuccess = cycle => data => ({
    type: actionTypes.DESTROY_ROUTINE_CYCLE_SUCCESS,
    data: { ...data, ...cycle.renormalize(null,{
        workoutRoutineDays: {
            workoutTemplate: {
                workoutTemplateChildren: {
                    child: (child) => {
                        return [child.isExerciseTemplate(),{
                            setTemplates: {}
                        }]
                    }
                }
            }
        }
    }) }
})

export const destroyRoutineCycle = (cycle) => (dispatch) => {
    const apiCall = api.destroyRoutineCycle.bind(null,{ id: cycle.id, workoutRoutineId: cycle.workoutRoutineId });
    return userSpaceApiCall({ 
        apiCall, 
        success: destroyRoutineCycleSuccess(cycle),
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const createWrtdSuccess = data => ({
    type: actionTypes.CREATE_WRTD_SUCCESS,
    data
})

export const createWrtd = (data) => (dispatch) => {
    const apiCall = api.createWrtd.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: createWrtdSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const destroyWrtdSuccess = (id,workoutTemplateId,destroyParams) => data => ({
    type: actionTypes.DESTROY_WRTD_SUCCESS,
    data: { id, workoutTemplateId, ...destroyParams, ...data }
})

export const destroyWrtd = (workoutRoutineDay) => (dispatch) => {
    const apiCall = api.destroyWrtd.bind(null,workoutRoutineDay);
    return userSpaceApiCall({ 
        apiCall, 
        success: destroyWrtdSuccess(workoutRoutineDay.id,workoutRoutineDay.workoutTemplateId,workoutRoutineDay.workoutTemplate.destroyParams()),
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateCycleDayOrderSuccess = data => ({
    type: actionTypes.UPDATE_CYCLE_DAY_ORDER_SUCCESS,
    data
})

export const updateCycleDayOrder = (cycle) => (dispatch) => {

    const apiCall = api.updateCycleDayOrder.bind(null,cycle.dayOrderParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: updateCycleDayOrderSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateWorkoutTemplateSuccess = data => ({
    type: actionTypes.UPDATE_WORKOUT_TEMPLATE_SUCCESS,
    data
});

export const updateWorkoutTemplate = (data) => (dispatch) => {
    const apiCall = api.updateWorkoutTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateWorkoutTemplateSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: true
    });
}

export const showSaveFlash = (msg,timeout) => (dispatch) => {
    dispatch({ type: actionTypes.SHOW_SAVE_FLASH, msg });
    setTimeout(() => dispatch({ type: actionTypes.HIDE_SAVE_FLASH }),timeout || 300);
}

const updateWtChildOrderSuccess = data => ({
    type: actionTypes.UPDATE_WT_CHILD_ORDER,
    data
})

export const updateWtChildOrder = (workoutTemplate) => (dispatch) => {

    const apiCall = api.updateWtChildOrder.bind(null,workoutTemplate.childOrderParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: updateWtChildOrderSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateSetTemplateSuccess = data => ({
    type: actionTypes.UPDATE_SET_TEMPLATE,
    data
});

export const updateSetTemplate = (setTemplate,user) => (dispatch) => {
    const data = setTemplate.submitValues(user);
    const apiCall = api.updateSetTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateSetTemplateSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: true
    });
}

export const updateStRefWeight = (data) => (dispatch) => {
    const apiCall = api.updateSetTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateSetTemplateSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const addSetTemplate = (data) => (dispatch) => {
    const apiCall = api.addSetTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateSetTemplateSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const destroySetTemplateSuccess = data => ({
    type: actionTypes.DESTROY_SET_TEMPLATE,
    data
});

export const destroySetTemplate = (setTemplate) => (dispatch) => {
    const data = setTemplate.actionParams();
    const apiCall = api.destroySetTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: destroySetTemplateSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateExerciseTemplateSuccess = data => ({
    type: actionTypes.UPDATE_EXERCISE_TEMPLATE,
    data
});

export const updateExerciseTemplate = (data) => (dispatch) => {
    const apiCall = api.updateExerciseTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateExerciseTemplateSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const swapExerciseTemplateSuccess = data => ({
    type: actionTypes.SWAP_EXERCISE_TEMPLATE,
    data
});

export const swapExerciseTemplate = (data) => (dispatch) => {
    const apiCall = api.swapExerciseTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: swapExerciseTemplateSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const loadProgressionRequirementsSuccess = data => ({
    type: actionTypes.LOAD_PROGRESSION_REQUIREMENTS,
    data
});

export const loadProgressionRequirements = (data) => (dispatch,getState) => {
    const apiCall = api.loadProgressionRequirements.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: loadProgressionRequirementsSuccess,
        dispatch, 
        state: getState(),
        cacheType: 'cache-if-exists', 
        cacheCheck: progressionRequirementsCached(data.exerciseTemplateId)
    });
}

export const loadAutoregTemplates = (data) => (dispatch) => {
    const apiCall = api.loadAutoregTemplates.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

const addWtChildGroupSuccess = data => ({
    type: actionTypes.ADD_WT_CHILD_GROUP,
    data
})

export const addWtChildGroup = (data) => (dispatch) => {

    const apiCall = api.addWtChildGroup.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: addWtChildGroupSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: 'Added'
    });
}

const addExerciseTemplateSuccess = data => ({
    type: actionTypes.ADD_EXERCISE_TEMPLATE,
    data
})

export const addExerciseTemplate = (data) => (dispatch) => {

    const apiCall = api.addExerciseTemplate.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: addExerciseTemplateSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: 'Added'
    });
}

export const insertNewExerciseToSearchTop = ({ exerciseId }) => ({
    type: actionTypes.INSERT_NEW_EXERCISE_TO_TOP,
    data: { exerciseId }
})

const createCustomExerciseSuccess = data => ({
    type: actionTypes.CREATE_EXERCISE,
    data
})

export const createCustomExercise = (data) => (dispatch) => {

    const apiCall = api.createCustomExercise.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: createCustomExerciseSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: 'Created'
    });
}

const destroyWtcSuccess = data => ({
    type: actionTypes.DESTROY_WTC,
    data
})

export const destroyWtc = (wtc) => (dispatch) => {

    const apiCall = api.destroyWtc.bind(null,wtc.destroyParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: destroyWtcSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const loadStReferences = (data) => (dispatch) => {
    const apiCall = api.loadStReferences.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

const destroyWtSuccess = (workoutTemplateId,destroyParams) => data => ({
    type: actionTypes.DESTROY_WORKOUT_TEMPLATE,
    data: { deleteTemplate: true, workoutTemplateId, ...destroyParams, ...data }
})

export const destroyWorkoutTemplate = (workoutTemplate) => (dispatch) => {
    const apiCall = api.destroyWorkoutTemplate.bind(null,workoutTemplate.actionParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: destroyWtSuccess(workoutTemplate.id,workoutTemplate.destroyParams()),
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const createExerciseGroupSuccess = data => ({
    type: actionTypes.CREATE_EXERCISE_GROUP,
    data
});

export const createExerciseGroup = (data) => (dispatch) => {
    const apiCall = api.createExerciseGroup.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: createExerciseGroupSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: 'Added'
    });
}

const updateExerciseSuccess = data => ({
    type: actionTypes.UPDATE_EXERCISE,
    data
})

export const updateExercise = (params) => (dispatch) => {
    const apiFn = api.updateExercise;
    const apiCall = apiFn.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateExerciseSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const loadExercise = (params) => (dispatch) => {
    const apiFn = api.loadExercise;
    const apiCall = apiFn.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateExerciseSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

// eslint-disable-next-line
const loadTrainerExercise = loadExercise;


export const destroyCustomExercise = (exercise) => (dispatch) => {
    const apiCall = api.destroyCustomExercise.bind(null,exercise.id);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateExerciseSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const loadMuxRecordSuccess = data => ({
    type: actionTypes.LOAD_MUX_RECORD,
    data
})

const loadMuxRecordCore = (data) => dispatch => {
    const apiCall = api.loadMuxRecord.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: loadMuxRecordSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const loadMuxRecord = record => {
    if(['Exercise','Assessment'].includes(record.constructor.NAME)) {
        // eslint-disable-next-line no-eval
        const fn =  eval(`loadTrainer${record.constructor.NAME}`);

        return fn({ id: record.id });
    }

    return loadMuxRecordCore({ id: record.id, type: record.constructor.NAME });
}

const restartMuxUploadSuccess = params => data => dispatch => {

    dispatch(doVideoUpload(params));

    dispatch({
        type: actionTypes.RESTART_MUX_UPLOAD,
        data
    });
}

export const restartMuxUpload = (params) => (dispatch) => {
    const apiCall = api.restartMuxUpload.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: restartMuxUploadSuccess(params),
        dispatch, 
        cacheType: 'none'
    });
}

const updateSettingsSuccess = data => dispatch => {
    if(!data.error) {
        dispatch({
            type: actionTypes.UPDATE_SETTINGS,
            data
        });
    }
}

export const updateSettings = (data,flash=true) => dispatch => {
    const apiCall = api.updateSettings.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateSettingsSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: flash
    });
}

const parseExtraKeywords = ({ extraKeywords, excludedKeywords, ...data }) => {
    
    if(extraKeywords !== undefined) {
        data = { ...data, extraKeywords: extraKeywords.join(',') }
    }

    if(excludedKeywords !== undefined) {
        data = { ...data, excludedKeywords: excludedKeywords.join(',') }
    }
    return data;
}

export const updateMealPlan = (data,popupOnFail=true,flashOnSuccess=true) => dispatch => {
    data = parseExtraKeywords(data);
    const apiCall = api.updateMealPlanSettings.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateMealPlanSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail,
        flashOnSuccess
    });
}

export const setCalorieSchedule = (data) => dispatch => {
    data = parseExtraKeywords(data);
    const apiCall = api.setCalorieSchedule.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateMealPlanSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}


export const finishMealPlanSetup = (data) => updateMealPlan({ ...parseMealPlanSetupParams(data), isFinish: true },false,false)

export const updateProgressChartsLocal = queryParams => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_PROGRESS_CHARTS,
        data: queryParams
    });
    return Promise.resolve({ status: 'SUCCESS', data: true });
}

export const updateProgressCharts = queryParams => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateProgressCharts.bind(null,queryParams), 
        dispatch, 
        cacheType: 'none'
    });
}

export const updateMacroAnalytics = lookbackDays => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateMacroAnalytics.bind(null,lookbackDays), 
        dispatch, 
        cacheType: 'none'
    });
}

const loadBodyMeasurementsSuccess = data => ({
    type: actionTypes.LOAD_BODY_MEASUREMENTS,
    data
})

export const loadBodyMeasurements = (userId) => (dispatch) => {
    const apiCall = api.loadBodyMeasurements.bind(null,{ userId });
    return userSpaceApiCall({ 
        apiCall, 
        success: loadBodyMeasurementsSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const createBodyMeasurementsSuccess = data => ({
    type: actionTypes.CREATE_BODY_MEASUREMENTS,
    data
})

export const createBodyMeasurements = (data,flash) => (dispatch) => {
    const apiCall = api.createBodyMeasurements.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: createBodyMeasurementsSuccess,
        dispatch, 
        cacheType: 'none',
        flashOnSuccess: flash ? 'Scroll for more' : null,
        flashTimeout: 1000
    });
}

export const clearInitialSignup = () => ({
    type: actionTypes.CLEAR_INITIAL_SIGNUP
})

export const clearTrainerSignup = () => ({
    type: actionTypes.CLEAR_TRAINER_SIGNUP
})

export const submitContactForm = (data) => (dispatch) => {
    const apiCall = api.submitContactForm.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

const loadSubscriptionSuccess = data => ({
    type: actionTypes.LOAD_SUBSCRIPTION,
    data
})

export const loadSubscription = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadSubscription, 
        dispatch, 
        cacheType: 'none',
        success: loadSubscriptionSuccess
    });
}

const notificationsReadSuccess = data => ({
    type: actionTypes.NOTIFICATIONS_READ,
    data
})

export const markNotificationsRead = (ids) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.markNotificationsRead.bind(null,ids), 
        dispatch, 
        success: notificationsReadSuccess,
        cacheType: 'none'
    });
}

export const clearCache = () => ({
    type: actionTypes.CLEAR_CACHE
})

const loadErrorLogsSuccess = (data) => ({
    type: actionTypes.ERROR_LOGS_LOAD,
    data
});

export const loadErrorLogs = (firstId,page) => (dispatch) => {
    const apiCall = api.loadErrorLogs.bind(null,{ firstId,page });
    const success = loadErrorLogsSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
};

const destroyErrorLogSuccess = data => ({
    type: actionTypes.DESTROY_ERROR_LOG,
    data
});

export const destroyErrorLog = log => dispatch => {
    const apiCall = api.destroyErrorLog.bind(null,log.id);
    const success = destroyErrorLogSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch
    });
}

const clearErrorLogsSuccess = data => ({
    type: actionTypes.CLEAR_ERROR_LOGS,
    data
});

export const refreshErrorLogs = () => ({
    type: actionTypes.REFRESH_ERROR_LOGS
})

export const clearErrorLogs = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.clearErrorLogs, 
        success: clearErrorLogsSuccess, 
        dispatch
    });
}

export const loadErrorLog = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadErrorLog.bind(null,id), 
        dispatch
    });
}

export const setMpViewType = type => ({
    type: actionTypes.SET_MP_VIEW_TYPE,
    viewType: type
})

const markWeekCleanSuccess = data => ({
    type: actionTypes.MARK_WEEK_CLEAN,
    data
});

export const markWeekClean = startDate => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.markWeekClean.bind(null,startDate), 
        success: markWeekCleanSuccess, 
        dispatch,
        popupOnFail: true
    });
}

const ignoreWarningsSuccess = (week,currentIgnores) => ({
    type: actionTypes.IGNORE_WARNINGS,
    data: {
        user: {
            ignoreWarningsFor: _.union([week],currentIgnores)
        }
    }
});

export const ignoreWarnings = (week,currentIgnores) => dispatch => {
    dispatch(ignoreWarningsSuccess(week,currentIgnores));
    userSpaceApiCall({ 
        apiCall: api.ignoreWarnings.bind(null,week), 
        dispatch
    });
}

export const setPinnedMeals = (pinnedMeals,startDate) => ({
    type: actionTypes.SET_PINNED_MEALS,
    data: { pinnedMeals, startDate }
})

export const cacheOffPlanMeals = (offPlanMealMap,startDate) => ({
    type: actionTypes.CACHE_OFF_PLAN_MEALS,
    data: { offPlanMealMap, startDate }
})

export const setOffPlanMeals = ({ offPlanMeals, ...data }) => dispatch => {
    if(!offPlanMeals || _.isEmpty(offPlanMeals)) {
        return Promise.resolve({status: 'SUCCESS', data: true});
    }
    return userSpaceApiCall({ 
        apiCall: api.setOffPlanMeals.bind(null,{ offPlanMeals, ...data }), 
        success: fullMealPlanUpdate, 
        dispatch,
        popupOnFail: true
    });
}

export const setAddPeopleSelection = (addPeopleSelection,startDate) => ({
    type: actionTypes.ADD_PEOPLE_SELECTION,
    data: { addPeopleSelection, startDate }
})

const updateMiniProfileSuccess = data => ({
    type: actionTypes.UPDATE_MINI_PROFILE,
    data
});

export const updateMiniProfile = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateMiniProfile.bind(null,data), 
        success: updateMiniProfileSuccess, 
        dispatch,
        popupOnFail: true
    });
}

const destroyMiniProfileSuccess = data => ({
    type: actionTypes.DESTROY_MINI_PROFILE,
    data
});

export const destroyMiniProfile = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.destroyMiniProfile.bind(null,id), 
        success: destroyMiniProfileSuccess, 
        dispatch,
        popupOnFail: true
    });
}

const mealUpdateSideEffects = ({ type, unlockIds, destroyedMeals, recipeMeals, userMeals, ...data }) => {
    return {
        type,
        data: {
            unlockIds,
            destroyedMeals,
            recipeMeals,
            userMeals,
            ...data
        }
    }
}

const handleShareMealSideEffects = (weeklyMeals) => (data) => {

    const unlockIds = _.flatMap(weeklyMeals,wm => wm.unlockIds());
    const destroyedSharedMeals = weeklyMeals.map(wm => wm.destroyedSharedMeals()).reduce((cur,inp) => {
        cur.userMealIds = [ ...cur.userMealIds, ...inp.userMealIds ];
        cur.recipeMealIds = [ ...cur.recipeMealIds, ...inp.recipeMealIds ];
        return cur;
    }, { recipeMealIds: [], userMealIds: [] });

    return mealUpdateSideEffects({ 
        type: actionTypes.HANDLE_SHARE_MEAL_SIDE_EFFECTS, 
        unlockIds, 
        destroyedMeals: destroyedSharedMeals, 
        recipeMeals: data.recipeMeals, 
        userMeals: data.userMeals
    });
}

export const confirmSharedMeals = ({ miniProfileIds, removed, added }) => dispatch => {
    let removedMap = removed.reduce((curMap,weeklyMeal) => ({ ...curMap, ...weeklyMeal.shareMapAfterRemoval(miniProfileIds) }),{})
    let addedMap = added.reduce((curMap,weeklyMeal) => ({ ...curMap, ...weeklyMeal.shareMapAfterAdding(miniProfileIds) }),{})
    const fullMap = { ...removedMap, ...addedMap };
    const weeklyMeals = _.concat(added,removed)
    if(_.isEmpty(fullMap)) {
        return Promise.resolve({status: 'SUCCESS', data: true});
    } else {
        return userSpaceApiCall({ 
            apiCall: api.confirmSharedMeals.bind(null,fullMap), 
            success: handleShareMealSideEffects(weeklyMeals), 
            dispatch,
            popupOnFail: true
        });
    }
}

export const confirmSingleSharedMeal = ({ miniProfileIds, weeklyMeal }) => dispatch => {
    const fullMap = weeklyMeal.shareMapFor(miniProfileIds);
    const weeklyMeals = [weeklyMeal];
    return userSpaceApiCall({ 
        apiCall: api.confirmSharedMeals.bind(null,fullMap), 
        success: handleShareMealSideEffects(weeklyMeals), 
        dispatch
    });
}

export const setUnsavedChanges = (data) => ({
    type: actionTypes.SET_UNSAVED_CHANGES,
    data
})

export const discardUnsavedChanges = () => ({
    type: actionTypes.DISCARD_UNSAVED_CHANGES
})

export const clearUnsavedChanges = () => ({
    type: actionTypes.CLEAR_UNSAVED_CHANGES
})

export const getLockedDays = userMealId => (dispatch) => {
    const apiCall = api.getLockedDays.bind(null,userMealId);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none'
    });
}

export const pickMealDaysSuccess = ({ oldUrl, newUrl, history }) => data => dispatch => {
    let { location: { pathname } } = history;
    if(newUrl && matchPath(pathname,{ path: oldUrl })) {
        setTimeout(() => history.replace(newUrl),10);
    }
    return dispatch(fullMealPlanUpdate(data));
}


export const pickMealDays = ({ oldUrl, newUrl, history, ...data}) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.pickMealDays.bind(null,data), 
        success: pickMealDaysSuccess({ oldUrl, newUrl, history }), 
        dispatch,
        cacheType: 'none'
    });
}

export const setupMealSwap = (data) => ({
    type: actionTypes.SETUP_MEAL_SWAP,
    data
})

const loadMealSearchResults = data => ({
    type: actionTypes.LOAD_MEAL_SEARCH_RESULTS,
    data
})

export const startSwapMeals = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startSwapMeals.bind(null,data), 
        success: loadMealSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

export const startBrowseMeals = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startBrowseMeals, 
        success: loadMealSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

const loadMealSearchCategoriesSuccess = data => ({
    type: actionTypes.LOAD_MEAL_SEARCH_CATEGORIES,
    data
})

export const loadMealSearchCategories = () => (dispatch,getState) => {
    return userSpaceApiCall({ 
        apiCall: api.loadMealSearchCategories, 
        success: loadMealSearchCategoriesSuccess,
        cacheCheck: mealSearchCategoriesCached,
        state: getState(),
        cacheType: 'cache-if-exists', 
        dispatch
    });
}

export const updateMealSearch = (data,page) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateMealSearch.bind(null,data,page), 
        success: loadMealSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

const loadRecipeSearchResults = data => ({
    type: actionTypes.LOAD_RECIPE_SEARCH_RESULTS,
    data
})

export const startBrowseRecipes = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startBrowseRecipes, 
        success: loadRecipeSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

export const startReplaceTempRecipe = (meal,data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startReplaceTempRecipe.bind(null,{ ...data, meal }), 
        success: loadRecipeSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

export const setupReplaceRecipe = (data) => ({
    type: actionTypes.SETUP_REPLACE_RECIPE,
    data
})

export const startReplaceRecipe = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startReplaceRecipe.bind(null,data), 
        success: loadRecipeSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

const replaceTempRecipeSuccess = data => ({
    type: actionTypes.REPLACE_TEMP_RECIPE,
    data
})

export const replaceTempRecipe = (meal,{ recipeId, replacementId, replacementServings }) => dispatch => {
    dispatch(replaceTempRecipeSuccess({
        meal: meal.replaceDish(recipeId,replacementId,replacementServings)
    }))
    return Promise.resolve({status: 'SUCCESS', data: true })
}

export const updateRecipeSearch = (data,page) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateRecipeSearch.bind(null,data,page), 
        success: loadRecipeSearchResults, 
        dispatch,
        cacheType: 'none'
    });
}

export const replaceMeals = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.replaceMeals.bind(null,data), 
        success: fullMealPlanUpdate, 
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

const loadRecentRecipesSuccess = data => ({
    type: actionTypes.LOAD_RECENT_RECIPES,
    data
})

export const loadRecentRecipes = page => (dispatch,getState) => {
    return userSpaceApiCall({ 
        apiCall: api.loadRecentRecipes.bind(null,page), 
        success: loadRecentRecipesSuccess, 
        cacheCheck: recentRecipesCachedCreator(page),
        state: getState(),
        cacheType: 'cache-if-exists', 
        dispatch
    });
}

export const startEditTempMeal = meal => ({
    type: actionTypes.START_EDIT_TEMP_MEAL,
    data: { meal }
})

const getRandomMealSuccess = data => ({
    type: actionTypes.GET_RANDOM_MEAL,
    data
})

export const getRandomMeal = (data,popup=false) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.getRandomMeal.bind(null,data), 
        success: getRandomMealSuccess, 
        dispatch,
        popupOnFail: popup,
        cacheType: 'none'
    });
}

const handleMealUpdate = (weeklyMeal) => (data) => {
    const unlockIds = weeklyMeal.unlockIds()
    const destroyedMeals = weeklyMeal.allDestroyedMeals();

    return mealUpdateSideEffects({ 
        type: actionTypes.HANDLE_MEAL_UPDATE, 
        unlockIds, 
        destroyedMeals: destroyedMeals, 
        ...data
    });
}

export const replaceRecipe = (weeklyMeal,data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.replaceRecipe.bind(null,{ userMealId: weeklyMeal.id(), ...data }), 
        success: handleMealUpdate(weeklyMeal), 
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const randomizeSides = (weeklyMeal,data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.randomizeSides.bind(null,{ userMealId: weeklyMeal.id(), ...data }), 
        success: handleMealUpdate(weeklyMeal), 
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

const setRecipeMealServingsSuccess = data => ({
    type: actionTypes.SET_RECIPE_MEAL_SERVINGS,
    data
})

export const setRecipeMealServings = (weeklyMeal,data) => dispatch => {
    userSpaceApiCall({ 
        apiCall: api.setRecipeMealServings.bind(null,{ userMealId: weeklyMeal.id(), ...data }), 
        success: setRecipeMealServingsSuccess, 
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
    return Promise.resolve({status: 'SUCCESS'});
}

export const setTempRecipeMealServings = (meal,{ recipeId,servings }) => dispatch => {
    dispatch(startEditTempMeal(meal.setServingsFor(recipeId,servings)));
    return Promise.resolve({status: 'SUCCESS'});
}

const loadWimageCatsSuccess = data => ({
    type: actionTypes.LOAD_WIMAGE_CATS,
    data
})

export const loadWimageCats = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadWimageCats.bind(null,data), 
        success: loadWimageCatsSuccess, 
        dispatch,
        cacheType: 'none'
    });
}

const destroyWimageCatSuccess = data => ({
    type: actionTypes.DESTROY_WIMAGE_CAT,
    data
})

export const destroyWimageCat = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.destroyWimageCat.bind(null,id), 
        success: destroyWimageCatSuccess, 
        dispatch,
        cacheType: 'none'
    });
}

const updateWimageCatSuccess = data => ({
    type: actionTypes.UPDATE_WIMAGE_CAT,
    data
})

export const updateWimageCat = data => dispatch => {
    const method = data.id ? api.updateWimageCat : api.createWimageCat;
    return userSpaceApiCall({ 
        apiCall: method.bind(null,data),
        success: updateWimageCatSuccess,
        popupOnFail: true,
        dispatch,
        cacheType: 'none'
    });
}

const loadGlistSuccess = preferences => data => ({
    type: actionTypes.LOAD_GLIST_SUCCESS,
    data: { ...data, preferences }
})

export const loadGroceryList = (preferences,data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadGroceryList.bind(null,data),
        success: loadGlistSuccess(preferences),
        dispatch,
        cacheType: 'none'
    });
}

const localCreatePantryItem = items => ({
    type: actionTypes.CREATE_PANTRY_ITEM,
    items
})

export const createPantryItem = groceryItems => dispatch => {

    dispatch(localCreatePantryItem(groceryItems));

    userSpaceApiCall({ 
        apiCall: api.createPantryItem.bind(null,GroceryItem.submitValues(groceryItems)),
        dispatch,
        cacheType: 'none'
    });
}

const localDestroyPantryItem = items => ({
    type: actionTypes.DESTROY_PANTRY_ITEM,
    items
})

export const destroyPantryItem = groceryItems => dispatch => {

    dispatch(localDestroyPantryItem(groceryItems));

    userSpaceApiCall({ 
        apiCall: api.destroyPantryItem.bind(null,GroceryItem.submitValues(groceryItems)),
        dispatch,
        cacheType: 'none'
    });
}

const handleSingleDayUpdate = (userMeals,destroyUserMeals) => (data) => {
    const unlockIds = []
    const destroyedMeals = { 
        userMealIds: destroyUserMeals ? userMeals.map(um => um.id) : [], 
        recipeMealIds: _.flatMap(userMeals, um => um.recipeMeals.map(rm => rm.id)) };

    return mealUpdateSideEffects({ 
        type: actionTypes.HANDLE_MEAL_UPDATE, 
        unlockIds, 
        destroyedMeals: destroyedMeals, 
        ...data
    });
}

export const regenUserMeal = (userMeal,excludedIds) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.regenUserMeal.bind(null,userMeal.id,excludedIds),
        success: handleSingleDayUpdate([userMeal],false),
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const regenSingleDay = (userMeals) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.regenSingleDay,
        success: handleSingleDayUpdate(userMeals,true),
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const startEditingRecipe = (user,recipe,isCopy=false,showMasterEditWarning=false) =>  ({
    type: actionTypes.START_EDITING_RECIPE,
    data: recipe ? recipe.editorFields(user,isCopy,showMasterEditWarning) : Recipe.defaultDraft(user)
})

export const cacheRecipeChanges = (data) =>  ({
    type: actionTypes.CACHE_RECIPE_CHANGES,
    data: { hasChanges: true, ...data }
})

export const addIngredient = (noSeqHandler,{ seq, servings, id, foodWeightId }) => dispatch => {

    if(!_.isBlank(seq)) {
        dispatch({
            type: actionTypes.ADD_INGREDIENT,
            data: { seq, amount: servings, foodWeightId, foodId: id, tmpId: Math.random() }
        });
        dispatch(showSaveFlash('Added'));
        return Promise.resolve({ status: 'SUCCESS' });
    } else {
        noSeqHandler(id,servings);
        return Promise.resolve({ status: 'DEFAULT' });
    }

}


const foodLoadSuccess = (data) => ({
    type: actionTypes.FOOD_LOAD,
    data
})

export const createFoodWeight = data => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.createFoodWeight.bind(null,data),
        success: foodLoadSuccess,
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const loadRecipeDraft = (ingredients) => (dispatch,getState) => {
    const foodWeightIds = ingredients.map(ingredient => ingredient.foodWeightId)

    return userSpaceApiCall({ 
        apiCall: api.loadIngredientFoods.bind(null,foodWeightIds), 
        dispatch, 
        state: getState(),
        cacheType: 'cache-if-exists',
        cacheCheck: recipeDraftIsCached(foodWeightIds),
        success: foodLoadSuccess
    });
}

const loadMyFoodsSuccess = data => ({
    type: actionTypes.LOAD_MY_FOODS,
    data
})

export const loadMyFoods = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadMyFoods,
        success: loadMyFoodsSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const destroyFoodSuccess = data => ({
    type: actionTypes.DESTROY_FOOD,
    data
})

export const destroyFood = foodId => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.destroyFood.bind(null,foodId),
        success: destroyFoodSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const updateFoodSuccess = (data) => ({
    type: actionTypes.UPDATE_FOOD,
    data
})

export const updateFood = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateFood.bind(null,data),
        success: updateFoodSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const preProcessDietTags = ({ dietTags, ...data }) => {
    if(dietTags) {
        const newData = { ...data, dietTags: _.compact(dietTags).join(',') };
        if(_.isBlank(newData.dietTags)) {
            newData.dietTags = 'standard';
        }
        return newData;
    }
    return data;
}

const createRecipeSuccess = data => ({
    type: actionTypes.CREATE_RECIPE,
    data
})

export const createRecipe = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createRecipe.bind(null,preProcessDietTags(data)),
        success: createRecipeSuccess,
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const instantCopyRecipe = recipeId => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.copyRecipe.bind(null,recipeId),
        success: createRecipeSuccess,
        dispatch,
        popupOnFail: true,
        flashOnSuccess: 'Copied',
        cacheType: 'none'
    });
}

const updateRecipeSuccess = id => data => ({
    type: actionTypes.UPDATE_RECIPE,
    data: { id, ...data }
})

export const updateRecipe = ({id, ...data}) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateRecipe.bind(null,{id, ...preProcessDietTags(data)}),
        success: updateRecipeSuccess(id),
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

const createRecipeOverrideSuccess = data => ({
    type: actionTypes.CREATE_RECIPE_OVERRIDE,
    data
})

export const createRecipeOverride = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createRecipeOverride.bind(null,preProcessDietTags(data)),
        success: createRecipeOverrideSuccess,
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

const restoreRecipeDefaultsSuccess = data => ({
    type: actionTypes.RESTORE_RECIPE_DEFAULTS,
    data
})

export const restoreRecipeDefaults = (id) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.restoreRecipeDefaults.bind(null,id),
        success: restoreRecipeDefaultsSuccess,
        dispatch,
        popupOnFail: true,
        cacheType: 'none'
    });
}

export const clearRecipeDraft = () => ({
    type: actionTypes.CLEAR_RECIPE_DRAFT
})

export const createUserLead = userLead => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createUserLead.bind(null,userLead),
        dispatch,
        cacheType: 'none'
    });
}

const storeCurrentUser = (userId,focusDate) => {
    sessionStorage.setItem('currentUserId',userId);
    if(!focusDate) {
        sessionStorage.removeItem('currentFocusDate');
    } else {
        sessionStorage.setItem('currentFocusDate',focusDate);
    }
}

const switchClientFinal = (data,trainerCache) => ({
    type: actionTypes.SWITCH_CLIENT_SUCCESS,
    data,
    trainerCache  
})

const switchClientSuccess = focusDate => data => (dispatch, getState) => {
    let trainerCache = trainerDataForSwitchSel(getState());

    storeCurrentUser(data.user.id,focusDate);

    dispatch(switchClientFinal(data,trainerCache))
}

const copyClientMealPlanSuccess = focusDate => data => (dispatch, getState) => {
    const { clientMpQuotaHit } = data;

    if(!clientMpQuotaHit) { 
        dispatch(switchClientSuccess(focusDate)(data));
    }
}

export const switchClient = (userId,focusDate) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.switchClient.bind(null,userId,focusDate),
        dispatch,
        cacheType: 'none',
        success: switchClientSuccess(focusDate),
        overrideUserId: userId,
        isUserSwitch: true
    });
}

const adminSwitchUserFinal = (data) => ({
    type: actionTypes.ADMIN_SWITCH_USER_SUCCESS,
    data 
})

const adminSwitchUserSuccess = focusDate => data => (dispatch) => {
    const adminId = localStorage.getItem('adminId');

    if(_.isBlank(adminId) || Number(adminId) !== data.user.id) {
        storeCurrentUser(data.user.id,focusDate);
    } else {
        sessionStorage.removeItem('currentUserId');
    }

    dispatch(adminSwitchUserFinal(data))
}

export const adminSwitchUser = (userId,focusDate) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.switchClient.bind(null,userId,focusDate),
        dispatch,
        cacheType: 'none',
        success: adminSwitchUserSuccess(focusDate),
        overrideUserId: userId,
        isUserSwitch: true
    });
}

export const copyClientMealPlan = (userId,focusDate) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.copyClientMealPlan.bind(null,focusDate),
        dispatch,
        cacheType: 'none',
        success: copyClientMealPlanSuccess(focusDate),
        overrideUserId: userId,
        isUserSwitch: true
    });
}

export const switchToTrainerCore = () => (dispatch,getState) => {
    let data = trainerCacheSelector(getState());

    clearCurrentUser();

    dispatch({
        type: actionTypes.SWITCH_TO_TRAINER,
        data: { ...data }
    })

    return Promise.resolve({ status: 'SUCCESS' });
}

export const switchToTrainer = () => (dispatch) => {
    return dispatch(cleanupRoutine(moment(),false,true)).then(() => dispatch(switchToTrainerCore())) //date doesn't actually matter since only logging
}

const createChildTrainerSuccess = data => dispatch => {
    if(!data.error) {
        dispatch({type: actionTypes.CREATE_CHILD_TRAINER, data})
    }
}

export const createChildTrainer = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createChildTrainer.bind(null,data),
        dispatch,
        cacheType: 'none',
        success: createChildTrainerSuccess,
        trainerSpace: true
    });
}

const checkTrainerRefreshSuccess = data => dispatch => {
    if(data && data.trainerData) {
        dispatch(loadUserSuccess(false)(data));
    }
}

export const checkTrainerRefresh = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.checkTrainerRefresh,
        dispatch,
        success: checkTrainerRefreshSuccess,
        cacheType: 'none',
        showPopup: false
    });
}

export const checkForBreakingUpdate = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.checkForBreakingUpdate,
        dispatch,
        cacheType: 'none',
        showPopup: false
    });
}

export const createWorkoutRoutine = (params) => (dispatch,getState) => {
    const needToLoadGroups = needToLoadExerciseGroupsSel(getState());
    return userSpaceApiCall({ 
        apiCall: api.createWorkoutRoutine.bind(null,params,warmupsNeedLoadingSel(getState()),needToLoadGroups),
        dispatch,
        cacheType: 'none',
        success: loadRoutineSuccess(needToLoadGroups)
    });
}

export const loadExerciseGroups = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadExerciseGroups,
        dispatch,
        cacheType: 'none'
    });
}

const importExerciseGroupSuccess = data => ({
    type: actionTypes.IMPORT_EXERCISE_GROUPS,
    data
})

export const importExerciseGroups = data => dispatch => {
    const { type, ...rest } = data;
    const apiCall = type === 'mark' ? api.markExerciseGroupsOwned : api.importExerciseGroups;

    return userSpaceApiCall({ 
        apiCall: apiCall.bind(null,rest),
        success: importExerciseGroupSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: 'Added'
    });
}

export const loadWorkoutTemplates = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadWorkoutTemplates,
        dispatch,
        cacheType: 'none'
    });
}

const importWorkoutTemplatesSuccess = data => ({
    type: actionTypes.IMPORT_WORKOUT_TEMPLATES,
    data
})

export const importWorkoutTemplates = data => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.importWorkoutTemplates.bind(null,data),
        success: importWorkoutTemplatesSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: 'Added'
    });
}

const loadClientsSuccess = skipRemove => data => (dispatch,getState) => {

    let removeUserIds = [];
    let removeRoutineIds = [];
    let removeDnpIds = [];

    if(data.meta && data.meta.page === 1 && !skipRemove) {
        const state = getState();
        const users = usersSelector(state);
        const dnps = dailyNutritionProfilesSel(state);
        if(users) {
            const preIds = Object.keys(_.pickBy(users,user => (user.type === 'TrainerClient'))).map(id => Number(id));
            removeUserIds = _.difference(preIds,data.meta.ids);
            removeRoutineIds = _.compact(Object.values(_.pick(users,removeUserIds)).map(user => user.assignedRoutineId));
            removeDnpIds = _.filter(Object.values(dnps || {}),dnp => removeUserIds.includes(dnp.userId));
        }
    }

    const action = {
        type: actionTypes.LOAD_CLIENTS,
        removeUserIds,
        removeRoutineIds,
        removeDnpIds,
        data
    }

    dispatch(action);
}

export const loadUnsyncedClients = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadUnsyncedClients,
        success: loadClientsSuccess(true),
        dispatch,
        cacheType: 'none'
    });
}


const loadAllTrainersSuccess = type => data => ({
    type: actionTypes.LOAD_ALL_TRAINERS,
    data: { type, ...data }
})

export const loadAllTrainers = type => filters => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadAllTrainers.bind(null,filters),
        success: loadAllTrainersSuccess(type),
        dispatch,
        cacheType: 'none'
    });
}

const loadClientSuccess = data => ({
    type: actionTypes.LOAD_CLIENT,
    data
})

export const loadClient = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadClient.bind(null,id),
        success: loadClientSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const loadClientsForDash = (params,skipRemove) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadClientsForDash.bind(null,params),
        success: loadClientsSuccess(skipRemove),
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        showPopup: false
    });
}

const loadTrainerRoutinesSuccess = data => ({
    type: actionTypes.LOAD_TRAINER_ROUTINES,
    data
})

export const loadTrainerRoutines = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadTrainerRoutines.bind(null,data),
        success: loadTrainerRoutinesSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const importRoutineSuccess = data => ({
    type: actionTypes.LOAD_TRAINER_ROUTINES,
    data
})

export const importRoutine = (id,popup) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.importRoutine.bind(null,id),
        success: importRoutineSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: popup ? 'Copied' : null,
        popupOnFail: true

    });
}

export const setTrainerForRoutines = id => ({
    type: actionTypes.SET_TRAINER_FOR_ROUTINES,
    data: { trainerId: id }
})

export const setTrainerForHabits = id => ({
    type: actionTypes.SET_TRAINER_FOR_HABITS,
    data: { trainerId: id }
})

const loadTrainerFormsSuccess = data => ({
    type: actionTypes.LOAD_FORMS_SUCCESS,
    data
})

export const loadTrainerForms = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadTrainerForms.bind(null,data),
        success: loadTrainerFormsSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const loadTrainerForm = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadTrainerForm.bind(null,data),
        success: loadTrainerFormsSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const importTrainerForm = (data,popup) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.importTrainerForm.bind(null,data),
        success: loadTrainerFormsSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: popup ? 'Copied' : null,
        popupOnFail: true
    });
}

const updateTrainerFormSuccess = data => ({
    type: actionTypes.UPDATE_FORM_SUCCESS,
    data
})

export const createTrainerForm = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.createTrainerForm.bind(null,data),
        success: updateTrainerFormSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const deleteTrainerForm = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.deleteTrainerForm.bind(null,data),
        success: updateTrainerFormSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const updateTrainerForm = (data,flash) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateTrainerForm.bind(null,data),
        success: updateTrainerFormSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: flash,
        popupOnFail: true
    });
}

export const fillTrainerForm = (data,flash) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.fillTrainerForm.bind(null,data),
        success: updateTrainerFormSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: flash,
        popupOnFail: true
    });
}

export const reviewTrainerForm = (data,flash) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.reviewTrainerForm.bind(null,data),
        success: updateTrainerFormSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: flash,
        popupOnFail: true
    });
}

export const updateFormFieldOrder = (form) => (dispatch) => {

    const apiCall = api.updateFormFieldOrder.bind(null,form.fieldOrderParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: updateTrainerFormSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateFormFieldSuccess = data => ({
    type: actionTypes.UPDATE_FORM_FIELD_SUCCESS,
    data
})

export const deleteFormField = (formField) => (dispatch) => {

    const apiCall = api.deleteFormField.bind(null,formField.destroyParams());
    return userSpaceApiCall({ 
        apiCall, 
        success: updateFormFieldSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const createFormField = (data,flashOnSuccess) => (dispatch) => {

    const apiCall = api.createFormField.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateFormFieldSuccess,
        dispatch, 
        cacheType: 'none',
        flashOnSuccess,
        popupOnFail: true
    });
}

export const updateFormField = (data) => (dispatch) => {

    const apiCall = api.updateFormField.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateFormFieldSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const initFormFieldDataEntry = ({ clientId, ...data }) => (dispatch) => {

    const apiCall = api.initFormFieldDataEntry.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateFormFieldSuccess,
        dispatch, 
        overrideUserId: clientId,
        cacheType: 'none'
    });
}

export const loadFormFieldHistory = ({ clientId, ...data }) => (dispatch) => {

    const apiCall = api.loadFormFieldHistory.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        overrideUserId: clientId,
        cacheType: 'none'
    });
}

export const tempDismissTip = (tipName) => ({
    type: actionTypes.TEMP_DISMISS_TIP,
    data: { tipName }
})

export const setClientFilters = filters => ({
    type: actionTypes.SET_CLIENT_FILTERS,
    data: filters
})

export const setTrainertFilters = filters => ({
    type: actionTypes.SET_TRAINER_FILTERS,
    data: filters
})

const tagClientsSuccess = data => ({
    type: actionTypes.TAG_CLIENTS,
    data
})

export const tagClients = (data) => dispatch => {
    const apiCall = api.tagClients;
    return userSpaceApiCall({ 
        apiCall: apiCall.bind(null,data),
        success: tagClientsSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateClientsSuccess = data => {
    if(data.error) {
        return {
            type: actionTypes.NOOP
        }
    }

    return {
        type: actionTypes.UPDATE_CLIENTS,
        data
    }
}

export const updateClients = (data,popupOnSuccess) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateClients.bind(null,data),
        success: updateClientsSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: popupOnSuccess 
    });
}

export const updateClient = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateClient.bind(null,data),
        success: updateClientsSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const updateClientProfiles = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateClientProfiles.bind(null,data),
        success: updateClientsSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

const createClientSuccess = data => (dispatch,getState) => {
    const state = getState();
    const curFilters = clientFiltersSelector(state);
    const trainer = trainerSelector(state);
    let prependIds = [];
    if(curFilters && data.users && !_.isEmpty(data.users)) {
        const newTrainerId =  Object.values(data.users)[0].trainerId;
        const filtTrainerId = curFilters.trainerId === 'me' ? (trainer && trainer.id) : curFilters.trainerId;
        const shouldPrepend = _.isBlank(filtTrainerId) || filtTrainerId === newTrainerId;
        if(shouldPrepend) {
            prependIds = Object.keys(data.users).map(id => `${Number(id)}`);
        }
    }

    dispatch({
        type: actionTypes.CREATE_CLIENT,
        data: { ...data, prependIds}
    })

} 

export const createClient = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createClient.bind(null,data),
        success: createClientSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: data.type === 'invite' ? { msg: 'Client Invited' } : { msg: 'Client Created' }
    });
}

const sendInviteSuccess = data => ({
    type: actionTypes.SEND_INVITE,
    data
})

export const sendInvite = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.sendInvite.bind(null,data),
        success: sendInviteSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Email Sent' }
    });
}

const assignTrainerSuccess = data => ({
    type: actionTypes.ASSIGN_TRAINER,
    data
})

export const assignTrainer = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.assignTrainer.bind(null,data),
        success: assignTrainerSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: 'Reassigned'
    });
}

const updateClientMealPlanSuccess = data => ({
    type: actionTypes.UPDATE_CLIENT_MEAL_PLAN,
    data
})

export const updateClientMealPlan = (data,autoSubmit) => dispatch => {
    data = parseExtraKeywords(data);
    return userSpaceApiCall({ 
        apiCall: api.updateClientMealPlan.bind(null,data),
        success: updateClientMealPlanSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        flashOnSuccess: autoSubmit
    });
}

const createTrainerSuccess = (data) => {

    if(data.error) {
        return {
            type: actionTypes.NOOP,
            data
        }
    } else {
        return {
            type: actionTypes.CREATE_TRAINER,
            data
        }
    }
}

export const createTrainer = (data) => (dispatch) => {
    const apiCall = api.createTrainer.bind(null,{ ...data });
    const success = createTrainerSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        success, 
        dispatch,
        popupOnFail: true
    });
}

const initTsubSuccess = data => (dispatch) => {
    if(data.error) {
        return dispatch({
            type: actionTypes.NOOP,
            data
        });
    } else {
        logTrainerSubscription();
    
        return dispatch({
            type: actionTypes.TRAINER_SUB_UPDATED,
            data
        });
    }
}

export const initStripeTrainerSub = (data) => (dispatch) => {
    const apiCall = api.initStripeTrainerSub.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        success: initTsubSuccess,
        cacheType: 'none'
    });
}

const updateTsubSuccess = data => ({
    type: actionTypes.TRAINER_SUB_UPDATED,
    data
})

export const updateTrainerSub = data => (dispatch) => {
    const apiCall = api.updateTrainerSub.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        success: updateTsubSuccess,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: (!_.isBlank(data.coupon) ? { msg: 'disc appl' } : null),
    });
}

export const tSubscribeImmediately = () => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.tSubscribeImmediately, 
        dispatch, 
        success: updateTsubSuccess,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Subscribed' },
    });
}

export const loadRequiredTrainingDays = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadRequiredTrainingDays.bind(null,id), 
        dispatch, 
        cacheType: 'none'
    });
}

const crupdateTrainerNoteSuccess = data => ({
    type: actionTypes.CRUPDATE_TRAINER_NOTE,
    data
})

export const createTrainerNote = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createTrainerNote.bind(null,data),
        success: crupdateTrainerNoteSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const updateTrainerNote = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateTrainerNote.bind(null,data),
        success: crupdateTrainerNoteSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const destroyTrainerNoteSuccess = data => ({
    type: actionTypes.DESTROY_TRAINER_NOTE,
    data
})

export const deleteTrainerNote = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.deleteTrainerNote.bind(null,data),
        success: destroyTrainerNoteSuccess,
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const loadClientStats = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadClientStats.bind(null,data), 
        dispatch, 
        cacheType: 'none'
    });
}

export const setViewLogsDate = date => ({
    type: actionTypes.SET_VIEW_LOGS_DATE,
    data: { date }
})

const loadLogsSuccess = (data) => ({
    type: actionTypes.LOAD_LOGS_SUCCESS,
    data
})

export const loadLogs = (date) => (dispatch) => {
    const apiCall = api.loadLogs.bind(null,date);
    const success = loadLogsSuccess;
    return userSpaceApiCall({ 
        apiCall, 
        dispatch,
        success,
        cacheType: 'none'
    });
}


const dereactivateSuccess = data => ({
    type: actionTypes.DEREACTIVATE_TRAINERS,
    data
})

export const deactivateTrainers = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.deactivateTrainers.bind(null,data),
        success: dereactivateSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const reactivateTrainers = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.reactivateTrainers.bind(null,data),
        success: dereactivateSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const updateTrainerSuccess = data => ({
    type: actionTypes.UPDATE_TRAINER,
    data
})

export const updateTrainer = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateTrainer.bind(null,data),
        success: updateTrainerSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const updateHabitSuccess = data => ({
    type: actionTypes.UPDATE_HABIT,
    data
})

export const createHabit = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createHabit.bind(null,data),
        success: updateHabitSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const updateHabit = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateHabit.bind(null,data),
        success: updateHabitSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const deleteHabit = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.deleteHabit.bind(null,data),
        success: updateHabitSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const loadHabits = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadHabits.bind(null,data),
        success: updateHabitSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const importHabit = (id,popup) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.importHabit.bind(null,id),
        success: updateHabitSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: popup ? 'Copied' : null,
        popupOnFail: true

    });
}

const loadSchedulableSettingsSuccess = data => ({
    type: actionTypes.LOAD_SCHEDULABLE_SETTINGS,
    data
})

export const loadSchedulableSettings = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadSchedulableSettings.bind(null,data),
        success: loadSchedulableSettingsSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const assignSchedulables = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.assignSchedulables.bind(null,data),
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

const logHabitSuccess = data => ({
    type: actionTypes.LOG_HABIT_SUCCESS,
    data
})

export const logHabit = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.logHabit.bind(null,data),
        dispatch,
        success: logHabitSuccess,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const loadBmHistory = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadBmHistory.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

const updatePdfExportSettings = values => ({
    type: actionTypes.UPDATE_PDF_EXPORT_SETTINGS,
    data: { values: _.omit(values,'clientId','startDate','email') }
})

export const sendPlanPdfEmail = data => dispatch => {
    if(data.saveToDefaults) {
        dispatch(updatePdfExportSettings(data));
    }

    return userSpaceApiCall({ 
        apiCall: api.sendPlanPdfEmail.bind(null,data),
        dispatch,
        cacheType: 'none',
        popupOnSuccess: { msg: 'Email Sent' }
    });
}

export const downloadPlanPdf = data => dispatch => {
    if(data.saveToDefaults) {
        dispatch(updatePdfExportSettings(data));
    }
    return userSpaceApiCall({ 
        apiCall: api.downloadPlanPdf.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

export const downloadGroceryList = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.downloadGroceryList.bind(null,data),
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const sendRoutinePdfEmail = data => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.sendRoutinePdfEmail.bind(null,data),
        dispatch,
        cacheType: 'none',
        popupOnSuccess: { msg: 'Email Sent' }
    });
}

export const downloadRoutinePdf = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.downloadRoutinePdf.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

export const downloadTrainerProductivity = data => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.downloadTrainerProductivity.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

const jumpToBrowserSuccess = data => dispatch => {
    if(window.isCordova) {
        window.cordova.InAppBrowser.open(data.url, '_system', '');
    } else {
        window.open(data.url,'_blank');
    }
}

export const jumpToBrowser = path => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.jumpToBrowser.bind(null,path),
        dispatch,
        cacheType: 'none',
        success: jumpToBrowserSuccess
    });
}

export const getAppLinks = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.getAppLinks,
        dispatch,
        cacheType: 'none'
    });
}

const uploadLogoSuccess = data => ({
    type: actionTypes.UPLOAD_TRAINER_LOGO,
    data
})

export const uploadTrainerLogo = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.uploadTrainerLogo.bind(null,data),
        dispatch,
        success: uploadLogoSuccess,
        cacheType: 'none',
        popupOnFail: true
    });
}

const updateMpInfoSuccess = data => ({
    type: actionTypes.UPDATE_MP_INFO_STUB,
    data
})

export const updateMpInfoStub = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateMpInfoStub.bind(null,data),
        dispatch,
        success: updateMpInfoSuccess,
        cacheType: 'none',
        popupOnFail: true
    });
}

const switchTtypeSuccess = data => ({
    type: actionTypes.SWITCH_TTYPE_SUCCESS,
    data
})

export const apiSwitchTrainerType = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.switchTrainerType.bind(null,data),
        dispatch,
        success: switchTtypeSuccess,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: "account type switched" }
    });
}

const updateTBasicProfileSuccess = data => ({
    type: actionTypes.UPDATE_TBASIC_PROFILE,
    data
})

export const updateTBasicProfile = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateTBasicProfile.bind(null,data),
        dispatch,
        success: updateTBasicProfileSuccess,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const getCustomDomain = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.getCustomDomain,
        dispatch,
        cacheType: 'none'
    });
}

export const setCustomDomain = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.setCustomDomain.bind(null,data),
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

const getJwtSuccess = data => ({
    type: actionTypes.GET_JWT,
    data
})

export const getJwt = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.getJwt,
        success: getJwtSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const cacheChatMessage = data => ({
    type: actionTypes.CACHE_CHAT_MESSAGE,
    data
})

const messageFailedToSend = tempKey => () => ({
    type: actionTypes.MESSAGE_SEND_FAILED,
    data: { tempKey }
})

export const discardMessages = tempKeys => ({
    type: actionTypes.DISCARD_MESSAGES,
    data: { tempKeys }
})

export const sendChatMessage = data => dispatch => {
    const tempKey = (data.tempKey || `t${_.random(99999999)}`);
    data = { ...data, tempKey, attemptedSendAt: moment().valueOf() };
    dispatch(cacheChatMessage(data));

    return userSpaceApiCall({ 
        apiCall: api.sendChatMessage.bind(null,data),
        dispatch,
        cacheType: 'none',
        onFail: messageFailedToSend(tempKey)
    });
}

export const forceSendChatMessage = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.forceSendChatMessage.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

const recalcUnreadMessages = (dispatch,getState) => () => {
    const user = chatUserSelector(getState());
    const update = user.needsUnreadMsgUpdate();

    if(update) {
        dispatch({
            type: actionTypes.UPDATE_UNREAD_MSGS,
            data: { unreadMessagesMap: update }
        });
    }
}

const preloadMsgImages = ({ chatMessages }) => {
    return new Promise((resolve,reject) => {
        let loaded = [];
        
        if(chatMessages) {
            let needToLoad = _.filter(Object.values(chatMessages),msg => (msg.image && msg.image.url));
    
            if(needToLoad.length > 0) {
                const handlerFn = msg => () => {
                    if(!loaded.includes(msg.id)) {
                        loaded.push(msg.id);
                    }
                    if(loaded.length === needToLoad.length) {
                        resolve(true);
                    }
                }
                needToLoad.forEach(msg => {
                    const img = new Image();
    
                    img.onload = img.onerror = handlerFn(msg);
                    img.src = msg.image.url;
                })
            } else {
                resolve(true);
            }
        } else {
            resolve(true);
        }
    });
}

export const healthkitDataReceived = data => dispatch => {

    dispatch({
        type: actionTypes.HEALTHKIT_DATA_RECEIVED,
        data
    });
}

export const chatDataReceived = data => (dispatch,getState) => {
    preloadMsgImages(data).then(() => {
        dispatch({
            type: actionTypes.CHAT_DATA_RECEIVED,
            data
        });
        setTimeout(recalcUnreadMessages(dispatch,getState),1);
    });
}

export const createChat = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createChat.bind(null,data),
        dispatch,
        success: chatDataReceived,
        cacheType: 'none',
        popupOnFail: true
    });
}

const loadChatsSuccess = data => ({
    type: actionTypes.LOAD_CHATS,
    data
})

export const loadChats = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadChats.bind(null,data),
        success: loadChatsSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const loadChatMessagesSuccess = data => dispatch => {
    return preloadMsgImages(data).then(() => {
        dispatch({
            type: actionTypes.LOAD_CHAT_MESSAGES,
            data
        });
        return Promise.resolve(true);
    });
}

export const loadChatMessages = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadChatMessages.bind(null,data),
        success: loadChatMessagesSuccess,
        dispatch,
        cacheType: 'none',
        waitForSuccess: true
    });
}

export const markMessagesRead = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.markMessagesRead.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

export const updateChat = data => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.updateChat.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

export const toggleArchiveChat = (chatId) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.toggleArchiveChat.bind(null,chatId),
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

export const muteChat = (chatId) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.muteChat.bind(null,chatId),
        dispatch,
        cacheType: 'none',
        popupOnFail: true
    });
}

const activateMessagingSuccess = data => ({
    type: actionTypes.ACTIVATE_MESSAGING,
    data
})

export const activateMessaging = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.activateMessaging.bind(null,data),
        success: activateMessagingSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const getChatCount = () => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.getChatCount,
        dispatch,
        cacheType: 'none'
    });
}

export const loadApiKey = () => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.loadApiKey,
        dispatch,
        cacheType: 'none'
    });
}

export const billableClientCount = () => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.billableClientCount,
        dispatch,
        cacheType: 'none'
    });
}

export const getInvoices = () => dispatch => {
    
    return userSpaceApiCall({ 
        apiCall: api.getInvoices,
        dispatch,
        cacheType: 'none'
    });
}

export const checkExportLimits = (data) => dispatch => {

    return userSpaceApiCall({ 
        apiCall: api.checkExportLimits.bind(null,data),
        dispatch,
        cacheType: 'none'
    });
}

const dnpLoadSuccess = data => ({
    type: actionTypes.DNP_LOAD_SUCCESS,
    data
})

export const loadDnps = () => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.loadDnps,
        success: dnpLoadSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const dnpUpdateSuccess = data => ({
    type: actionTypes.DNP_UPDATE_SUCCESS,
    data
})

export const createDnp = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createDnp.bind(null,data),
        success: dnpUpdateSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const updateDnp = (data) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateDnp.bind(null,data),
        success: dnpUpdateSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const destroyDnp = (id) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.destroyDnp.bind(null,id),
        success: dnpUpdateSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const videoUploadProgress = ({ recordId, recordClass, percent }) => ({
    type: actionTypes.MUX_VIDEO_UPLOAD_PROG,
    data: { recordId, recordClass, percent }
})

const activeUploads = {};
export const doVideoUpload = ({ file,url,recordId,recordClass }) => dispatch => {
    let lastProg = 1;
    let cordovaFile = null;
    let uploadKey = `${recordClass}|${recordId}`;

    //File gets overridden by cordova which breaks upchunk so need to temporarily reset it
    if(window.NativeFile && !(window.File instanceof Blob)) {
        cordovaFile = window.File;
        window.File = window.NativeFile;
    }

    const unloadHandler = () => {
        dispatch(failMuxUpload({ id: recordId, type: recordClass }));
    }

    const upload = UpChunk.createUpload({
        endpoint: url,
        file,
        chunkSize: 5120 // Uploads the file in ~5mb chunks
      });

      if(activeUploads[uploadKey]) { 
        activeUploads[uploadKey].abort();
      }
      activeUploads[uploadKey] = upload;
      
      // subscribe to events
      upload.on('error', err => {
            dispatch(failMuxUpload({ id: recordId, type: recordClass }));
            window.removeEventListener('beforeunload', unloadHandler);
      });
      
      upload.on('progress', progress => {
        const pct = Math.round(progress.detail);
        if(pct !== lastProg) {
            lastProg = pct;
            dispatch(videoUploadProgress({ recordId, recordClass, percent: pct }))
        }
      });
      
      // subscribe to events
      upload.on('success', err => {
        dispatch(finishMuxUpload({ id: recordId, type: recordClass }));
        window.removeEventListener('beforeunload', unloadHandler);
      });

      window.addEventListener('beforeunload', unloadHandler);

      if(cordovaFile) {
        window.File = cordovaFile;
      }
}

const startMuxUploadSuccess = file => data => dispatch => {
    const { uploadMetadata: { url, recordId, recordClass } } = data;
    
    dispatch(doVideoUpload({ file,url,recordId,recordClass }));

    dispatch({
        type: actionTypes.START_MUX_UPLOAD_SUCCESS,
        data
    })
}

export const startMuxUpload = (data,file) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.startMuxUpload.bind(null,data),
        success: startMuxUploadSuccess(file),
        dispatch,
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Video upload started' }
    });
}

const cancelMuxUploadSuccess = data => ({ 
    type: actionTypes.CANCEL_MUX_UPLOAD_SUCCESS,
    data
})

export const cancelMuxUpload = (params) => (dispatch) => {
    const { id, type } = params;
    const uploadKey = `${type}|${id}`;
    if(activeUploads[uploadKey]) {
        activeUploads[uploadKey].abort();
        activeUploads[uploadKey] = null;
    }

    const apiCall = api.cancelMuxUpload.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: cancelMuxUploadSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const finishMuxUpload = (params) => (dispatch) => {
    const { id, type } = params;
    const uploadKey = `${type}|${id}`;
    if(activeUploads[uploadKey]) {
        activeUploads[uploadKey] = null;
    }

    const apiCall = api.finishMuxUpload.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: cancelMuxUploadSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true,
        popupOnSuccess: { msg: 'Video upload finished' }
    });
}

export const failMuxUpload = (params) => (dispatch) => {
    const { id, type } = params;
    const uploadKey = `${type}|${id}`;
    if(activeUploads[uploadKey]) {
        activeUploads[uploadKey] = null;
    }

    const apiCall = api.failMuxUpload.bind(null,params);
    return userSpaceApiCall({ 
        apiCall, 
        success: updateExerciseSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

export const setInitialRecipesFilter = data => ({
    type: actionTypes.SET_INITIAL_RECIPE_FILTER,
    data
})

const createActivityLogSuccess = data => ({
    type: actionTypes.CREATE_ACTIVITY_LOG,
    data
})

export const createActivityLog = (data,noPopup) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.createActivityLog.bind(null,data),
        dispatch,
        success: createActivityLogSuccess,
        cacheType: 'none',
        popupOnSuccess: noPopup ? null : { msg: 'Activity logged' },
        hkSyncOnSuccess: true
    });
}

const updateActivityLogSuccess = data => ({
    type: actionTypes.UPDATE_ACTIVITY_LOG,
    data
})

export const updateActivityLog = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.updateActivityLog.bind(null,data),
        dispatch,
        success: updateActivityLogSuccess,
        cacheType: 'none',
        hkSyncOnSuccess: true
    });
}

const destroyActivityLogSuccess = data => ({
    type: actionTypes.DESTROY_ACTIVITY_LOG,
    data
})

export const destroyActivityLog = id => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.destroyActivityLog.bind(null,id),
        dispatch,
        success: destroyActivityLogSuccess,
        cacheType: 'none',
        hkSyncOnSuccess: true
    });
}

export const startActivity = data => ({
    type: actionTypes.START_ACTIVITY,
    data
})

export const discardCurrentActivity = data => ({
    type: actionTypes.DISCARD_CURRENT_ACTIVITY,
    data
})

export const getOwnedHealthkitData = (date,success) => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.getOwnedHealthkitData.bind(null,date),
        dispatch,
        success,
        cacheType: 'none'
    });
}

const publishMealPlanSuccess = data => ({
    type: actionTypes.PUBLISH_MEAL_PLAN,
    data
})

export const publishMealPlan = data => dispatch => {
    return userSpaceApiCall({ 
        apiCall: api.publishMealPlan.bind(null,data),
        success: publishMealPlanSuccess,
        dispatch,
        cacheType: 'none',
        popupOnSuccess: { msg: 'Meal plan published' },
        popupOnFail: true
    });
}

export const requestMealPlan = (data) => (dispatch) => {
    const apiCall = api.requestMealPlan.bind(null,data);
    return userSpaceApiCall({ 
        apiCall, 
        dispatch, 
        cacheType: 'none',
        popupOnSuccess: { msg: 'Request Sent' }
    });
}

const updateTrainerAssessmentSuccess = data => ({
    type: actionTypes.UPDATE_ASSESSMENT_SUCCESS,
    data
})

export const createTrainerAssessment = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.createTrainerAssessment.bind(null,data),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const loadTrainerAssessments = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.loadTrainerAssessments.bind(null,data),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const loadTrainerAssessment = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.loadTrainerAssessment.bind(null,data),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const importTrainerAssessment = (data,popup) => dispatch => {
    return userSpaceApiCall({
        apiCall: api.importTrainerAssessment.bind(null,data),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: popup ? 'Copied' : null,
        popupOnFail: true
    });
}

export const deleteTrainerAssessment = id => dispatch => {
    return userSpaceApiCall({
        apiCall: api.deleteTrainerAssessment.bind(null,id),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none'
    });
}

export const updateTrainerAssessment = (data,flash) => dispatch => {
    return userSpaceApiCall({
        apiCall: api.updateTrainerAssessment.bind(null,data),
        success: updateTrainerAssessmentSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: flash,
        popupOnFail: true
    });
}

const setDefaultFormSchedSuccess = (data) => ({
    type: actionTypes.SET_FORM_DEFAULT_SCHED,
    data
})

export const setDefaultFormScheduling = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.setDefaultFormScheduling.bind(null,data),
        success: setDefaultFormSchedSuccess,
        dispatch,
        cacheType: 'none'
    });
}

const removeFormAsDefSuccess = (data) => ({
    type: actionTypes.REMOVE_FORM_AS_DEF,
    data
})

export const removeFormFromDefaults = data => dispatch => {
    return userSpaceApiCall({
        apiCall: api.removeFormFromDefaults.bind(null,data),
        success: removeFormAsDefSuccess,
        dispatch,
        cacheType: 'none',
        flashOnSuccess: 'Removed'
    });
}

const loadProgressPhotosSuccess = data => ({
    type: actionTypes.LOAD_PROGRESS_PHOTOS,
    data
})

export const loadProgressPhotos = () => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.loadProgressPhotos, 
        success: loadProgressPhotosSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const createProgPhotoSuccess = data => ({
    type: actionTypes.CREATE_PROGRESS_PHOTO,
    data
})

export const createProgressPhoto = (data) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.createProgressPhoto.bind(null,data), 
        success: createProgPhotoSuccess,
        dispatch, 
        cacheType: 'none'
    });
}

const destroyProgPhotoSuccess = data => ({
    type: actionTypes.DESTROY_PROGRESS_PHOTO,
    data
})

export const destroyProgressPhoto = (data) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.destroyProgressPhoto.bind(null,data), 
        success: destroyProgPhotoSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}

export const setFilterBtnFilts = ({ filterKey, filters }) => ({
    type: actionTypes.SET_FILTER_BTN_FILTS,
    data: { filterKey, filters }
})

const initMiniProfilesSuccess = data => ({
    type: actionTypes.INIT_MINI_PROFILES,
    data
})

export const initMiniProfiles = (data) => (dispatch) => {
    return userSpaceApiCall({ 
        apiCall: api.initMiniProfiles.bind(null,data), 
        success: initMiniProfilesSuccess,
        dispatch, 
        cacheType: 'none',
        popupOnFail: true
    });
}