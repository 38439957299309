import { Eatable, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { allMacrosWithCals } from './classes';
import { noRecipeImages, Recipe } from './recipe';
import OffPlanMeal from 'assets/img/OffPlanMeal.jpg';
import thumbOffPlanMeal from 'assets/img/thumbOffPlanMeal.jpg';
import mediumThumbOffPlanMeal from 'assets/img/mediumThumbOffPlanMeal.jpg';
import { SideDish } from './recipe-meal';

export const offPlanImages = { OffPlanMeal, thumbOffPlanMeal, mediumThumbOffPlanMeal };

const mealShared = {

    sideDishes() {
        return _.filter(this.recipeMeals,rm => !rm.isMainDish());
    },

    mainDish() {
        return _.find(this.recipeMeals,sideDish => sideDish.isMainDish()) || this.recipeMeals[0];
    },
        
    macroHash() {
        return _.hashSum(this.recipeMeals.map(recipeMeal => recipeMeal.macroHash()));
    },

    mainRecipe() {
        return this.mainDish().recipe;
    },

    mainRecipeId() {
        return this.mainDish().recipeId;
    },

    sideRecipeIds() {
        return this.sideDishes().map(sideDish => sideDish.recipeId)
    },

    totalCost() {
        return _.roundToF(this.recipeMeals.reduce((sum,rm) => (sum + rm.cost()),0),0.01)
    },

    dollarSigns() {
        const cost = this.totalCost();
        const cals = this.macroHash().calories;
        const normalizedCost = (500.0/cals)*cost;

        if(normalizedCost <= 1.0 || cost <= 1.0) {
            return 1;
        } else if(normalizedCost <= 1.75 || cost <= 1.75) {
            return 2;
        } else if(normalizedCost <= 2.5 || cost <= 2.5) {
            return 3;
        } else {
            return 4;
        }
    },

    totalActiveTime() {
        return this.recipeMeals.reduce((sum,rm) => (sum + rm.activeTime()),0)
    },

    totalFoodsArr() {
        return this.recipeMeals.reduce((arr,rm) => (_.union(arr,rm.foodsArr())),[])
    },

    ingredientLoad(prevFoodList=[],foodListWithout=[]) {
        const newList = _.union(foodListWithout,this.totalFoodsArr());
        return newList.length - prevFoodList.length;
    },

    recipes() {
        return this.recipeMeals.map(recipeMeal => recipeMeal.recipe);
    },

    recipeIds() {
        return this.recipeMeals.map(recipeMeal => recipeMeal.recipeId);
    },

    rawMealObject() {
        return { sideDishes: this.recipeMeals.map(recipeMeal => recipeMeal.rawObject()) }
    },

    recipeMealsLoaded() {
        return _.every(this.recipes(),recipe => !!recipe);
    },

    recipeIdsNeedLoading() {
        return this.recipeMeals.map(recipeMeal => recipeMeal.recipeId);
    },
    
    orderedDishes(pendingDestructions) {
        const rms = pendingDestructions ? _.filter(this.recipeMeals,rm => !pendingDestructions.includes(rm.recipeId)) : this.recipeMeals;
        return _.sortBy(rms, recipeMeal => recipeMeal.dishOrder())
    },
    
    urlStub() {
        return this.orderedDishes().map(recipeMeal => `${recipeMeal.recipeId}_${recipeMeal.servings}`).join('-');
    },

    replaceDish(recipeId,replacementId,replacementServings) {
        let sideDishes;
        if(_.isBlank(recipeId)) {
            sideDishes = this.recipeMeals.map(rm => rm.rawObject());
            let newDish = _.remove(sideDishes,sideDish => sideDish.recipeId === replacementId)[0] || { recipeId: replacementId, servings: 0, mainDish: false };
            newDish.servings = newDish.servings + replacementServings;
            sideDishes = [ ...sideDishes, newDish ];
        } else if(!_.isBlank(replacementId)) {
            sideDishes = this.recipeMeals.map(rm => rm.rawObject());
            let target = _.remove(sideDishes,sideDish => (sideDish.recipeId === replacementId && sideDish.recipeId !== recipeId))[0];
            if(target) {
                const old = _.remove(sideDishes,sideDish => sideDish.recipeId === recipeId)[0];
                if(old && old.mainDish) {
                    target.mainDish = true;
                }
                target.servings = target.servings + replacementServings;
                sideDishes = [ ...sideDishes, target ];
            } else {
                sideDishes = this.recipeMeals.map(sideDish => (sideDish.recipeId === recipeId ? { recipeId: replacementId, servings: replacementServings, mainDish: sideDish.mainDish } : sideDish.rawObject()))
            }
        } else {
            sideDishes = _.filter(this.recipeMeals,rm => rm.recipeId !== recipeId).map(rm => rm.rawObject());
            if(!_.some(sideDishes,sd => sd.mainDish)) {
                sideDishes[0] = { ...sideDishes[0], mainDish: true }
            }
        }
        return { sideDishes }
    },

    setServingsFor(recipeId,servings) {
        const rawObject = this.rawMealObject();
        if(_.isNumeric(servings) && servings > 0) {
            rawObject.sideDishes = rawObject.sideDishes.map(sideDish => (sideDish.recipeId === recipeId ? { ...sideDish, servings } : sideDish ))
        }
        return rawObject;
    },

    recipeMealFor(recipeId) {
        return _.find(this.recipeMeals,recipeMeal => recipeMeal.staticRecipeId() === recipeId)
    },

    carouselItems() {
        return this.orderedDishes().map(dish => ({ id: dish.staticRecipeId(), src: dish.recipe.imageUrl('highQuality','mediumThumb'), recipe: dish.recipe }))
    },

    nextRecipeId(curId,dir=1) {
        const ordered = this.orderedDishes();
        const curInd = _.findIndex(ordered,recipeMeal => (recipeMeal.staticRecipeId() === curId));
        let nextInd = (curInd + dir);
        if(nextInd >= ordered.length) {
            nextInd = nextInd % ordered.length;
        } else if (nextInd < 0) {
            nextInd = ordered.length-1;
        }
        return ordered[nextInd].staticRecipeId();
    },

    prevRecipeId(curId) {
        return this.nextRecipeId(curId,-1)
    }
}

export class Meal {

    static mealCollection = (results,{ recipes, ...tables }) => {
        const recipesTable = _.mapValues(recipes, recipe => Recipe.newWithAssocs(recipe,tables));
        return results.map(res => new Meal(res,recipesTable));
    }

    static mealObjectForRecipeId(recipeId,servings) {
       return { sideDishes: [{ recipeId: recipeId, servings, mainDish: true }]}
    }

    static mealsForRecipes(recipes) {
        const recipesTable = _.keyBy(recipes,rec => rec.id);
        const results = recipes.map(rec => (this.mealObjectForRecipeId(rec.id,1)));
        return results.map(res => new Meal(res,recipesTable));
    }
    
    constructor(obj,recipesTable) {
        const { sideDishes, ...attrs } = obj;
        Object.assign(this,attrs);
        this.recipeMeals = sideDishes.map(sideDish => (new SideDish({ ...sideDish, recipe: recipesTable[sideDish.recipeId] })));
    }

    mainName() {
        return this.mainDish().recipe.name;
    }

    mainImageUrl(size) {
        return this.mainDish().recipe.imageUrl(size);
    }

    reactKey() {
        return this.mainDish().recipe.id;
    }


}
Object.assign(Meal.prototype,mealShared);

export class UserMeal extends Eatable {
    static NAME = 'UserMeal'

    static UNLOGGED = 0;
    static LOGGED = 1;
    static SKIPPED = 2;

    static ASSOCS = {
        mealType: { type: 'belongsTo' },
        recipeMeals: { type: 'hasMany', sortAttr: 'dishOrder' },
        sharedMeals: { type: 'hasMany', tableName: 'userMeals', fk: 'sharedMealParentId', inverse: 'sharedMealParent' },
        sharedMealParent: { type: 'belongsTo', tableName: 'userMeals', inverse: 'sharedMeals' },
        miniProfile: { type: 'belongsTo' },
        source: { type: 'belongsTo', poly: true }
    }

    key() {
        return this.cachedKey = this.cachedKey || `${this.mealType.id}${this.getDnpIdStr()}-${this.isOffPlan() ? 'offPlan' : this.mealKey()}${_.isBlank(this.lockedMealParentId) ? '' : `-${this.lockedMealParentId}`}`;
    }

    mealKey() {
        return this.recipeMeals.map(rm => [rm.recipeId,rm.servings]).sort().reduce((accumulator,currentPair) => `${accumulator},${currentPair.join('|')}`,'');
    }

    logKey() {
        return `um${this.id}`;
    }

    getDnpIdStr() {
        const user = this.mealType && this.mealType.user;
        if(user) {
            const dnp = user.dnpOn(this.date);
            if(dnp && !_.isBlank(dnp.id)) {
                return `-d${dnp.id}`;
            }
        }
        return '-d';
    }

    macroPerServing(type) {
        return Number(this[type])/this.servings;
    }

    setServings(servings) {
        if(servings && servings > 0) {
            allMacrosWithCals.forEach((macro) => {
                this[macro] = this.macroPerServing(macro)*servings;
            })
            this.servings = servings;
        }
    }

    recentIsEquivalent(recent) {
        return (recent.description === this.description && 
            Math.round(this.macroPerServing('calories')/10) === Math.round(recent.macroPerServing('calories')/10));
    }

    logTitle(mealType,t,abbrProt=false) {
        mealType = mealType || this.mealType;
        if(this.category(mealType) === 'misc' && !_.isBlank(this.description)) {
            return this.description;
        } else {
            if(this.category(mealType) === 'protein' && !abbrProt) {
                return t('Protein Supplements');
            } else if(this.category(mealType) === 'snack') {
                return t('Snacks');
            } else {
                return t(_.capitalize(this.category(mealType)));
            }
        }
    }

    loggedMacroHash() {
        return _.mapValues(_.pick(this,allMacrosWithCals),val => Math.round(val));
    }

    unloggedMacroHash() {
        return this.offPlan ? this.unloggedMacros : this.macroHash();
    }

    miniProfileMacroHash(profile) {
        const meal = this.sharedMealFor(profile);

        return meal ? meal.unloggedMacroHash() : _.arrToObj(allMacrosWithCals,macro => 0.0);
    }

    totalActiveTime() {
        return Math.round(this.recipeMeals.map(recipeMeal => recipeMeal.activeTime()).reduce((accumulator,rm) => (accumulator + rm)));
    }

    mealTypeActive() {
        return this.mealType.isActive();
    }

    isLogged() {
        return (this.logType === UserMeal.LOGGED);
    }

    isSkipped() {
        return this.logType === UserMeal.SKIPPED;
    }

    isOffPlan() {
        return this.offPlan;
    }

    offPlanState() {
        if(this.isOffPlan()) {
            return this.isLocked() ? 2 : 1;
        }

        return 0;
    }

    isShared() {
        return this.sharedMeals.length > 0;
    }

    isSharedMeal() {
        return !_.isBlank(this.miniProfileId);
    }

    sharedProfiles() {
        return this.sharedMeals.map(sharedMeal => sharedMeal.miniProfile)
    }

    sharedProfileIds() {
        return this.sharedProfiles().map(sharedProfile =>  sharedProfile.id)
    }

    sharedMealFor(miniProfile) {
        return _.find(this.sharedMeals,meal => (meal.miniProfile.id === miniProfile.id));
    }

    isLocked() {
        return !_.isBlank(this.lockedMealParentId)
    }

    isPinned() {
        return this.pinned;
    }

    isProteinSupp() {
        return this.category() === 'protein';
    }

    category(mealType) {
        return (mealType || this.mealType).category;
    }

    mainImageUrl(size,tempState=null) {
        if(tempState === 'filler') {
            return noRecipeImages[`${size}NoImageFood`];
        } else if(this.isOffPlan() || tempState === 'offPlan') {
            return offPlanImages[`${size}OffPlanMeal`]
        }

        return this.mainDish().recipe.imageUrl(size);
    }

    mainName(t,tempState=null) {
        if(tempState === 'filler') {
            if(this.mealType.isActive()) {
                return t('New meal');
            } else {
                return t('No meal');
            }
        } else if(this.isOffPlan() || tempState === 'offPlan') {
            return t('Off-plan meal');
        }

        return this.mainDish().recipe.name;
    }

    forwardMatchesFor(endDate) {
        return this.mealType.forwardMatchesFor(this,endDate);
    }

    eaterName(t) {
        if(this.miniProfile) {
            return this.miniProfile.name;
        }
        return _.capitalize(t('you'));
    }
}
Object.assign(UserMeal.prototype,mealShared);

registerInflection('userMeal','userMeals',UserMeal);